import React from "react";
import { Helmet } from "react-helmet";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import bannerImage from "../assets/img/gunahomes_buyers.png";
import bannerImage2 from "../assets/img/guna_homes_buyers_2.png";
import { Link } from "react-router-dom";

const paraStyles = {
  color: "#949497",
};

const subHeadingStyle = {
  color: "#303030",
  fontWeight: 600,
};

const listStyle = {
  marginTop: 5,
  marginBottom: 5,
  color: "black",
};
const TITLE = process.env.REACT_APP_SITE_NAME + ' - Calculators'

const Calculators = () => {
  return (
    <>
      <Helmet>
          <title>{TITLE}</title>
      </Helmet>
      <HeaderComponent />
        <div className="innerbanner">
            <div className="w-100 ib_top"></div>
        </div>
        <div className="container">  
            <div className="mb-5">
        
            </div>
            <div className="container detail-section">
                <div className="d-md-flex align-items-center">
                    <h3 className="font-weight-bold"><span className="text_secondary">CALCULATORS</span></h3>
                </div>
                <div className="pt-3 pb-3">
                  <div className="d-md-flex d-lg-block d-block footer-links">
                    <Link to="/landtransfer">
                        <div
                          className="d-flex border py-3 px-3 mt-md-0 mt-lg-3 ">
                          <p className="pl-3"><h5>LAND TRANSFER TAX </h5><p class="">Determine the amount of land transfer tax you will have to pay. Note that land transfer tax is applied on the sale price only.</p></p>
                        </div>
                    </Link>
                  </div>
                </div>
                <div className="pt-3 pb-3">
                  <div className="d-md-flex d-lg-block d-block footer-links">
                    <Link to="/mortgageloan">
                        <div
                          className="d-flex border py-3 px-3 mt-md-0 mt-lg-3 ">
                          <p className="pl-3"><h5>MORTGAGE LOAN CALCULATOR</h5><p class="">Determine your estimated monthly payment and amortization schedule.</p></p>
                        </div>
                    </Link>
                  </div>
                </div>
                <div className="pt-3 pb-3">
                  <div className="d-md-flex d-lg-block d-block footer-links">
                    <Link to="/mortgageaffordability">
                        <div
                          className="d-flex border py-3 px-3 mt-md-0 mt-lg-3 ">
                          <p className="pl-3"><h5>MORTGAGE AFFORDABILITY CALCULATOR</h5><p class="">Can you buy your dream home? Find out just how much you can afford!</p></p>
                        </div>
                    </Link>
                  </div>
                </div>
                <div className="pt-3 pb-5">
                  <div className="d-md-flex d-lg-block d-block footer-links">
                    <Link to="/cmhcpremium">
                        <div
                          className="d-flex border py-3 px-3 mt-md-0 mt-lg-3 ">
                          <p className="pl-3"><h5>CMHC PREMIUM CALCULATOR</h5><p class="">A tool to help you estimate the premium payable when you are purchasing a home. Simply enter the purchase price, down payment and the amortization period.</p></p>
                        </div>
                    </Link>
                  </div>
                </div>
            </div>
        </div>
      <FooterComponent />
    </>
  );
};

export default Calculators;
