import React from "react";

export default function Image({ src }) {
	return (
		<table align="center">
			<tr>
				<td>
					<img src={src} width="250" />
				</td>
			</tr>
		</table>
	);
}
