import { createAction } from "./../../../common/actions/actionHelpers";
//import { propertiesSchema } from '../../entities/schemas/Entities';
import { SET_DATA, SAVE_PROPERTY_DETAILS } from "./PropertiesActionsTypes";
//import axios from './../../../common/ajax';
import { fetchAllproperties } from "./../../entities/actions/propertyActions";

export const setProperitesData = (properties = []) => {
  return createAction(SET_DATA, properties);
};

export const fetchingAndSetProperties = () => async (dispatch) => {
  const res = fetchAllproperties();
  dispatch(setProperitesData(res));
};

export const savePropertyDetails = (payload) => async (dispatch) => {
  dispatch({ type: SAVE_PROPERTY_DETAILS, payload });
};
