// import EstateHome from "../pages/Estate/EstateHome";
import Home from "../pages/admin/pages/Home";
import Appointments from "../pages/admin/pages/Appointments";
import Blog from "../pages/admin/pages/Blog";
import EmailList from "../pages/admin/pages/EmailList";
import Settings from "../pages/admin/pages/Settings";
import Enquiries from "../pages/admin/pages/Enquiries";
import ImageUpload from "../pages/admin/pages/ImageUpload";
import OwnProperties from "../pages/admin/pages/OwnProperties";
import EstateDetails from "../pages/Estate/EstateDetails";

import Profile from "../pages/admin/pages/Profile";
import Feedbacks from "../pages/admin/pages/Feedbacks";
import Todos from "../pages/admin/pages/Todos";

import CsvImport from "../pages/admin/components/forms/email/CsvImport";
import BulkEmail from "../pages/admin/components/forms/email/BulkEmail";
import SendEmails from "../pages/admin/components/forms/email/SendEmails";

const adminRoutes = [
  {
    path: "home",
    component: Home,
    exact: true,
  },
  {
    path: "enquiries",
    component: Enquiries,
    exact: true,
  },
  {
    path: "own",
    component: OwnProperties,
    exact: true,
  },
  {
    path: "settings",
    component: Settings,
    exact: true,
  },
  {
    path: "Logout",
    component: OwnProperties,
    exact: true,
  },
  {
    path: "image-upload",
    component: ImageUpload,
    exact: true,
  },
  {
    path: "estate/details/:propertyId/:propertyType",
    component: EstateDetails,
    exact: true,
  },
  {
    path: "showings",
    component: Appointments,
    exact: true,
  },
  {
    path: "blog",
    component: Blog,
    exact: true,
  },
  {
    path: "email-list",
    component: EmailList,
    exact: true,
  },
  {
    path: "email-list/import",
    component: CsvImport,
    exact: true,
  },
  {
    path: "email-list/bulk/:group_type",
    component: BulkEmail,
    exact: true,
  },
  {
    path: "email-list/send",
    component: SendEmails,
    exact: true,
  },
  {
    path: "profile",
    component: Profile,
    exact: true,
  },
  {
    path: "feedbacks",
    component: Feedbacks,
    exact: true,
  },
  {
    path: "todos",
    component: Todos,
    exact: true,
  },
];
export default adminRoutes;
