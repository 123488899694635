import React, { useState } from "react";

export default function Uploader({ handleFileChange, multiple }) {
	const [urls, setUrls] = useState([]);

	const handleFiles = (files) => {

		console.log("files in Uploader ", files);
		let imageUrls = [];

		for (let i = 0; files.length > i; i++) {
			const tempUrl = window.URL.createObjectURL(files[i]);

			imageUrls.push(tempUrl);
		}

		setUrls(imageUrls);

		handleFileChange(files);
	};

	return (
		<div>
			{urls.length > 0 && (
				<div className="mb-2">
					{urls.map((url, index) => (
						<img
							src={url}
							key={index}
							width="250"
							className="mx-2"
						/>
					))}
				</div>
			)}

			<div class="input-group">
				<div class="custom-file">
					<input
						type="file"
						class="custom-file-input"
						id="inputGroupFile01"
						aria-describedby="inputGroupFileAddon01"
						accept="image/*"
						multiple={multiple}
						onChange={(e) => handleFiles(e.target.files)}
					/>
					<label class="custom-file-label" for="inputGroupFile01">
						Choose file
					</label>
				</div>
			</div>
		</div>
	);
}
