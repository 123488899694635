import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import InnerPageContactHeader from "../assets/img/inner_page_header_contact.png";
import sellers from "../assets/img/sellers.png";
import hlg1 from "../assets/img/hlg1.png";
import sellersparr from "../assets/img/sellersparr.jpg";

const paraStyles = {
  color: "#949497",
};

const listStyle = {
  marginTop: 5,
  marginBottom: 5,
  color: "black",
};
const TITLE = process.env.REACT_APP_SITE_NAME+' - Sell'  

const About = () => {
  return (
    <>
    <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
      <HeaderComponent />
      <div className="innerbanner">
        <div className="w-100 ib_top">          
          <img src={InnerPageContactHeader}  style={{ width: 100 + "%" }}/>
      </div>
      </div>
      <div className="container my-1">
        <div className="mb-5">        
        </div>

        <div className="container pl-5 detail-section">
          <div className="d-md-flex align-items-center px-4">
            <h3 style={{ color: "#e60d2e" }}>THINKING OF</h3>
            <h3 className="my-md-2 pl-md-3">SELL YOUR HOME</h3>
          </div>

          <div className="px-4">
            <p className="my-md-2 pl-md-3" style={paraStyles}>
              <strong style={{ color: "#e60d2e" }}>
                How much is your home worth?
              </strong>{" "}
              Pricing a home is part art and part science. It's a combination
              marrying analytical statistics to emotional appeal and market
              movement.Knowing how to professionally spruce up your home is part
              of the battle.
            </p>

            <p className="my-md-2 pl-md-3" style={paraStyles}>
              The other half is figuring out if you have too much furniture, the
              wrong type or a bad arrangement, and whether it's worth making
              repairs or staging your home to transform that house an
              irresistible and desirable showcase for potential buyers.The best
              home selling tricks are to price it right, prepare it for sale,
              hire the best listing agent and attract that excited home buyer
              who will offer top dollar in record time.
            </p>
          </div>
        </div>

        <div className="d-md-flex justify-content-center p-md-5">
          <div className="w-30 p-3 buyers-img" style={{ width: 30 + "%" }}>
            <img src={sellers}  style={{ width: 100 + "%" }}/>
          </div>

          <div className="w-80 pl-3">
            <div className="d-md-flex align-items-center">
              <h5 style={{ color: "#e60d2e" }}>The following are the</h5>
              <h5 className="my-md-2 pl-md-3">
                steps that I take to get a home sold
              </h5>
            </div>
            <div className="my-2">
              <h5>The “Proactive Approach”</h5>
            </div>
            <div className="pl-3">
              <ul style={{ listStyle: "circle" }}>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Meet with you for a one on one, in depth consultation
                    including a review of your future property needs.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    {" "}
                    Price your home competitively…to open the market vs.
                    narrowing the market.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Complete all paperwork in a timely manner and submit your
                    home to the MLS service.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    {" "}
                    Analyze and research market conditions to determine your
                    property’s fair market value.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    {" "}
                    Prospect daily 2.5 hours, 5 times a week, 25 contacts a day
                    = 125 new contacts a week!!
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    {" "}
                    Suggest and advise as to any changes you may want to make in
                    your property to make it more saleable.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Add additional exposure through a professional sign and lock
                    box.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Whenever possible pre-qualify the prospective Buyers.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    {" "}
                    Provide you with weekly updates and discuss buyer comments
                    and activity.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    {" "}
                    Develop a list of features and benefits of your home for the
                    Brokers to use with their potential buyers.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Assist you with interim financing if required.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Provide a list of highly qualified, professional contractors
                    to repair any deficiencies in your property.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Expose your property on high visibility websites.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Support you on any and all complications that may occur
                    throughout this entire process, from beginning to end.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Represent you on all offer presentations…to assure you in
                    negotiating the best possible price and terms
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    {" "}
                    Handle all the follow-up upon a contract being accepted…all
                    waivers, inspections, financing etc.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${sellersparr})` ,
            height: 500,
          }}
          className="mb-5"
        >
          <div className="w-50 p-5 bottom-section">
            <h3 className="text-white p-5">
              It’s your Journey. I am Here to Help
            </h3>
            <p className="text-white my-2 px-5">
              To give real service, you must add something which cannot be
              bought or measured with money, and that is sincerity and integrity
            </p>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default About;
