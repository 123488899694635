import React from "react";
import { Link } from "react-router-dom";

function Spinner(props) {
  return (
    <Link to={props.link} className="text-white">
      <div className="d-flex justify-content-between">
        <h5 className="w-100 bg-dark pl-2 text-white vm_fs_21">{props.title}</h5>
        <p className="d-flex justify-content-center align-items-center px-2 bg_secondary text-white">
          <i className={props.icon}></i>
          <span></span>
        </p>
      </div>
    </Link>
  );
}

export default Spinner;
