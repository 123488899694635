// import * as React from "react";

export default function SearchInput({ getValue }) {
  return (
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        placeholder="Search in the table"
        aria-label="Search in the table"
        aria-describedby="basic-addon2"
        onChange={(e) => getValue(e.target.value)}
      />
      {/*<div class="input-group-append">
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </div>*/}
    </div>
  );
}
