import React from "react";
import { Helmet } from "react-helmet";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";

import InnerPageHeader from "../assets/img/inner_page_header.png";
import Aboutus1 from "../assets/img/aboutus1.png";

const paraStyles = {
  color: "#949497",
};

/* const subHeadingStyle = {
  color: "#303030",
  fontWeight: 600,
}; */
const TITLE = process.env.REACT_APP_SITE_NAME + ' - About Us'

const About = () => {
  return (
    <>
    <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
      <HeaderComponent />
      <div className="innerbanner">
        <div className="w-100 ib_top">          
          <img src={InnerPageHeader}  style={{ width: 100 + "%" }}/>
        </div>
      </div>
      <div className="container pb-4 my-1">
        <div className="mb-5">
        </div>
        <div className="d-md-flex justify-content-center p-md-5">
          <img src={Aboutus1}  style={{ width: 100 + "%" }}/>
          <div className="pl-3">
            <div className="d-md-flex align-items-center">
              <h3 style={{ color: "#e60d2e" }}>About</h3>
              <h3 className="my-md-2 pl-md-3">Guna M Thuraisingam</h3>
            </div>
            <div>
              <p className="mb-3" style={paraStyles}>
                Guna M Thuraisingham has incredible amount of negotiation skills
                and sales experience to assisting you achieve your real estate
                needs. He is a Real estate professional with more than a decade
                of experience in public practice.
              </p>

              <p className="mb-3" style={paraStyles}>
                Guna is an enthusiastic community worker, where contributing to
                the community also plays a major role in his regimen. He served
                as board of director and/or as committee member for many non-
                profit organizations, such as Canadian Tamil Chamber of Commerce
                (CTCC), Brampton Tamil Association (BTA), and actively involved
                with many other organizations and charities.
              </p>

              <p className="mb-3" style={paraStyles}>
                His extensive corporate and small business experience coupled
                with a master’s degree from the Ryerson University, and the
                professional designations has made him an expert Sales person on
                his chosen field that his clients have found to be of great
                benefit. He helps his clients make better and proactive
                decisions about their Real Estate needs. It is very important to
                ensure that the Real Estate Buy/Sell decision you eventually
                make is the most suitable for your noteworthy needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default About;
