import HTTP from "../common/ajax";

class EnquiryhModel {
  async blogs() {
    try {
      const res = await HTTP.get("admin/blogs");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async blog(id) {
    try {
      const res = await HTTP.get("admin/blogs/" + id);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async trashed() {
    try {
      const res = await HTTP.get("admin/blogs/trashed");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async create(payload) {
    try {
      const res = await HTTP.post("admin/blogs", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async update(id, payload) {
    try {
      const res = await HTTP.post("admin/blogs/" + id, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async delete(payload) {
    try {
      const res = await HTTP.post("admin/blogs/delete", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async permenantDelete(payload) {
    try {
      await HTTP.post("admin/blogs/delete/confirm", payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async changeStatus(id) {
    try {
      await HTTP.get("admin/blogs/status/" + id);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async restore(id) {
    try {
      await HTTP.get("admin/blogs/restore/" + id);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async getAllCategories() {
    try {
      const res = await HTTP.get("admin/blogs/category/getAllCategories");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async createCategory(payload) {
    try {
      await HTTP.post("admin/blogs/category", payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async updateCategory(payload) {
    try {
      await HTTP.post("admin/blogs/category/update", payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async deleteCategory(payload) {
    try {
      await HTTP.post("admin/blogs/category/delete", payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async latest() {
    try {
      const res = await HTTP.get("public/blogs/latest");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async getAllCategories2() {
    try {
      const res = await HTTP.get("public/blogs/category");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async fetchBlogPostData(category, id) {
    try {
      const res = await HTTP.get(`public/blogs/${category}/${id}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async fetchPostsByCategory(category) {
    try {
      const res = await HTTP.get(`public/blogs/${category}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }
}

export default new EnquiryhModel();
