
import axios from './../../../common/ajax';

/**
 * API call to fetch the account transaction statistics for the user
 */
 export const fetchAllproperties = () => {

    return axios
    .get(`all-properties`, {})
    .then(res => {
        const data = res.data;
        // console.log('all-properties');
        // console.log(data);
        return Promise.resolve(data);
    })
    .catch((error) => {
        console.log(error)
    });
  };