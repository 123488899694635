import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";

import RootElement from "../components/RootElement";
import TodoModel from "../../../Models/TodoModel";

import DatePicker from "../../../componets/DatePicker";
import SelectInput from "../components/inputs/SelectInput";

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const ITEM_HEIGHT = 48;

const options = [
  'None',
  'Atria',
  'Callisto',
  'Dione',
  'Ganymede',
  'Hangouts Call',
  'Luna',
  'Oberon',
  'Phobos',
  'Pyxis',
  'Sedna',
  'Titania',
  'Triton',
  'Umbriel',
];


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Feedbacks = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(true);

  const [todos, setTodos] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchTodos = async () => {
    const res = await TodoModel.fetchTodos();

    setTodos(res.todos);
    setCategories(res.categories);

    setLoading(false);
  };

  useEffect(() => {
    fetchTodos();

    return () => {
      fetchTodos();
    };
  }, []);

  const [form, setForm] = useState({
    title: "",
    date: "",
    category: "",
  });

  const saveTodo = async () => {
    setLoading(true);
    const res = await TodoModel.saveTodo(form);
    setTodos(res.todos);
    setCategories(res.categories);
    setLoading(false);
    handleClose();

    Swal.fire("Done", "Todo created successfully", "success");
  };

  const markAsCompleted = async (id) => {
    await confirmMsg("Mark this todo as completed?");
    await TodoModel.updateTodo(id);
  };

  const confirmMsg = async (text) => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "Are you sure?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          resolve();
        }
      });
    });
  };

  const [category, setCategory] = React.useState("");
  const [openCategory, setOpenCategory] = React.useState(false);
  const handleOpenCategory = () => setOpenCategory(true);
  const handleCloseCategory = () => setOpenCategory(false);

  const saveCategory = async () => {
    setLoading(true);

    await TodoModel.saveCategory({ category });
    await fetchTodos();
    handleCloseCategory();

    Swal.fire("Success", "New category is created.", "success");
    setLoading(false);
  };

  const [rawCategories, setRawCategories] = useState([]);

  useEffect(() => {
    const data = categories.map(function (category, index) {
      return category.category;
    });

    setRawCategories(data);
  }, [categories]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <RootElement active="todo">
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <div className="mt-5" style={{ minHeight: "100vh" }}>
        <div className="mb-3">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleOpen()}
            disabled={loading}
          >
            New Todo
          </button>

          <button
            className="btn btn-sm btn-primary mx-1"
            onClick={() => handleOpenCategory()}
            disabled={loading}
          >
            New Todo Category
          </button>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="">
              {todos.length > 0 ? (
                <div className="px-3 pt-3">
                  <table className="table table-hover">
                    <thead>
                      <tr style={{ fontSize: 14 }}>
                        <th scope="col">#</th>
                        <th>Category</th>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Todo Date</th>
                        <th>Actions

                          {/* <div>
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={openMenu ? 'long-menu' : undefined}
                              aria-expanded={openMenu ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={handleClickMenu}
                            >
                              <FilterAltIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleCloseMenu}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              {options.map((option) => (
                                <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleCloseMenu}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {todos.map((todo, index) => (
                        <tr
                          key={index}
                          style={{
                            fontSize: 14,
                            verticalAlign: "middle",
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{todo.category}</td>
                          <td>{todo.title}</td>
                          <td>{todo.status}</td>
                          <td>{todo.date}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => markAsCompleted(todo.id)}
                            >
                              <i class="bi bi-check2"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>
                  <p>No data</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <h6>New Todo</h6>
              <button
                className="btn btn-sm btn-success"
                onClick={() => saveTodo()}
              >
                {!loading ? (
                  "Save"
                ) : (
                  <div className="px-3">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </button>
            </div>

            <div className="mt-3">
              <div class="input-group input-group-lg">
                <div class="input-group-prepend"></div>
                <input
                  type="text"
                  class="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  placeholder="Eg: Send emails on monday"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      title: e.target.value,
                    })
                  }
                />
              </div>

              <div class="input-group my-3">
                <DatePicker
                  value={form.date}
                  getDateAndTime={(dateTime) =>
                    setForm({
                      ...form,
                      date: dateTime,
                    })
                  }
                />
              </div>
            </div>

            <div>
              <SelectInput
                label="Category"
                placeholder={"Please select a category."}
                options={rawCategories}
                value={form.category}
                getValue={(value) => setForm({ ...form, category: value })}
              />
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openCategory}
        onClose={handleCloseCategory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <h6>New Category</h6>
              <button
                className="btn btn-sm btn-success"
                onClick={() => saveCategory()}
              >
                {!loading ? (
                  "Save"
                ) : (
                  <div className="px-3">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </button>
            </div>

            <div className="mt-3">
              <div class="input-group input-group-lg">
                <input
                  type="text"
                  class="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  placeholder="Eg: Appointments"
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
            </div>

            <div>
              {categories.length > 0 ? (
                <div className="pt-3">
                  <table className="table table-striped">
                    <thead className="bg-primary text-white">
                      <tr style={{ fontSize: 14 }}>
                        <th scope="col">#</th>
                        <th>Category</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.map((todo, index) => (
                        <tr
                          key={index}
                          style={{
                            fontSize: 14,
                            verticalAlign: "middle",
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{todo.category}</td>
                          <td>
                            <button className="btn btn-sm btn-danger">
                              <i class="bi bi-trash-fill"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="text-center my-3">
                  <p className="text-muted">There's no Todo category</p>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </RootElement>
  );
};

export default Feedbacks;
