import React, { useEffect, useRef } from "react";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

const style = {
	position: "absolute",
	top: "45%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: {
		xl: 1500,
		lg: 900,
		md: 750,
		xs: 450
	},
	bgcolor: "transparent",
	zIndex: "99999 !important",
	p: 4,
};

function ImageViewer(props) {
	// const [rotation, setRotation] = useState(0);
	const next = useRef('next');
	const prev = useRef('prev');

	function _handleKeyDown(event) {
		switch (event.key) {
			case "ArrowRight":
				// calling the function directly is not working
				// Simulate the click event using useRef
				next.current?.click();

				break;
			case "ArrowLeft":
				// calling the function directly is not working
				// Simulate the click event using useRef
				prev.current?.click();

				break;
			default:
				// code block
				break;
		}
	}

	useEffect(() => {
		document.addEventListener("keydown", _handleKeyDown);

		return () => {
			document.removeEventListener("keydown", _handleKeyDown);
		};
	}, []);

	return (
		<Modal
			open={props.open}
			onClose={() => props.onClose()}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				{/* <div className="text-right">
					<Tooltip title="Close">
						<IconButton
							aria-label="remove"
							component="span"
							onClick={() => props.onClose()}
							style={{ color: "#ffffff" }}
						>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</div> */}
				<div className="d-flex justify-content-between align-items-center">
					<div className="pr-2">
						<IconButton
							aria-label="delete"
							size="large"
							style={{ color: "#fff" }}
							onClick={() => props.previousImage()}
							ref={prev}
						>
							<ArrowBackIosNewIcon />
						</IconButton>
					</div>

					<div className="div-image-viewer">
						<div className="d-none text-right controll-button-close">
							<Tooltip title="Close">
								<IconButton
									aria-label="remove"
									component="span"
									onClick={() => props.onClose()}
									style={{ color: "#ffffff" }}
								>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						</div>
						{/* src={`${process.env.REACT_APP_IMG_SOURCE}/${img.url}`} */}
						<img
							src={props.image}
							alt=""
							width="100%"
							className="ws-image-viewer"
							style={{ transform: `rotate(${0}deg)` }}
						/>
						<div className="d-none justify-content-center controll-button">
							<div className="pr-2">
								<IconButton
									aria-label="delete"
									size="large"
									style={{ color: "#fff" }}
									onClick={() => props.previousImage()}
									ref={prev}
								>
									<ArrowBackIosNewIcon />
								</IconButton>
							</div>
							<div className="pl-2">
								<IconButton
									aria-label="delete"
									size="large"
									style={{ color: "#fff" }}
									onClick={() => props.nextImage()}
									ref={next}
								>
									<ArrowForwardIosIcon />
								</IconButton>
							</div>
						</div>
					</div>

					<div className="pl-2">
						<IconButton
							aria-label="delete"
							size="large"
							style={{ color: "#fff" }}
							onClick={() => props.nextImage()}
							ref={next}
						>
							<ArrowForwardIosIcon />
						</IconButton>
					</div>
				</div>
			</Box>
		</Modal>
	);
}

export default React.memo(ImageViewer);
