import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getBlogPost,
  saveBlogPosts,
  saveBlogPost,
} from "../store/actions/App/AppActions";
import { useHistory, useParams } from "react-router-dom";
import BlogModel from "../Models/BlogModel";
import PostCard from "../componets/Cards/PostCard";
import Skeleton from "@mui/material/Skeleton";

import Swal from "sweetalert2";
import CategoryCard from "../componets/Cards/CategoryCard";
const TITLE = process.env.REACT_APP_SITE_NAME+' - Blogs'  

function showMessage(type, message) {
  Swal.fire({
    position: "top-end",
    html: `<p class='text-left text-${type}'>` + `${message}` + `</p>`,
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
  });
}

const Blogs = (props) => {
  const history = useHistory();
  const params = useParams();

  const [categories, setCategories] = useState([]);
  const [posts, setPosts] = useState([]);
  const [postNotFound, setPostNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchAllCategories = async () => {
      const res = await BlogModel.getAllCategories2();

      setCategories(res.data);
    };

    fetchAllCategories();

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const handleClick = async (category) => {
    setLoading(true);
    const res = await BlogModel.fetchPostsByCategory(params.category);
    if (res.data.length > 0) {
      setPosts(res.data);
      setPostNotFound(true);
    } else {
      setPosts([]);
      setPostNotFound(false);
      showMessage(
        "danger",
        "There are no posts. Please select different category"
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    if (params.category) {
      handleClick();
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [params.category]);

  return (
    <>
    <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
      <HeaderComponent />
      <section className="py-5 mb-md-4 mt-md-5">
        {!postNotFound ? (
          <Container>
            <h5 className="font-weight-bold text-center mb-3">Categories</h5>

            {loading && (
              <div className="row">
                {["a", "b", "c", "d"].map((alpha) => (
                  <div className="col-md-3">
                    <Skeleton
                      key={alpha}
                      variant="rectangular"
                      width={320}
                      height={240}
                    />
                    <Skeleton variant="rectangular" width={320} height={50} />
                  </div>
                ))}
              </div>
            )}

            <Row>
              {categories &&
                categories.map((category, index) => (
                  <Col
                    md={3}
                    key={index}
                    style={{ cursor: "pointer", marginBottom: 10 }}
                  >
                    {!loading && <CategoryCard category={category} />}
                  </Col>
                ))}
            </Row>
          </Container>
        ) : (
          <Container>
            <h5 className="font-weight-bold text-center mb-3">Posts</h5>
            <Row>
              {posts &&
                posts.map((post, index) => (
                  <Col md={3} key={index}>
                    <PostCard
                      blog={post}
                      history={history}
                      getBlogPost={() => props.saveBlogPost(post)}
                    />
                  </Col>
                ))}
            </Row>
            <div className="text-center my-5 pt-3">
              <button
                className="btn btn-danger"
                onClick={() => setPostNotFound(false)}
              >
                Go Back
              </button>
            </div>
          </Container>
        )}
      </section>
      <FooterComponent />
    </>
  );
};

const mapStateToProps = (state) => {
  return { state: state.appState };
};

const mapDispatchToProps = (dispatch) => ({
  saveBlogPosts: (payload) => {
    dispatch(saveBlogPosts(payload));
  },
  saveBlogPost: (payload) => {
    dispatch(saveBlogPost(payload));
  },
  getBlogPost: (id) => {
    dispatch(getBlogPost(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
