import React, { useState, useEffect } from "react";
import axios from "./../../common/ajax";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";

import SearchModel from "../../Models/SearchModel";
import SettingModel from "../admin/models/SettingModel";
import { savePropertyTypes,saveSearchData } from "../../store/actions/Search/SearchActions";
import { setShowings } from "../../store/actions/App/AppActions";

import HeaderComponent from "../../componets/Applications/Header/Header";
import FooterComponent from "../../componets/Applications/Footer/Footer";

import Card from "../../componets/Card";

const BrokerageListing = (props) => {
    const location = useLocation();
    const [count, setCount] = useState(0);      
    const [broLoadings, setBroLoadings] = useState({
        brokerageListings: true
    });

    const [brokerage,setBrokerage] = useState([]);
    async function fetchbrokeragelistings() {
        let res = await SearchModel.fetchbrokeragelistings({
        propertyType: "RESEDENTIAL",
        });
        setBrokerage(res);
        setBroLoadings({ brokerageListings: false });
    }
    
    useEffect(() => {
        fetchbrokeragelistings();
    }, []);

    function addToShowings(payload) {
        let storage = JSON.parse(localStorage.getItem("showings"));
        const found = storage.find((pr) => pr.property_id === payload.property_id);

        if (found !== undefined || found) {
        Swal.fire(
            "Already Exist",
            "Property already in showings list.",
            "warning"
        );
        return;
        }
        // return;
        props.setShowings(payload);

        Swal.fire("Success", "Property added to showings list.", "success");
    }

    useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
  
    const TITLE = process.env.REACT_APP_SITE_NAME+' - Brokerage Properties'

    return (
        <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <HeaderComponent />
            <section className="py-5 mb-md-4">
                <div className="innerbanner">
                    <div className="w-100 ib_top"></div>
                </div>
                <Container>
                    <h3 className="font-weight-bold text-center mb-3 font-weight-bold">OUR BROKERAGE'S LISTING</h3>
                    <div className="container pb-4 my-5">      
                        {broLoadings.brokerageListings && (
                        <div className="d-flex justify-content-center mb-5">
                            <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        )}

                        {brokerage.length === 0 && (
                        <div className="text-center">
                            Currenlt there's no listings.
                        </div>
                        )}
                
                        <div className="row pt-4 m-0">
                        {brokerage.length > 0 ? (
                            brokerage.map((item, index) => {
                            return (
                                <div key={index} className="col-md-3 mb-5">
                                <Card
                                    item={item}
                                    addToShowings={(value) => addToShowings(value)}
                                />
                                </div>
                            ); //
                            })
                        ) : (
                            <h3 className="text-center">Data not found</h3>
                        )}
                        </div>
                    </div>
                </Container>
            </section>
            <FooterComponent />
        </>
    );
};

const mapStateToProps = (state) => {
  return { searchState: state.searchState };
};

const mapDispatchToProps = (dispatch) => ({ 
  setShowings: (data) => {
    dispatch(setShowings(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BrokerageListing);
