import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const BlogCategoryTable = (props) => {
  return (
    <div>
      {props.categories.length > 0 ? (
        <table class="table" style={{ width: 500 }}>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Category</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {props.categories.map((category, index) => (
              <tr key={index}>
                <th scope="row">
                  <img src={category.image} alt="" width={150} height={150} />
                </th>
                <td>
                  <h4>{category.category}</h4>
                </td>
                <td>
                  <Stack direction="row" spacing={2}>
                    <div className="border rounded">
                      <Tooltip title="Edit">
                        <IconButton
                          color="primary"
                          aria-label="details"
                          component="span"
                          onClick={() => props.setCategory(category)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </div>

                    <div className="border rounded">
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="details"
                          component="span"
                          onClick={() => props.deleteCategory(category)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Stack>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>
          <strong>No categories</strong>
        </div>
      )}
    </div>
  );
};

export default BlogCategoryTable;
