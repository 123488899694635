import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { cloneDeep } from "lodash";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { Container, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import OwlCarousel from "react-owl-carousel";
import { Helmet } from "react-helmet";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/home.css";
import "../../assets/css/PostCard.css";

import { fetchingAndSetProperties } from "./../../store/actions/Properties/PropertiesActions";
import {
  saveSearchResults,
  saveSearchData,
} from "../../store/actions/Search/SearchActions";
import { getBlogPost, saveBlogPosts } from "../../store/actions/App/AppActions";

import HeaderComponent from "../../componets/Applications/Header/Header";
import FooterComponent from "../../componets/Applications/Footer/Footer";
import SearchModel from "../../Models/SearchModel";
import FeaturedCard from "../../componets/FeaturedCard";
import PostCard from "../../componets/Cards/PostCard";
import BlogModel from "../../Models/BlogModel";

import FeaturedToronto from "../../assets/img/featured_area_toronto.webp";
import FeaturedOttawa from "../../assets/img/featured_area_ottowa.webp";
import FeaturedHamilton from "../../assets/img/featured_area_hamilton.webp";
import FeaturedMississauga from "../../assets/img/featured_area_mississauga.webp";
import FeaturedLondon from "../../assets/img/featured_area_london.webp";
import FeaturedBrampton from "../../assets/img/featured_area_brampton.webp";
import FeaturedVaughan from "../../assets/img/featured_area_vaughan.webp";
import FeaturedWindsor from "../../assets/img/featured_area_windsor.webp";
import FeaturedOakville from "../../assets/img/featured_area_oakville.webp";
import FeaturedBarrie from "../../assets/img/featured_area_barrie.webp";
import FeaturedBurlington from "../../assets/img/featured_area_burlington.webp";
import FeaturedKitchener from "../../assets/img/featured_area_kitchener.webp";
import FeaturedOshawa from "../../assets/img/featured_area_oshawa.webp";
import FeaturedScarborough from "../../assets/img/featured_area_scarborough.webp";
import FeaturedMarkham from "../../assets/img/featured_area_markham.webp";
import FeaturedAjax from "../../assets/img/featured_area_ajax.webp";
import video from "../../assets/videos/teamguna.mp4";

const Home = (props) => {
  const [count, setCount] = useState(0);      
  const [broLoadings, setBroLoadings] = useState({
    brokerageListings: true
  });

  const [brokerage,setBrokerage] = useState([]);
  async function fetchbrokeragelistings() {
    let res = await SearchModel.fetchbrokeragelistings({
      propertyType: "RESEDENTIAL",
    });
    setBrokerage(res);
    setBroLoadings({ brokerageListings: false });
  }

  const [soldLoadings, setSoldLoadings] = useState({
    soldListings: true
  });
  const [soldprops,setSoldprops] = useState([]);
  async function fetchsoldpropslistings() {
    let res = await SearchModel.fetchsoldpropslistings({
      propertyType: "RESEDENTIAL",
    });
    setSoldprops(res);
    setSoldLoadings({ soldListings: false });
  }

  const [feaLoadings, setFeaLoadings] = useState({
    featuredListings: true
  });  
  const [featured, setFeatured] = useState([]);
  async function fetchFeaturedListings() {
    let res = await SearchModel.fetchFeaturedListings({
      propertyType: "RESEDENTIAL",
    });
    setFeatured(res);
    setFeaLoadings({ featuredListings: false });
  }
  
  useEffect(() => {
    fetchbrokeragelistings();
    fetchsoldpropslistings();
    fetchFeaturedListings();
  }, []);
  // search
  const [search, setSearch] = useState({
    propertyType: "RESEDENTIAL",
    buildingType: "any",
    transactionType: "any",
    keyword: "",
    minPrice: 0,
    maxPrice: 0,
    bed: "any",
    bath: "any",    
    parking: "any",
  });  
  const [suggestions, setSuggestions] = useState([]);
  const [searching, setSearching] = useState(false);
  // find suggesstion only if user type any keyword
  useEffect(() => {
    if(search.keyword){
      SearchModel.findSimilarity(search.keyword).then((res) => {
        // matching rating is greater than 0
        if (res.bestMatch.rating > 0) {
          // copy array without duplication
          let data = cloneDeep(suggestions);
          const allInarray = data.filter(
            (values) => values === res.bestMatch.target
          );
          // Make sure the value doesn't exist already
          if (allInarray.length === 0) {
            data.push(res.bestMatch.target);
            setSuggestions(data);
          }
        }
      });
    }
  }, [search.keyword]);  
  const [blogs, setBlogs] = useState("");
  useEffect(() => {
    const post = async () => {
      try {
        const res = await BlogModel.latest();
        setBlogs(res.data);
        props.saveBlogPosts(res.data);
        setTimeout(function () {
          setCount(count + 1);
        }, 200);
      } catch (error) {
      }
    };
    post();
    return () => {
      post();
    };
  }, []);
  let history = useHistory();
  async function handleFormSubmit(event) {
    event.preventDefault();
    // if (search.keyword === "") {
    //   Swal.fire("Error", "Search keyword cannot be empty", "error");
    //   return;
    // }
    setSearching(true);
    let res = await SearchModel.search(search);
    //console.log(`res => `, res);
    props.saveSearchData(search);
    props.saveSearchResults(res);
    setSearching(false);
    history.push("search", { search });
  }
  const [suggestionClicked, setSuggestionClicked] = useState(false);
  const handleTyping = (value,field) => {    
      setSearch({ ...search, keyword: value });
    if (suggestionClicked) {
      setSuggestionClicked(false);
    }
  };
  const handleSuggestionClick = (keyword,field) => {   
    setSearch({ ...search, keyword: keyword });    
    setSuggestions([]);
    setSuggestionClicked(true);
    setCount(count + 1);
  };
  const handleOnBlur = () => {
    setTimeout(function () {
      setSuggestionClicked(true);
      setCount(count + 1);
    }, 200);
  };

  const options = {
    margin: 30,
    responsiveClass: true,
    loop: brokerage.length > 1 ? true : false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  const optionsSold = {
    margin: 30,
    responsiveClass: true,
    loop: soldprops.length > 1 ? true : false,
    autoplay: true,
    autoplayTimeout: 1800,
    autoplayHoverPause: true,
    autoplaySpeed: 500,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []); 
  const TITLE = process.env.REACT_APP_SITE_NAME + ' - Home'

  return (
    <>
      <Helmet>
          <title>{TITLE}</title>
      </Helmet>
      <HeaderComponent page="home" />
      <React.Fragment>
        <section className="main_banner">
          <div className="guna position-absolute"></div>
          <Container clasname="container">
            <div className="banner_content position-absolute d-none d-md-block">
              <h4
                className="text-white"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                Don't wait to buy
              </h4>

              <h1
                className="text-white text-uppercase fw_bold"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="450"
              >
                Real Estate
              </h1>

              <h4
                className="text-white"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="550"
              >
                Buy real estate and wait
              </h4>
            </div>
          </Container>
          <div className="overlay">
          
            <video className='videoTag' controls="false" autoplay="autoplay" loop="true" muted defaultmuted playsinline>
              <source  src={video}  type='video/mp4' />
            </video> 
          </div>
          {/* <div className="bannerimage"></div> */}
        </section>

        <section className="bg_primary pt-md-5 pb-md-0 px-3 pt-3 serchengine">
          <div className="container pt-4 py-md-0">
            <form onSubmit={handleFormSubmit}>
              <div className="d-flex flex-md-row flex-column justify-content-between flex-wrap">
                <div className="col col-lg-2 pt-3 pt-md-0">
                  <p className="text-white pb-2">Property Type</p>
                  <select
                    name="buildingType"
                    id="building-type"
                    className="form-control border_radius_none text-white bg_primary"
                    onChange={(e) =>
                      setSearch({ ...search, buildingType: e.target.value })
                    }
                  >
                      <option value="any">Any</option>
                      <option value="condo">Condo</option>
                      <option value="Att/Row/Twnhouse">Att/Row/Twnhouse</option>
                      <option value="Cottage">Cottage</option>
                      <option value="Detached">Detached</option>
                      <option value="Duplex">Duplex</option>
                      <option value="Farm">Farm</option>
                      <option value="Fourplex">Fourplex</option>
                      <option value="Link">Link</option>
                      <option value="Lower Level">Lower Level</option>
                      <option value="Mobile/Trailer">Mobile/Trailer</option>
                      <option value="Multiplex">Multiplex</option>
                      <option value="Other">Other</option>
                      <option value="Rural Resid">Rural Resid</option>
                      <option value="Semi-Detached">Semi-Detached</option>
                      <option value="Store W/Apt/Offc">Store W/Apt/Offc</option>
                      <option value="Triplex">Triplex</option>
                      <option value="Upper Level">Upper Level</option>
                      <option value="Vacant Land">Vacant Land</option>		
                  </select>
                </div>
                <div className="col col-lg-2 pt-3 pt-md-0">
                  <p className="text-white pb-2">Transaction Type</p>
                  <select
                    name="transactionType"
                    id="transaction-type"
                    className="form-control bg_none border_radius_none text-white bg_primary"
                    onChange={(e) =>
                      setSearch({ ...search, transactionType: e.target.value })
                    }
                  >
                     <option value="any">Any</option>
                     <option value="Sale">Sale</option>
                     <option value="Lease">Rent/Lease</option>
                     <option value="Sub-Lease">Sub Lease</option>
                  </select>
                </div>
                <div className="pr-3 pl-3">
                  <p className="text-white pb-2">
                    Search by MLS #, City, Neighborhood, Zip or Addres
                  </p>
                  <input
                    type="text"
                    name="keyword"
                    className="form-control bg_none border_radius_none text-white bg_primary"
                    placeholder="Toronto"
                    onChange={(e) => handleTyping(e.target.value)}
                    value={search.keyword}
                    onBlur={() => handleOnBlur()}
                  />
                  <ul className="list-group">
                    {!suggestionClicked &&
                      suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          className="list-group-item"
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Min Price</p>
                  <input
                    type="number"
                    name="min_price"
                    className="form-control bg_none border_radius_none text-white bg_primary"
                    placeholder="Min Price"
                    onChange={(e) =>
                      setSearch({ ...search, minPrice: e.target.value })
                    }
                  />
                </div>
                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Max Price</p>
                  <input
                    type="number"
                    name="max_price"
                    className="form-control bg_none border_radius_none text-white bg_primary"
                    placeholder="Max Price"
                    onChange={(e) =>
                      setSearch({ ...search, maxPrice: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="mt-4 d-flex flex-md-row flex-column justify-content-between flex-wrap">
                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Bed Rooms</p>
                  <select
                    name="bed"
                    id="bed"
                    className="form-control bg_none border_radius_none text-white bg_primary"
                    onChange={(e) =>
                      setSearch({ ...search, bed: e.target.value })
                    }
                  >
                    <option value="any">Any</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Bath Rooms</p>
                  <select
                    name="bath"
                    id="bath"
                    className="form-control bg_none border_radius_none text-white bg_primary"
                    onChange={(e) =>
                      setSearch({ ...search, bath: e.target.value })
                    }
                  >
                    <option value="any">Any</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Parking</p>
                  <select
                    name="parking"
                    id="parking"
                    className="form-control bg_none border_radius_none text-white bg_primary"
                    onChange={(e) =>
                      setSearch({ ...search, parking: e.target.value })
                    }
                  >
                    <option value="any">Any</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div className="col pb-4">
                  <p className="text-white pb-2">&nbsp;</p>
                  <input
                    type="submit"
                    name="keyword"
                    value="Search"
                    className="form-control text-white border_none bg_secondary border_radius_none"
                  />
                </div>
              </div>
            </form>

            {searching && (
              <div className="d-flex justify-content-center align-items-center text-light">
                <h5 className="text-light mr-2">Searching please wait</h5>
                <div className="spinner-grow spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {/* <ul className="nav nav-pills pt-4" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active border_radius_none"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#gunas_listing"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Our Brokerage's Listing
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link border_radius_none"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#sold_listing"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Sold Listing
                </a>
              </li>
            </ul> */}
          </div>
        </section>

        <section className="py-5 gunas_listing">
          <div className="container pb-4">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="gunas_listing"
                role="tabpanel"
                aria-labelledby="pills-guna-tab"
              >
                <h3 className="text-center pb-5 fw_bold">
                Our Brokerage's Listing
                </h3>

                {broLoadings.brokerageListings && (
                  <div className="d-flex justify-content-center mt-4">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}

                {brokerage.length === 0 && (
                  <div className="text-center">
                    Currenlt there's no listings.
                  </div>
                )}

                <OwlCarousel className="owl-theme" {...options}>
                  {brokerage &&
                    brokerage.map((data, index) => (
                      <div
                        className="item d-md-flex justify-content-between w-100 bg-white"
                        key={index}
                      >
                        <div>
                          <div
                            className="img"
                            style={{
                              backgroundImage: `url(${
                                data.images.length > 0
                                  ? process.env.REACT_APP_IMG_SOURCE +
                                    "/" +
                                    data.images[0].url
                                  : "img/no_image.png"
                              })`,
                            }}
                          ></div>
                        </div>
                        <div className="details pl-md-4 pt-4 pt-md-0 w-100">
                          <h6 className="tag bg_blue text-white">{data.Type_own1_out}</h6>

                          <p className="title text-center text-md-left text-truncate">
                            <i className="bi bi-geo-alt-fill h3"></i>{" "}
                            {data.Addr}, {data.Municipality}
                          </p>

                          <p className="text-center text-md-left para">
                            {data.Ad_text}
                          </p>

                          <div className="facilities d-flex justify-content-md-start justify-content-center">
                            <div className="d-flex pr-md-4 pr-2">
                              <div className="pr-2">
                                <div className="bed"></div>
                                <h6>Bed</h6>
                              </div>
                              <p className="count">{data.bed_rooms}</p>
                            </div>
                            <div className="line"></div>
                            <div className="d-flex px-md-4 px-2">
                              <div className="pr-2">
                                <div className="bath"></div>
                                <h6>Bath</h6>
                              </div>
                              <p className="count">{data.bath_rooms}</p>
                            </div>
                            <div className="line"></div>
                              <div className="d-flex pl-md-4 pl-2">
                                  <div className="pr-2">
                                      <div className="sqft"></div>
                                      <h6>Parking</h6>
                                  </div>
                                  <p className="count">{data.park_spcs}</p>
                              </div>
                          </div>

                          <div className="pt-3 d-flex flex-column flex-md-row justify-content-between">
                            <h4 className="fw_bold d-flex justify-content-center mb-3 mb-md-0">
                              <img
                                src="img/price_tag.png"
                                className="mr-2 mt-1 w-auto"
                                alt=""
                              />
                              <span><NumberFormat value={data.Orig_dol} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
                            </h4>
                            <button
                            type="button"
                              className="btn hvr-shutter-out-horizontal border-0 pb-2"
                              onClick={() =>
                                history.push(
                                  `/estate/details/${data.property_id}/${data.property_type}`
                                )
                              }
                            >
                              <i className="bi bi-search mr-1 h6"></i>
                              <span>View Details</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </OwlCarousel>

                {brokerage && (
                <div className="d-flex justify-content-center mt-4">
                  <Link to="/allbrokerage">
                    <div className="bg_primary text-white text-center p-3 d-inline">
                      View All Properties{" "}
                      <i className="bi bi-chevron-double-right"></i>
                    </div>
                  </Link>
                </div>
                )}
              </div>
              {/* <div
                className="tab-pane fade"
                id="sold_listing"
                role="tabpanel"
                aria-labelledby="pills-sold-tab"
              >
                <h3 className="text-center pb-5 fw_bold">Sold Listing</h3>

                {soldLoadings.soldListings === 0 && (
                  <div className="d-flex justify-content-center mt-4">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}

                {soldprops.length === 0 && (
                  <div className="text-center">
                    Currenlt there's no sold listings.
                  </div>
                )}
                <OwlCarousel className="owl-theme" {...optionsSold}>                 
                  {soldprops &&
                    soldprops.map((item, index) => {
                      return (
                        <div className="item border-0 p-0 bg-white" key={index}>
                          <div className="item shadow p-0">
                            <h5 className="position-absolute bg-danger text-white text-uppercase px-3 vm_fs_21 py-1 font-weight-bold">
                              Sold
                            </h5>
                            <img
                              src={`${
                                item.images.length > 0
                                  ? process.env.REACT_APP_IMG_SOURCE +
                                    "/" +
                                    item.images[0].url
                                  : "img/no_image_1.jpg"
                              }`}
                              className="w-100"
                              alt=""
                              height={240}
                            />
                            <div className="mb-3">
                              <h5 className="py-1 px-3 font-weight-bold">
                                ${item.Orig_dol}
                              </h5>
                              <div className="px-3">
                                <p className="text-truncate">
                                  {item.Addr && item.Addr},{" "}
                                  {item.Municipality && item.Municipality}
                                </p>
                              </div>
                              <p className="px-3">
                                Bed {item.bed_rooms} | Bath {item.bath_rooms}
                              </p>
                              <p className="px-3">MLS® {item.Ml_num}</p>
                            </div>
                            <div className="d-flex justify-content-between">

                            </div>
                          </div>
                        </div>
                      );
                    })}
                </OwlCarousel>
              </div> */}
            </div>
          </div>
        </section>

        <section className="listing bg-white">
          <div className="overlay py-5">
            <div className="container-fluid pb-5 px-md-5">
              <h3 className="text-center pb-5 pt-4 fw_bold">
                Featured Listing
              </h3>
              {feaLoadings.featuredListings && (
                <div className="d-flex justify-content-center mt-4">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              <div className="row pt-4">
                {featured &&
                  featured.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="column_wrap"
                        style={{ padding: 0 }}
                      >
                        <FeaturedCard key={index} item={item} />
                      </div>
                    );
                  })}
              </div>

              {featured && (
                <div className="d-flex justify-content-center mt-4">
                  <Link to="/estate/toronto">
                    <div className="bg_primary text-white text-center p-3 d-inline">
                      View More Properties{" "}
                      <i className="bi bi-chevron-double-right"></i>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="py-4 bg_ash featured_area">
          <div className="container pb-4">
            <h3 className="text-center pb-5 pt-4 fw_bold">Featured Area</h3>

            <div className="row d-flex justify-content-center pt-4">
              <Col md={3}>
                <a href="/estate/Toronto">
                  <div className="item">
                    <img src={FeaturedToronto} className="w-100" />
                    <div className="title">Toronto</div>
                  </div>
                </a>
              </Col>
              <Col md={3}>
                <a href="/estate/Ottawa">
                  <div className="item">
                    <img src={FeaturedOttawa} className="w-100" />
                    <div className="title">Ottawa</div>
                  </div>
                </a>
              </Col>
              <Col md={3}>
                <a href="/estate/Hamilton">
                  <div className="item">
                    <img src={FeaturedHamilton} className="w-100" />
                    <div className="title">Hamilton</div>
                  </div>
                </a>
              </Col>
              <Col md={3}>
                <a href="/estate/Mississauga">
                  <div className="item">
                    <img src={FeaturedMississauga} className="w-100" />
                    <div className="title">Mississauga</div>
                  </div>
                </a>
              </Col>
              <Col md={3}>
                <a href="/estate/London">
                  <div className="item">
                    <img src={FeaturedLondon} className="w-100" />
                    <div className="title">London</div>
                  </div>
                </a>
              </Col>
              <Col md={3}>
                <a href="/estate/Brampton">
                  <div className="item">
                    <img src={FeaturedBrampton} className="w-100" />
                    <div className="title">Brampton</div>
                  </div>
                </a>
              </Col>
              <Col md={3}>
                <a href="/estate/Vaughan">
                  <div className="item">
                    <img src={FeaturedVaughan} className="w-100" />
                    <div className="title">Vaughan</div>
                  </div>
                </a>
              </Col>
              <Col md={3}>
                <a href="/estate/Windsor">
                  <div className="item">
                    <img src={FeaturedWindsor} className="w-100" />
                    <div className="title">Windsor</div>
                  </div>
                </a>
              </Col>      
              <Col md={3}>
                <a href="/estate/Oakville">
                  <div className="item">
                    <img src={FeaturedOakville} className="w-100" />
                    <div className="title">Oakville</div>
                  </div>
                </a>
              </Col>    
              <Col md={3}>
                <a href="/estate/Barrie">
                  <div className="item">
                    <img src={FeaturedBarrie} className="w-100" />
                    <div className="title">Barrie</div>
                  </div>
                </a>
              </Col>    
              <Col md={3}>
                <a href="/estate/Burlington">
                  <div className="item">
                    <img src={FeaturedBurlington} className="w-100" />
                    <div className="title">Burlington</div>
                  </div>
                </a>
              </Col>    
              <Col md={3}>
                <a href="/estate/Kitchener">
                  <div className="item">
                    <img src={FeaturedKitchener} className="w-100" />
                    <div className="title">Kitchener</div>
                  </div>
                </a>
              </Col>    
              <Col md={3}>
                <a href="/estate/Markham">
                  <div className="item">
                    <img src={FeaturedMarkham} className="w-100" />
                    <div className="title">Markham</div>
                  </div>
                </a>
              </Col> 
              <Col md={3}>
                <a href="/estate/Ajax">
                  <div className="item">
                    <img src={FeaturedAjax} className="w-100" />
                    <div className="title">Ajax</div>
                  </div>
                </a>
              </Col> 
              <Col md={3}>
                <a href="/estate/Oshawa">
                  <div className="item">
                    <img src={FeaturedOshawa} className="w-100" />
                    <div className="title">Oshawa</div>
                  </div>
                </a>
              </Col> 
              <Col md={3}>
                <a href="/estate/Scarborough">
                  <div className="item">
                    <img src={FeaturedScarborough} className="w-100" />
                    <div className="title">Scarborough</div>
                  </div>
                </a>
              </Col> 
            </div>
          </div>
        </section>

        <section className="mls_update py-5">
          <div className="container mb-3">
            <div className="col-md-6 pr-md-5 px-5 px-md-0">
              <div className="pr-5 pl-md-3">
                <h5 className="text-white text-center mt-3 mb-4">
                  Do you need to get MLS Updates?
                </h5>
                <p className="text-center text-white py-3">
                Access to active MLS listings that are updated in real-time is essential for buyers and sellers in the competitive real estate market.
                </p>
                <div className="d-flex justify-content-center">
                  <Link to="/contact-us"><div className="btn bg_secondary text-center text-white">
                    Contact Us
                  </div></Link>
                </div>
              </div>
            </div>            
          </div>
        </section>

        <section className="highlights">
          <div className="d-md-flex justify-content-between item">
            <div
              className="bg_cover col-md-6 bg_norepeat bg_center img"
              style={{
                backgroundImage:
                  "url(img/guna_homes_your_search_starts_here.png)",
              }}
            ></div>

            <div className="bg-white py-5 col-md-6 px-5 d-flex align-items-center">
              <div>
                <h5 className="text-center fw_bold">Your Search Starts Here</h5>
                <p className="text-center py-3">
                  Search, compare and select from homes, condos, land for sale,
                  and more! Save your searches and favorite properties for later
                  review, get email updates of new listings, price reductions,
                  and even schedule your property showings online.
                </p>
                <Link to="/estate/toronto"><div className="d-flex justify-content-center">
                  <div className="btn bg_secondary text-center text-white">
                    Start Search
                  </div>
                </div></Link>
              </div>
            </div>
          </div>
        </section>

        <section className="highlights">
          <div className="d-flex flex-md-row flex-column justify-content-between item flex-column-reverse">
            <div className="bg_gray py-5 col-md-6 px-5 d-flex align-items-center">
              <div>
                <h5 className="text-center text-white fw_bold">
                  What's My Home Worth?
                </h5>
                <p className="text-center py-3 text-white">
                  Looking to sell your home? You can count on a quick sale with
                  the best price, terms, and conditions possible while getting
                  maximum listing exposure across multiple sources. Get your
                  Free Home Valuation now!
                </p>
                <Link to="/contact-us"><div className="d-flex justify-content-center">
                  <div className="btn bg_secondary text-center text-white">
                    Find our Instantly
                  </div>
                </div></Link>
              </div>
            </div>
            <div
              className="bg_cover col-md-6 bg_norepeat bg_center img"
              style={{ backgroundImage: "url(img/whtas_my_home_worth.png)" }}
            ></div>
          </div>
        </section>

        <section className="highlights">
          <div className="d-md-flex justify-content-between item">
            <div
              className="bg_cover col-md-6 bg_norepeat bg_center img"
              style={{ backgroundImage: "url(img/listing_alerts.png)" }}
            ></div>
            <div className="bg-white py-5 col-md-6 px-5 d-flex align-items-center">
              <div>
                <h5 className="text-center fw_bold">Listing Alerts</h5>
                <p className="text-center py-3">
                  Subscribe and receive instant Listing alerts as soon as your
                  Dream Home hits the market. Create a Free Account today and
                  receive updated Market Reports surrounding highly desired
                  communities.
                </p>
                <Link to="/contact-us"><div className="d-flex justify-content-center">
                  <div className="btn bg_secondary text-center text-white">
                    Subscribe Now
                  </div>
                </div></Link>
              </div>
            </div>
          </div>
        </section>

        <section className="highlights">
          <div className="d-flex flex-md-row flex-column justify-content-between item flex-column-reverse">
            <div className="bg_gray py-5 col-md-6 px-5 d-flex align-items-center">
              <div>
                <h5 className="text-center text-white fw_bold">
                  Buyer & Seller Resources
                </h5>
                <p className="text-center py-3 text-white">
                  Listing and Buying your home can be overwhelming. Read over
                  our online resource directory and discover tips to save
                  thousands of dollars.
                </p>
                <Link to="/buyers"><div className="d-flex justify-content-center">
                  <div className="btn bg_secondary text-center text-white">
                    Learn More
                  </div>
                </div></Link>
              </div>
            </div>
            <div
              className="bg_cover col-md-6 bg_norepeat bg_center img"
              style={{
                backgroundImage: "url(img/buyers_and_sellers_resources.png)",
              }}
            ></div>
          </div>
        </section>

        <section className="highlights">
          <div className="d-md-flex justify-content-between item">
            <div
              className="bg_cover col-md-6 bg_norepeat bg_center img"
              style={{ backgroundImage: "url(img/pre_approved.png)" }}
            ></div>
            <div className="bg-white py-5 col-md-6 px-5 d-flex align-items-center">
              <div>
                <h5 className="text-center fw_bold">Pre-Approved</h5>
                <p className="text-center py-3">
                  Our partners offer low rates, low fees and local underwriting
                  so we can close loans quickly. Technology-driven with years of
                  experience, they have simplified mortgage lending options and
                  keep you in the loop through the entire process.
                </p>
                <Link to="/contact-us"><div className="d-flex justify-content-center">
                  <div className="btn bg_secondary text-center text-white">
                    Get Started Now
                  </div>
                </div></Link>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>

      <section className="py-5 mt-4">
        <h3 className="text-center fw_bold mb-5 py-5">Latest Blogs</h3>

        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="row">
                {blogs &&
                  blogs.map((post, index) => (
                    <div class="col-md-4 col-xl-3" key={index}>
                      <PostCard
                        blog={post}
                        history={history}
                        getBlogPost={getBlogPost}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterComponent />
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) => ({
  getAllProperties: (data) => {
    dispatch(fetchingAndSetProperties(data));
  },
  saveSearchResults: (data) => {
    dispatch(saveSearchResults(data));
  },
  saveSearchData: (data) => {
    dispatch(saveSearchData(data));
  },
  saveBlogPosts: (payload) => {
    dispatch(saveBlogPosts(payload));
  },
  getBlogPost: (id) => {
    dispatch(getBlogPost(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
