import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { cloneDeep, uniqBy } from "lodash";
import axios from "../../../common/ajax";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import LinearProgress from "@mui/material/LinearProgress";
import Swal from "sweetalert2";
import moment from "moment";
import BackupIcon from "@mui/icons-material/Backup";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Skeleton from "@mui/material/Skeleton";
import DangerousIcon from "@mui/icons-material/Dangerous";
import Tooltip from "@mui/material/Tooltip";

import RootElement from "../components/RootElement";
import PropertyModel from "../models/PropertyModel";
import SettingModel from "../models/SettingModel";

import InfiniteScroll from "react-infinite-scroll-component";

import AddNewForm from "../components/forms/AddNewProperty";
import EditForm from "../components/forms/EditProperty";

import SearchInput from "../../../componets/SearchInput";
import NoImage from "../../../assets/img/no_image_1.jpg";

// import { RMIUploader } from "react-multiple-image-uploader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 90 + "%",
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 4,
  height: "100vh",
  overflowX: "hidden",
};

const uploadStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 80 + "%",
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 4,
  height: "90vh",
  overflowX: "hidden",
};

export default function OwnProperties(props) {
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // Custom
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [properties, setProperties] = useState([]);
  // const [propertyType, setPropertyType] = useState("own");
  const [nextPage, setNextPage] = useState("");
  const [deletedImages, setDeletedImages] = useState([]);

  let history = useHistory();

  const fetchProperties = async () => {
    try {
      const res = await PropertyModel.fetchProperties('own');
      // console.log("obj", res);
      setProperties(res.data);
      setNextPage(res.next_page_url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // console.log("error => ", error.message);

      if (error.message.includes("longer be refreshed")) {
        localStorage.removeItem("auth");
        history.push("/admin/login");
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchProperties();
  }, []);

  const deleteProperty = async (id, type, index) => {
    await confirmMsg("You want to delete this entry?");

    setLoading(true);
    try {
      const data = {
        property_id: id,
        property_type: "own",
      };

      await PropertyModel.deleteProperty(data);
      fetchProperties();
      showAlert("Deleted!", `Property #${id} successfully deleted`, "success");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error =>", error);
    }
  };

  const confirmMsg = async (text) => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "Are you sure?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          resolve();
        }
      });
    });
  };

  const showAlert = (title, msg, status) => {
    Swal.fire(title, msg, status);
  };

  const [propData, setPropData] = useState({});
  const [openUpload, setOpenUpload] = useState(false);

  const handleOpenUpload = (data) => {
    // console.log("open popup ", data);
    setOpenUpload(true);

    let _data = data;

    if (data.images.length > 0) {
      for (var i = data.images.length - 1; i >= 0; i--) {
        _data.images[i] = {
          ...data.images[i],
          mainImage: data.main_img === data.images[i].url ? true : false,
          src: data.images[i].url,
          // src: `${process.env.REACT_APP_BASE_URL}/backend/public/storage/images/${data.property_id}/${data.images[i].image}`,
          data: null,
        };
      }
    }

    setPropData(data);

    setImages(_data.images);
    setCount(count + 1);
  };

  const handleCloseUpload = () => {
    setImages([]);
    setCount(1);
    setOpenUpload(false);
  };

  const [images, setImages] = useState([]);
  // const [rawImageFiles, setRawImageFiles] = useState(null);
  const [count, setCount] = useState(0);

  const handleFileChange = async (e) => {
    console.log(e.target.files);
    const files = e.target.files;

    // setRawImageFiles(files);

    const _images = images;

    for (var i = files.length - 1; i >= 0; i--) {
      const imageBase64 = await convert(files[i]);

      const data = {
        // name: files[i].name,
        src: URL.createObjectURL(files[i]),
        // type: files[i].type,
        data: imageBase64,
        mainImage: false,
      };

      _images.push(data);
    }

    setImages(_images);

    setTimeout(function () {
      setCount(count + 1);
    }, 500);

    // console.log("files", files);
    // console.log("images", images);

    // addNewProperty({ images: _images }, "added");
  };

  const handleUploadImages = async (id) => {
    setUploading(true);

    // return;
    // console.log("Images => ", images);
    // console.log("Deleted Images => ", deletedImages);

    const filtered = images.filter((img) => img.data != null);

    if (images.length === 0) {
      handleCloseUpload();
      console.log(`no images => `);
      Swal.fire("Warning", "Please upload at least one image", "error");
      return;
    }

    try {
      if (filtered.length > 0) {
        await PropertyModel.uploadImages({ property_id: id, images: filtered });
      }

      if (deletedImages.length > 0) {
        await PropertyModel.deleteImages(deletedImages);
        setDeletedImages([]);
      }

      await fetchProperties();

      handleCloseUpload();
      Swal.fire("Done", "Successfully uploaded", "success");
      setUploading(false);
      setImages([]);
      setCount(1);
    } catch (error) {
      setUploading(false);
      console.log("error", error);
      Swal.fire("Error", "Something went wrong", "error");
    }
  };

  const markAsMainImage = (index) => {
    // console.log("markAsMainImage", index);
    // console.log("markAsMainImage Images", images);

    let imagesCopy = images;

    for (var i = 0; imagesCopy.length - 1 >= i; i++) {
      // console.log("I ", i);
      // console.log("img ", imagesCopy[i]);

      if (i === index) {
        imagesCopy[i].mainImage = true;
      } else {
        imagesCopy[i].mainImage = false;
      }
      setImages(imagesCopy);
      setCount(count + 1);
    }
  };

  const convert = (img) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onloadend = function () {
        // console.log("RESULT", reader.result);
        resolve(reader.result);
      };
      reader.readAsDataURL(img);
    });
  };

  const fetchMore = async () => {
    try {
      setLoading(true);
      // console.log("Fetching more");

      // console.log("fetchMore ", requestData);

      let _temp = cloneDeep(properties);

      const res = await axios.get(nextPage);

      // console.log("res", res);

      _temp.push(...res.data.data);

      // console.log("i", _temp);

      setProperties(_temp);
      setNextPage(res.data.next_page_url);
      setLoading(false);
    } catch (error) {
      console.log("error ", error);
    }
  };

  const deleteImage = async (i) => {
    let _temp = cloneDeep(images);

    const newImageArray = _temp.filter((value, index) => index !== i);

    // console.log("imgs", newImageArray);

    setImages(newImageArray);
    setDeletedImages([...deletedImages, _temp[i]]);
    setCount(count + 1);
  };

  const [openEditForm, setOpenEditForm] = useState(false);
  const [openEditFormData, setOpenEditFormData] = useState({});

  const handleEditOpen = (payload) => {
    setOpenEditForm(true);
    setOpenEditFormData(payload);
  };

  const closeEditForm = () => {
    setOpenEditForm(false);
  };
  const [matched, setMatched] = useState({
    status: false,
    data: [],
  });

  const [typing, setTyping] = useState(false);

  const searchInTable = (input) => {
    if (input === "") {
      setMatched({ status: false, data: [] });
      setTyping(false);
      setCount(count + 1);
    }

    // user must type al least 2 charactors
    if (input.length <= 2) {
      return;
    }

    if (!typing) {
      setTyping(true);
    }

    setTimeout(function () {
      var results = []; // store any matching results
      var inputText = "" + input; //JS match needs string for macth()

      inputText = inputText.toLowerCase();

      for (var i = properties.length - 1; i >= 0; i--) {
        Object.entries(properties[i]).map(([key, value]) => {
          if (input !== "") {
            //
            const propValue = "" + value;
            const propValueLc = propValue.toLowerCase();

            let result = propValueLc.match(inputText);

            if (result) {
              const data = {
                index: i,
                property: properties[i],
              };

              results.push(data);
            }

          }
          return '';
        });
      }

      // console.log("results", results);

      if (results.length > 0) {
        setMatched({ status: true, data: uniqBy(results, "index") });
        setTyping(false);
        setCount(count + 1);
        return;
      }

      setMatched({ status: false, data: [] });
      setTyping(false);
      setCount(count + 1);
    }, 1000);
  };

  const handleAddToOwnListing = async (id, type) => {
    try {
      // true means adding
      if (type) {
        await confirmMsg(
          "Do you want this property to be shown in Guna's Listing?"
        );
      } else {
        await confirmMsg(
          "Do you want this property to be removed from Guna's Listing?"
        );
      }

      setLoading(true);

      await PropertyModel.addToOwnListings({ property_id: id, type });
      Swal.fire("Done", "Successfully added to list", "success");
      await fetchProperties();

      setLoading(false);
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  };

  const [municipality, setMunicipality] = useState({
    loading: true,
    data: [],
  });
  const [propertyTypes, setPropertyTypes] = useState({
    loading: true,
    data: [],
  });
  const [listingTypes, setListingTypes] = useState({
    loading: true,
    data: [],
  });

  async function fetchMunicipality() {
    setMunicipality({
      loading: true,
      data: [],
    });

    try {
      const res = await SettingModel.fetchMunicipality();
      setMunicipality({
        loading: false,
        data: res.map((data) => data.Municipality),
      });
    } catch (error) {
      console.log("error ", error);
    }
  }

  async function fetchPropertyTypes() {
    setPropertyTypes({
      loading: true,
      data: [],
    });

    try {
      const res = await SettingModel.fetchPropertyTypes();
      setPropertyTypes({
        loading: false,
        data: res.map((data) => data.prop_type),
      });
    } catch (error) {
      console.log("error ", error);
    }
  }

  async function fetchListingTypes() {
    setListingTypes({
      loading: true,
      data: [],
    });

    try {
      const res = await SettingModel.fetchListingTypes();
      setListingTypes({
        loading: false,
        data: res.map((data) => data.type),
      });
    } catch (error) {
      console.log("error ", error);
    }
  }

  useEffect(() => {
    fetchMunicipality();
    fetchPropertyTypes();
    fetchListingTypes();

    return () => {
      fetchMunicipality();
      fetchPropertyTypes();
      fetchListingTypes();
    };
  }, []);

  return (
    <RootElement active="own">
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <div className="d-flex justify-content-between align-items-center mt-5 mb-2">
        <h5 className="">OWN PROPERTIES</h5>

        <div className="d-flex justify-content-center align-items-center">
          <div className="pr-3">
            <SearchInput getValue={(value) => searchInTable(value)} />
          </div>

          <Button
            variant="contained"
            color="success"
            startIcon={<AddBusinessIcon />}
            onClick={handleOpen}
          >
            Add
          </Button>
        </div>
      </div>

      <div className="w-80">
        {typing ? (
          <div className="my-3">
            <div className="mb-1">
              <Skeleton animation="wave" variant="rectangular" height={50} />
            </div>
            <div>
              <Skeleton animation="wave" variant="rectangular" height={100} />
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {matched.status && (
          <div>
            <h5 className="text-center">Seacrh Results</h5>

            <table class="table">
              <thead>
                <tr>
                  <th>
                    #
                  </th>
                  <th>
                    Property ID
                  </th>
                  <th>
                    Type
                  </th>
                  <th>
                    ML Number
                  </th>
                  <th>
                    Address
                  </th>
                  <th>
                    Price
                  </th>

                  <th>
                    Added on
                  </th>
                  <th>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {matched.data.map((item, index) => (
                  <tr
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <td>
                      <div className="">
                        <img
                          src={`${item.property.images
                            ? item.property.main_img
                            : NoImage
                            }`}
                          className=""
                          alt=""
                          height={100}
                          width={150}
                        />
                      </div>
                    </td>
                    <td component="th">
                      {item.property.property_id}
                    </td>
                    <td>{item.property.property_type}</td>
                    <td>{item.property.Ml_num}</td>
                    <td>
                      {item.property.Addr}, {item.property.Municipality},{" "}
                      {item.property.Area}
                    </td>
                    <td>${item.property.Orig_dol}</td>
                    <td>
                      {moment(item.property.created_at).format("YYYY-MM-DD")}
                    </td>
                    <td>
                      <Stack direction="row" spacing={2}>
                        <div className="border rounded">
                          <Tooltip title="View Details">
                            <IconButton
                              color="primary"
                              aria-label="details"
                              component="span"
                              onClick={() =>
                                history.push(
                                  `/admin/estate/details/${item.property.property_id}/${item.property.property_type}`
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className="border rounded">
                          <Tooltip title="Edit Details">
                            <IconButton
                              color="warning"
                              aria-label="edit"
                              component="span"
                              onClick={() => handleEditOpen(item.property)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </div>

                        {item.property.in_own_listing ? (
                          <div className="border rounded">
                            <Tooltip title="Remove from Team Guna Listings">
                              <IconButton
                                color="error"
                                aria-label="add to list"
                                component="span"
                                onClick={() =>
                                  handleAddToOwnListing(
                                    item.property.property_id,
                                    false
                                  )
                                }
                              >
                                <DangerousIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="border rounded">
                            <Tooltip title="Add to Team Guna Listings">
                              <IconButton
                                color="primary"
                                aria-label="add to list"
                                component="span"
                                onClick={() =>
                                  handleAddToOwnListing(
                                    item.property.property_id,
                                    true
                                  )
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}

                        <div className="border rounded">
                          <Tooltip title="Upload Images">
                            <IconButton
                              color="success"
                              aria-label="upload"
                              component="span"
                              onClick={() => handleOpenUpload(item.property)}
                            >
                              <BackupIcon />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className="border rounded">
                          <Tooltip title="Remove Property">
                            <IconButton
                              color="error"
                              aria-label="remove"
                              component="span"
                              onClick={() =>
                                deleteProperty(
                                  item.property.property_id,
                                  item.property.property_type,
                                  item.index
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Stack>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr className="my-5" />
          </div>
        )}
      </div>

      <InfiniteScroll
        dataLength={properties.length} //This is important field to render the next data
        next={fetchMore}
        height={900}
        hasMore={nextPage ? true : false}
        loader={
          <div class="text-center my-3">
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <div>
          <table class="table">
            <thead>
              <tr className="cutom-tr-font-size">
                <th>#</th>
                <th>
                  Property ID
                </th>
                <th>
                  Type
                </th>
                <th>
                  ML Number
                </th>
                {/*<th>
                  Name
                </th>*/}
                <th>
                  Address
                </th>
                <th>
                  Price
                </th>
                <th>
                  Added on
                </th>
                <th>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {properties.map((row, index) => (
                <tr
                  key={index}
                  className="cutom-tr-font-size bg-white"
                >
                  <td>
                    <div className="">
                      <img
                        src={`${row.main_img ? row.main_img : NoImage}`}
                        className=""
                        alt=""
                        height={100}
                        width={150}
                      />
                    </div>
                  </td>
                  <td component="th">
                    {row.property_id}
                  </td>
                  <td>{row.property_type}</td>
                  <td>{row.Ml_num}</td>
                  {/*<td>{row.property_name}</td>*/}
                  <td>
                    {row.Addr}, {row.Municipality}, {row.Area}
                  </td>
                  <td>${row.Orig_dol}</td>
                  <td>{moment(row.created_at).format("YYYY-MM-DD")}</td>
                  <td>
                    <Stack direction="row" spacing={2}>
                      <div className="border rounded">
                        <Tooltip title="View Details">
                          <IconButton
                            color="primary"
                            aria-label="details"
                            component="span"
                            onClick={() =>
                              history.push(
                                `/admin/estate/details/${row.property_id}/${row.property_type}`
                              )
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </div>

                      <div className="border rounded">
                        <Tooltip title="Edit Details">
                          <IconButton
                            color="warning"
                            aria-label="edit"
                            component="span"
                            onClick={() => handleEditOpen(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </div>

                      {row.in_own_listing ? (
                        <div className="border rounded">
                          <Tooltip title="Remove from Team Guna Listings">
                            <IconButton
                              color="error"
                              aria-label="add to list"
                              component="span"
                              onClick={() =>
                                handleAddToOwnListing(row.property_id, false)
                              }
                            >
                              <DangerousIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <div className="border rounded">
                          <Tooltip title="Add to Team Guna Listings">
                            <IconButton
                              color="primary"
                              aria-label="add to list"
                              component="span"
                              onClick={() =>
                                handleAddToOwnListing(row.property_id, true)
                              }
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}

                      <div className="border rounded">
                        <Tooltip title="Upload Images">
                          <IconButton
                            color="success"
                            aria-label="upload"
                            component="span"
                            onClick={() => handleOpenUpload(row)}
                          >
                            <BackupIcon />
                          </IconButton>
                        </Tooltip>
                      </div>

                      <div className="border rounded">
                        <Tooltip title="Remove Property">
                          <IconButton
                            color="error"
                            aria-label="remove"
                            component="span"
                            onClick={() =>
                              deleteProperty(
                                row.property_id,
                                row.property_type,
                                index
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>

                      {/* <Button
                        variant="outlined"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => handleEditOpen(row)}
                      >
                        Edit
                      </Button>

                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<BackupIcon />}
                        onClick={() => handleOpenUpload(row)}
                      >
                        Upload
                      </Button>

                      <Button
                        color="error"
                        variant="outlined"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() =>
                          deleteProperty(
                            row.property_id,
                            row.property_type,
                            index
                          )
                        }
                      >
                        Remove
                      </Button>*/}
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="new-property-form"
        aria-describedby="new-property-form-description"
        style={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          <AddNewForm
            closeModal={handleClose}
            fetchProperties={fetchProperties}
            municipality={municipality.data}
            propertyTypes={propertyTypes.data}
            listingTypes={listingTypes.data}
          />
        </Box>
      </Modal>

      <Modal
        open={openEditForm}
        onClose={closeEditForm}
        aria-labelledby="edit-property-form"
        aria-describedby="edit-property-form-description"
        style={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          <EditForm
            initialValues={openEditFormData}
            closeModal={closeEditForm}
            fetchProperties={fetchProperties}
            municipality={municipality.data}
            propertyTypes={propertyTypes.data}
            listingTypes={listingTypes.data}
          />
        </Box>
      </Modal>

      <Modal
        open={openUpload}
        onClose={handleCloseUpload}
        aria-labelledby="modal-modal-upload"
        aria-describedby="modal-modal-description-upload"
      >
        <Box sx={uploadStyle}>
          {/*<div className="App">
            <button onClick={handleSetVisible}>Show Me</button>
            <RMIUploader
              isOpen={visible}
              hideModal={hideModal}
              onSelect={onSelect}
              onUpload={onUpload}
              onRemove={onRemove}
              dataSources={dataSources}
            />
          </div>*/}

          <div className="d-flex justify-content-between align-items-center">
            <h5>Upload Images for the property : ID#{propData.property_id}</h5>
            <button
              className="btn btn-lg btn-primary btn-sm"
              disabled={uploading}
              onClick={() => handleUploadImages(propData.property_id)}
            >
              {!uploading ? (
                "Upload"
              ) : (
                <div className="px-3">
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </button>
          </div>
          <hr />

          <div className="mb-3">
            <div className="drag-drop-input">
              <input
                type="file"
                className="custom-input"
                id="photo-upload"
                aria-describedby="photoUpload"
                accept="image/*"
                multiple
                onChange={(e) => handleFileChange(e)}
              />
              <label for="photo-upload">Choose files or Drag & Drop</label>
            </div>
          </div>

          {/*<div>
            {propData.images &&
              propData.images.map((img) => (
                <img
                  src={img.src}
                  width="250"
                  alt=""
                />
              ))}
          </div>*/}

          <div className="d-md-flex justify-content-center row">
            {images.map((image, index) => (
              <div className="col-lg-4 m-1">
                <div className="any">
                  <img
                    src={image.src}
                    alt="..."
                    className="img-thumbnail"
                    key={index}
                    style={{ height: "250px" }}
                  />
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-sm btn-success mx-1"
                    disabled={image.mainImage}
                    onClick={() => markAsMainImage(index)}
                  >
                    Mark as Main Image
                  </button>
                  <IconButton
                    color="error"
                    aria-label="upload picture"
                    component="span"
                    disabled={uploading}
                    onClick={() => deleteImage(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </Box>
      </Modal>
    </RootElement>
  );
}
