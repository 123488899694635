import React from "react";

export default function ParagraphImage({ src, paragraph }) {
	return (
		<div
			className="image-paragraph"
			style={{ marginTop: 50, marginBottom: 50 }}
		>
			<table align="center">
				<tr>
					<td style={{ verticalAlign: "middle" }}>
						<div>
							<p
								style={{
									color: "#464646",
									textAlign: "center",
								}}
							>
								{paragraph}
							</p>
						</div>
					</td>
					<td>
						<img src={src} width="250" />
					</td>
				</tr>
			</table>
		</div>
	);
}
