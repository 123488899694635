export const SAVE_BLOG_POSTS = "SAVE_BLOG_POSTS";
export const SAVE_BLOG_POST = "SAVE_BLOG_POST";
export const GET_BLOG_POST = "GET_BLOG_POST";
export const SAVE_BLOG_CATEGORIES = "SAVE_BLOG_CATEGORIES";

export const UPDATE_LOADING = "UPDATE_LOADING";
export const SET_SHOWINGS = "SET_SHOWINGS";
export const UPDATE_SHOWINGS = "UPDATE_SHOWINGS";

export const GET_PROPERTY_LIST = "GET_PROPERTY_LIST";
export const GET_PROPERTY_LISTS = "GET_PROPERTY_LISTS";
export const SAVE_PROPERTY_LIST = "SAVE_PROPERTY_LIST";
export const REMOVE_PROPERTY_LIST = "REMOVE_PROPERTY_LIST";
