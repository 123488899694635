import HTTP from "../common/ajax";

class EnquiryhModel {
  async create(payload) {
    try {
      const res = await HTTP.post("admin/enquiries/new", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async create2(payload) {
    try {
      const res = await HTTP.post("public/enquiries/new", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async fetchEnquiries() {
    try {
      const res = await HTTP.get("admin/enquiries");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async reply(payload) {
    try {
      const res = await HTTP.post("admin/enquiries/reply", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async createAppointment(payload) {
    try {
      const res = await HTTP.post("public/appointments/new", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async fetchAppointments(type) {
    try {
      const res = await HTTP.get("admin/appointments/" + type);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async replyAppointment(payload) {
    try {
      const res = await HTTP.post("admin/appointments/reply", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async saveList(payload) {
    try {
      await HTTP.post("admin/appointments/showings/saveList", payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }
}

export default new EnquiryhModel();
