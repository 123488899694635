import React from "react";

import Stack from "@mui/material/Stack";

import EmailActionButton from "./EmailActionButton";

export default function EmailsTable({ data, deleteEntry, sendmailmodal }) {
	return (
		<table class="table">
			<thead class="thead-dark">
				<tr>
					<th scope="col">SN</th>
					<th scope="col">Group Type</th>
					<th scope="col">Total Entries</th>
					<th scope="col">Actions</th>
				</tr>
			</thead>
			<tbody>
				{data &&
					data.map((group, index) => (
						<tr key={index}>
							<th scope="row">{index + 1}</th>
							<td>{group.group_type}</td>
							<td>{group.total_entries}</td>
							<td>
								<Stack spacing={2} direction="row">
									<EmailActionButton
										data={group}
										icon="envelope"
										color="success"
										toolTip="Send Email"
										action={(data) => sendmailmodal(data)}
									/>

									<EmailActionButton
										data={group}
										icon="trash"
										color="danger"
										toolTip="Delete Entry"
										action={(id) => deleteEntry(id)}
									/>
								</Stack>
							</td>
						</tr>
					))}
			</tbody>
		</table>
	);
}
