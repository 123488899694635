import React, { useState, useEffect } from "react";
import { compose, withProps, withStateHandlers } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  Polygon,
} from "react-google-maps";

const _GoogleMap = ({ properties }) => {
  // google map
  const [mapPositionLat, setMapPositionLat] = useState(43.653226);
  const [mapPositionLng, setMapPositionLng] = useState(-79.383184);

  // console.log("properties => ", properties);

  const MyMapComponent = React.memo(
    compose(
      withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `500px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
        center: { lat: mapPositionLat, lng: mapPositionLng },
      }),
      withStateHandlers(
        () => ({
          isOpen: false,
          index: null,
          goTo: "goTo",
        }),
        {
          onToggleOpen:
            ({ isOpen, index }) =>
            ({ index }) => ({
              isOpen: !isOpen,
              index,
            }),
          navigateToPage: (mapState) => (mapProps) => {
            console.log("asss");
          },
        }
      ),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap defaultZoom={9} defaultCenter={props.center}>
        {properties &&
          properties.map((property, index) => {
            // console.log("property ", property);

            return (
              <Marker
                label={index + 1 + ""}
                defaultTitle={property.address}
                position={{
                  lat: parseFloat(property.latitude),
                  lng: parseFloat(property.longitude),
                }}
              />
            );
          })}
      </GoogleMap>
    ))
  );
  // 201 Winchester St, Toronto, ON M4X 1B8, Canada
  return (
    <div>
      <MyMapComponent
        isMarkerShown
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=places`}
        // googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `900px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default _GoogleMap;
