import { combineReducers } from "redux";
//import createNamedReducer from '../../common/reducers/createNamedReducer';

// reducers
import SearchReducer from "./SearchReducer";
import AppReducer from "./AppReducer";
// const entities = require("../entities/entities");
const properties = require("./properties/PropertiesReducer");
//import PropertiesReducer from './properties/PropertiesReducer';

const rootReducer = combineReducers({
  //   entities,
  properties,
  searchState: SearchReducer,
  appState: AppReducer,
});

export default rootReducer;
