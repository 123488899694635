import moment from "moment";
import "./css/PostCard.css";

const PostCard = ({ blog, history, getBlogPost }) => {
  return (
    <article className="post">
        <div className="shadow">
          <div>
            <a onClick={() => {
                  getBlogPost(blog.id);
                  history.push(`/blogs/${blog.category}/${blog.id}`);
                }} style={{ cursor: "pointer" }}
                >
              <img
                src={blog.featured_image_url}
                alt="blog image"
                className="post-card-image"
              />
            </a>
          </div>
        <div className="article-content-main p-4">
          <div className="article-header">
            <h2 className="entry-title">
              <p
                className="link-text tg-primary-text fw_bold"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  getBlogPost(blog.id);
                  history.push(`/blogs/${blog.category}/${blog.id}`);
                }}
              >
                {blog.title}
              </p>
            </h2>
            <div className="entry-meta">
              <div className="entry-date">
                {moment(blog.updated).format("DD MMM YYYY")}
              </div>
            </div>
          </div>

          <div className="article-content">
            <p className="excerpt">{blog.excerpt}</p>
          </div>

          <div className="article-footer">
            <div className="row">
              <div className="col-6 text-left footer-link">
                <p
                  className="more-link tg-primary-text"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    getBlogPost(blog.id);
                    history.push(`/blogs/${blog.category}/${blog.id}`);
                  }}
                >
                  Read More
                </p>
              </div>

              {/* <div className="col-6 text-right footer-meta">
                <a href="#">
                  65 <i className="bi bi-chat-right-dots"></i>
                </a>
                <a href="#">
                  50 <i className="bi bi-share"></i>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </article>  
  );
};

export default PostCard;
