import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import NumberFormat from "react-number-format";

// import NoImage from "./../assets/img/no-image.png";
import NoImage from "./../assets/img/featured_area_markham.png";

import ImageViewer from "./ImageViewer";

function FeaturedCard({ item, addToShowings }) {
  // console.log(`item => `, JSON.parse(item.propert_details));
  const history = useHistory();
  const [image, setImage] = useState({ url: "", index: 0 });
  const handleSetImage = (url, index) => setImage({ url, index });

  const [propertyDetails, setPropertyDetails] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const clickedOnImage = (item, index) => {
    // console.log(`img => `, img);
    /* setPropertyDetails(item);
    handleSetImage(prepareUrl(item.images[0].url), index);
    handleOpen(); */
  };

  function nextImage() {
    const IMGS = propertyDetails.images;
    const NEXT_INDEX = image.index + 1;

    if (IMGS.length - 1 < NEXT_INDEX) {
      handleSetImage(prepareUrl(IMGS[0].url), 0);
      return;
    }

    handleSetImage(prepareUrl(IMGS[NEXT_INDEX].url), NEXT_INDEX);
  }

  function previousImage() {
    const IMGS = propertyDetails.images;

    let NEXT_INDEX = image.index - 1;
    const ln = IMGS.length;

    if (NEXT_INDEX < 0) {
      handleSetImage(prepareUrl(IMGS[ln - 1].url), ln - 1);
      return;
    }

    handleSetImage(prepareUrl(IMGS[NEXT_INDEX].url), NEXT_INDEX);
  }

  function prepareUrl(url) {
    return `${process.env.REACT_APP_IMG_SOURCE}/${url}`;
  }

  return (
    <div class="card shadow properpty_listing" style={{ borderRadius: 0 }}>
      <Link to={`/estate/details/${item.property_id}/${item.property_type}`}>
        {/*   <div class="bg-success text-white px-2 py-1 position-absolute vm_fs_13">Residential</div> */}
        <img
          src={`${item.images.length > 0 ? process.env.REACT_APP_IMG_SOURCE + '/' + item.images[0].url : NoImage}`}
          className="prop_image card-img-top"
          alt="..."
          onClick={() => clickedOnImage(item, 0)}
        />
      </Link>

      {/*<Link to={`/estate/details/${item.property_id}/${item.property_type}`}>*/}
      <div class="p-2">
        <Link to={`/estate/details/${item.property_id}/${item.property_type}`}>
          <div className="card-text">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mr-2">
                <h6
                  className="font-weight-bold pt-1"
                  style={{ fontSize: "14px" }}
                >
                  {/* {item.property_type} */}
                  <NumberFormat
                    style={{
                      width: "100%",
                      fontSize: "18px",
                      border: "none",
                      fontWeight: "bold",
                    }}
                    value={item.Orig_dol}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </h6>
              </div>
            </div>
          </div>
          <div>
            <p
              className="text-truncate vm_fs_13 vm_lh_18">
              {item.Addr}, {item.Municipality}
            </p>

            <p
              className="text-truncate vm_fs_13 vm_lh_18 pb-2 pt-1">
              {item.bed_rooms} Bed &nbsp; | &nbsp;
              {item.bath_rooms} Bath &nbsp;  | {item.park_spcs} Parking
            </p>
          </div>
        </Link>
        <div className="">
          {/* <button
            className="btn btn-sm bg_secondary text-white w-50 rounded-0 mr-1"
            style={{ fontSize: 12 }}
            onClick={() =>
              history.push(
                `/estate/details/${item.property_id}/${item.property_type}`
              )
            }
          >
            DETAILS
          </button> */}
          <button className="btn btn-sm btn-block bg_primary rounded-0 text-white vm_fs_13" onClick={() => addToShowings(item)}>
            Add to Showing
          </button>
        </div>
      </div>


      <ImageViewer
        open={open}
        onClose={() => handleClose()}
        previousImage={() => previousImage()}
        nextImage={() => nextImage()}
        image={image.url}
      />
    </div>
  );
}

export default React.memo(FeaturedCard);
