import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import "../../../assets/css/footer.css";
import FooterLogo from "../../../assets/img/footer_logo.png";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import ProfileModel from "../../../Models/ProfileModel";

const Footer = () => {  
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <footer>
      <Container>
        <div className="top">
          <div className="d-md-flex pb-4">
            <div className="d-flex justify-content-center d-md-block">
              <Link to="/"><Image src={FooterLogo} alt="" /></Link>
            </div>
            <div className="line"></div>
            <div className="col pe-5">
              <p className="text-white ps-4 text-center text-md-start pt-4 pt-md-0 pb-4 pb-md-0">
                Too often you find the house of your dreams and call the agent
                only to find the property "SOLD".....That is why...
              </p>
            </div>
            <div className="pb-4 pb-md-0">
              <div className="d-flex justify-content-md-start justify-content-center align-items-center">
                <Icon.PhoneVibrate color="red" size={55} />
                <h3 className="mt-2 ml-2 text-white text-center text-md-start">
                  (416) 528-1407
                </h3>
              </div>
            </div>
          </div>
        </div>
        <Row className="pt-5 px-5 px-md-0">
          <Col lg={3} md={4}>
            <div className="d-flex align-items-center">
              <Icon.GeoAltFill color="white" size={25} />
              <h5 className="text-white pt-1 ps-2">Areas We Cover</h5>
            </div>
            <div className="d-flex pt-3">
              <div>
                <Link to="/estate/Toronto"><p className="text-white">Toronto</p></Link>
                <Link to="/estate/Ottawa"><p className="text-white">Ottawa</p></Link>
                <Link to="/estate/Hamilton"><p className="text-white">Hamilton</p></Link>
                <Link to="/estate/Mississauga"><p className="text-white">Mississauga</p></Link>
                <Link to="/estate/London"><p className="text-white">London</p></Link>
                <Link to="/estate/Brampton"><p className="text-white">Brampton</p></Link>
                <Link to="/estate/Vaughan"><p className="text-white">Vaughan</p></Link>
                <Link to="/estate/Windsor"><p className="text-white">Windsor</p></Link>
              </div>
              <div className="pl-5">
                <Link to="/estate/Oakville"><p className="text-white">Oakville</p></Link>
                <Link to="/estate/Barrie"><p className="text-white">Barrie</p></Link>
                <Link to="/estate/Burlington"><p className="text-white">Burlington</p></Link>
                <Link to="/estate/Kitchener"><p className="text-white">Kitchener</p></Link>
                <Link to="/estate/Markham"><p className="text-white">Markham</p></Link>
                <Link to="/estate/Ajax"><p className="text-white">Ajax</p></Link>
                <Link to="/estate/Oshawa"><p className="text-white">Oshawa</p></Link>
                <Link to="/estate/Scarborough"><p className="text-white">Scarborough</p></Link>
              </div>
            </div>
          </Col>
          <Col lg={3} md={4} className="pt-5 pt-md-0">
            <h5 className="text-white">Important Links</h5>
            <div className="pt-3">
              <Link to="/estate/toronto"><p className="text-white">Property Listing</p></Link>
              <Link to="/commerical"><p className="text-white">Commerical Properties</p></Link>
              <Link to="/blogs"><p className="text-white">Blogs</p></Link>
              <Link to="/calculators"><p className="text-white">Calculators</p></Link>
              <Link to="/buyers"><p className="text-white">Buy</p></Link>
              <Link to="/sellers"><p className="text-white">Sell</p></Link>
              <Link to="/contact-us"><p className="text-white">Contact us</p></Link>
            </div>
          </Col>
          <Col lg={3} md={4} className="pt-5 pt-md-0">
            <h5 className="text-white">Contact us</h5>
            <p className="text-white pt-3">
              Please don't hesitate to contact us if you have any questions
            </p>
            <p className="d-flex text-white pt-3">
              <Icon.GeoAltFill color="red" size={35} />
              <span className="pl-2">
                <a
                  className="text-white"
                  href="https://www.google.com/maps/search/?api=1&query={process.env.REACT_APP_ADDRESS1}"
                  target="_blank"
                >
                 {process.env.REACT_APP_ADDRESS1_1}<br/>
                 {process.env.REACT_APP_ADDRESS1_2}
                </a>
              </span>
            </p>
            <p className="d-flex text-white pt-2">
              <Icon.PhoneFill color="red" size={25} />
              <span className="pl-2">{process.env.REACT_APP_PHONE_NUMBER}</span>
            </p>
            <p className="d-flex text-white pt-2 align-items-center">
              <Icon.EnvelopeFill color="red" size={23} />
              <span className="pl-2">{process.env.REACT_APP_EMAIL}</span>
            </p>
          </Col>
          <Col lg={3} md={3} className="pt-5 pt-lg-0">
            <h5 className="text-white pb-4">Follow us</h5>
            <div className="d-md-flex d-lg-block d-block footer-links">
              <div
                className="d-flex border py-3 px-3 mt-md-0 mt-lg-3 justify-content-center align-items-center"
                onClick={() => openInNewTab(`${process.env.REACT_APP_FB_LINK}`)}
              >
                <Icon.Facebook color="white" size={25} />
                <p className="text-white pl-3">Facebook</p>
              </div>
              <div
                className="d-flex border py-3 px-3 mt-3 mt-md-0 mt-lg-3 ms-md-3 ms-lg-0 ms-0 justify-content-center align-items-center"
                onClick={() => openInNewTab(`${process.env.REACT_APP_TWITTER_LINK}`)}
              >
                <Icon.Twitter color="white" size={25} />
                <p className="text-white pl-3">Twitter</p>
              </div>
              <div
                className="d-flex border py-3 px-3 mt-3 mt-md-0 mt-lg-3 ms-md-3 ms-lg-0 ms-0 justify-content-center align-items-center"
                onClick={() => openInNewTab(`${process.env.REACT_APP_YOUTUBE_LINK}`)}
              >
                <Icon.Youtube color="white" size={25} />
                <p className="text-white pl-3">Youtube</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
