import {
  UPDATE_LOADING,
  SET_SHOWINGS,
  UPDATE_SHOWINGS,
  GET_PROPERTY_LIST,
  GET_PROPERTY_LISTS,
  SAVE_PROPERTY_LIST,
  REMOVE_PROPERTY_LIST,
  SAVE_BLOG_CATEGORIES,
  SAVE_BLOG_POSTS,
  SAVE_BLOG_POST,
  GET_BLOG_POST,
} from "../actions/App/AppActionTypes";

const initialState = {
  loading: true,
  showings: [],
  propertyList: [],
  blogCategories: [],
  blogPosts: [],
  blogPost: {},
};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_SHOWINGS:
      return {
        ...state,
        showings: [...state.showings, action.payload],
      };

    case UPDATE_SHOWINGS:
      return {
        ...state,
        showings: action.payload,
      };

    case GET_PROPERTY_LIST:
      return {
        ...state,
        propertyList: state.propertyList.find(
          (property) => property.property_id === action.payload
        ),
      };

    case GET_PROPERTY_LISTS:
      return {
        ...state,
        propertyList: state.propertyList,
      };

    case SAVE_PROPERTY_LIST:
      return {
        ...state,
        propertyList: action.payload,
      };

    case REMOVE_PROPERTY_LIST:
      return {
        ...state,
        propertyList: state.propertyList.filter(
          (property) => property.property_id !== action.payload
        ),
      };

    case SAVE_BLOG_CATEGORIES:
      return {
        ...state,
        blogCategories: action.payload,
      };

    case SAVE_BLOG_POSTS:
      return {
        ...state,
        blogPosts: action.payload,
      };

    case GET_BLOG_POST:
      return {
        ...state,
        blogPost: state.blogPosts.find((post) => post.id === action.payload.id),
      };
    case SAVE_BLOG_POST:
      return {
        ...state,
        blogPost: action.payload,
      };

    default: {
      return state;
    }
  }
};
export default SearchReducer;
