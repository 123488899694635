import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { cloneDeep, uniqBy } from "lodash";
import axios from "../../../common/ajax";
import moment from "moment";

import InfiniteScroll from "react-infinite-scroll-component";

import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";

import RootElement from "../components/RootElement";
import PropertyModel from "../models/PropertyModel";

import SearchInput from "../../../componets/SearchInput";

import NoImage from "../../../assets/img/no_image_1.jpg";

export default function Properties(props) {
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [propertyType, setPropertyType] = useState("resedential");
  const [nextPage, setNextPage] = useState("");
  const [count, setCount] = useState(0);
  let history = useHistory();

  const fetchProperties = async () => {
    try {
      const res = await PropertyModel.fetchProperties(propertyType);
      setProperties(res.data);
      setNextPage(res.next_page_url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error => ", error);
    }
  };

  const addToOwnProperties = async (payload) => {
    try {
      await confirmMsg("You want to add this property to Own Properties?");

      setLoading(true);
      const res = await PropertyModel.addToOwnProperties(payload);

      properties.forEach((property) => {
        if (property.property_id === payload.property_id) {
          property.own = true;
        }
      });

      // console.log("data ", data);

      showAlert(
        "Success!",
        `Property #${payload.property_id} successfully add to own property listing`,
        "success"
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error => ", error);
    }
  };

  const deleteProperty = async (id, type, index) => {
    await confirmMsg("You want to delete this entry?");

    setLoading(true);
    try {
      const data = {
        property_id: id,
        property_type: type,
      };

      await PropertyModel.deleteProperty(data);
      fetchProperties();
      showAlert("Deleted!", `Property #${id} successfully deleted`, "success");
    } catch (error) {
      console.log("Error =>", error);
    }
  };

  const confirmMsg = async (text) => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "Are you sure?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          resolve();
        }
      });
    });
  };

  const showAlert = (title, msg, status) => {
    Swal.fire(title, msg, status);
  };

  useEffect(() => {
    console.log("props", props);
    setLoading(true);
    fetchProperties();
  }, [propertyType]);

  const fetchMore = async () => {
    try {
      setLoading(true);
      console.log("Fetching more");

      // console.log("fetchMore ", requestData);

      let _temp = cloneDeep(properties);

      const res = await axios.get(nextPage);

      console.log("res", res);

      _temp.push(...res.data.data);

      console.log("i", _temp);

      setProperties(_temp);
      setNextPage(res.data.next_page_url);
      setLoading(false);
    } catch (error) {
      console.log("error ", error);
    }
  };

  const [matched, setMatched] = useState({
    status: false,
    data: [],
  });

  const [typing, setTyping] = useState(false);
  const searchInTable = (input) => {
    if (input === "") {
      setMatched({ status: false, data: [] });
      setTyping(false);
      setCount(count + 1);
    }

    // user must type al least 2 charactors
    if (input.length <= 2) {
      return;
    }

    if (!typing) {
      setTyping(true);
    }

    setTimeout(function () {
      var results = []; // store any matching results
      var inputText = "" + input; //JS match needs string for macth()

      inputText = inputText.toLowerCase();

      for (var i = properties.length - 1; i >= 0; i--) {
        Object.entries(properties[i]).map(([key, value]) => {
          if (input !== "") {
            //
            const propValue = "" + value;
            const propValueLc = propValue.toLowerCase();

            let result = propValueLc.match(inputText);

            if (result) {
              const data = {
                index: i,
                property: properties[i],
              };

              results.push(data);
            }

          }
          return '';
        });
      }

      // console.log("results", results);

      if (results.length > 0) {
        setMatched({ status: true, data: uniqBy(results, "index") });
        setTyping(false);
        setCount(count + 1);
        return;
      }

      setMatched({ status: false, data: [] });
      setTyping(false);
      setCount(count + 1);
    }, 1000);
  };

  return (
    <RootElement active="home">
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <div className="my-5">
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            color="primary"
            disabled={propertyType === "resedential" ? true : false}
            onClick={() => setPropertyType("resedential")}
          >
            Resedential
          </Button>
          <Button
            variant="contained"
            color="success"
            disabled={propertyType === "commercial" ? true : false}
            onClick={() => setPropertyType("commercial")}
          >
            Commercial
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={propertyType === "condo" ? true : false}
            onClick={() => setPropertyType("condo")}
          >
            Condo
          </Button>
        </Stack>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-2">
        <h5>{propertyType.toUpperCase()} PROPERTIES</h5>
        <div className="w-50">
          <SearchInput getValue={(value) => searchInTable(value)} />
        </div>
      </div>

      <div className="w-80 my-2">
        {typing ? (
          <div>
            <div className="mb-1">
              <Skeleton animation="wave" variant="rectangular" height={50} />
            </div>
            <div>
              <Skeleton animation="wave" variant="rectangular" height={100} />
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {matched.status && (
          <div>
            <h5 className="text-center">Seacrh Results</h5>

            <table className="table mb-3">
              <thead>
                <tr>
                  <th>
                    #
                  </th>
                  <th>
                    Property ID
                  </th>
                  <th>
                    Type
                  </th>
                  <th>
                    ML Number
                  </th>
                  <th>
                    Address
                  </th>
                  <th>
                    Price
                  </th>
                  <th>
                    Added on
                  </th>
                  <th>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {matched.data.map((item, index) => (
                  <tr
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <td>
                      <div className="">
                        <img
                          src={`${item.property.main_img
                            ? item.property.main_img
                            : NoImage
                            }`}
                          className=""
                          alt=""
                          height={100}
                        />
                      </div>
                    </td>
                    <td component="th">
                      {item.property.property_id}
                    </td>
                    <td>{item.property.property_type}</td>
                    <td>{item.property.Ml_num}</td>
                    <td>
                      {item.property.Addr}, {item.property.Municipality},{" "}
                      {item.property.Area}
                    </td>
                    <td>${item.property.Orig_dol}</td>
                    <td>
                      {moment(item.property.created_at).format("YYYY-MM-DD")}
                    </td>
                    <td>
                      <Stack direction="row" spacing={2}>
                        <div className="border rounded">
                          <Tooltip title="View Details">
                            <IconButton
                              color="primary"
                              aria-label="details"
                              component="span"
                              onClick={() =>
                                history.push(
                                  `/admin/estate/details/${item.property.property_id}/${item.property.property_type}`
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className="border rounded">
                          <Tooltip title="Add to Own Listings">
                            <IconButton
                              color="warning"
                              aria-label="details"
                              component="span"
                              onClick={() => addToOwnProperties(item.property)}
                              disabled={item.property.own && true}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className="border rounded">
                          <Tooltip title="Remove Property">
                            <IconButton
                              color="error"
                              aria-label="details"
                              component="span"
                              onClick={() =>
                                deleteProperty(
                                  item.property.property_id,
                                  item.property.property_type,
                                  item.index
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Stack>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr className="my-5" />
          </div>
        )}
      </div>

      <InfiniteScroll
        dataLength={properties.length} //This is important field to render the next data
        next={fetchMore}
        height={900}
        hasMore={nextPage ? true : false}
        loader={
          <div className="text-center my-3">
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <div>
          <table className="table">
            <thead>
              <tr className="cutom-tr-font-size">
                <th>#</th>
                <th>
                  Property ID
                </th>
                <th>
                  Type
                </th>
                <th>
                  ML Number
                </th>
                <th>
                  Address
                </th>
                <th>
                  Price
                </th>
                <th>
                  Added on
                </th>
                <th>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {properties.map((row, index) => (
                <>
                  <tr key={index} className="cutom-tr-font-size bg-white">
                    <td>
                      {/* src={`${row.main_img ? row.main_img : NoImage}`} */}
                      <div className="">
                        <img
                          src={`${row.main_img ? row.main_img : NoImage}`}
                          className=""
                          alt=""
                          height={100}
                        />
                      </div>
                    </td>
                    <td component="th">
                      {row.property_id}
                    </td>
                    <td>{row.property_type}</td>
                    <td>
                      <span className="badge badge-success">{row.Ml_num}</span>
                    </td>
                    <td>
                      {row.Addr}, {row.Municipality}, {row.Area}
                    </td>
                    <td>${row.Orig_dol}</td>
                    <td>{moment(row.created_at).format("YYYY-MM-DD")}</td>
                    <td>
                      <Stack direction="row" spacing={2}>
                        <div className="border rounded">
                          <Tooltip title="View Details">
                            <IconButton
                              color="primary"
                              aria-label="details"
                              component="span"
                              onClick={() =>
                                history.push(
                                  `/admin/estate/details/${row.property_id}/${row.property_type}`
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className="border rounded">
                          <Tooltip title="Add to Own Listings">
                            <IconButton
                              color="warning"
                              aria-label="details"
                              component="span"
                              onClick={() => addToOwnProperties(row)}
                              disabled={row.own && true}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className="border rounded">
                          <Tooltip title="Remove Property">
                            <IconButton
                              color="error"
                              aria-label="details"
                              component="span"
                              onClick={() =>
                                deleteProperty(
                                  row.property_id,
                                  row.property_type,
                                  index
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Stack>
                    </td>
                  </tr>

                  <hr style={{ height: 0, width: 0 }} />
                </>
              ))}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </RootElement>
  );
}
