import React from "react";
import TextField from "@mui/material/TextField";

export default function TextInput(props) {
  return (
    <TextField
      variant="outlined"
      error={props.error}
      helperText={props.errorMsg}
      value={props.value}
      onChange={(e) => props.getValue(e.target.value)}
      {...props}
    />
  );
}
