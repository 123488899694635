import HTTP from "../common/ajax";

class ShowingsModel {
  async showing(phoneNumber) {
    try {
      const res = await HTTP.get("public/showings/" + phoneNumber);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async saveShowing(payload) {
    try {
      await HTTP.post("public/showings", payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async fetchShowing(id) {
    try {
      const res = await HTTP.get("public/showings/get/" + id);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }
}

export default new ShowingsModel();
