import React from "react";

export default function Paragraph({ paragraph }) {
	return (
		<div
			className="image-paragraph"
			style={{ marginTop: 50, marginBottom: 50 }}
		>
			<p
				
				style={{ color: "#464646", textAlign: "center" }}
			>
				{paragraph}
			</p>
		</div>
	);
}
