import HTTP from "../common/ajax";

class TodoModel {
	async fetchTodos() {
		try {
			const res = await HTTP.get(`/admin/todos`);
			return Promise.resolve(res.data);
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}

	async saveTodo(payload) {
		try {
			const res = await HTTP.post(`/admin/todos`, payload);
			return Promise.resolve(res.data);
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}

	async updateTodo(id) {
		try {
			await HTTP.post(`/admin/todos/update/${id}`);
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}

	async saveCategory(payload) {
		try {
			await HTTP.post(`/admin/todos/categories`, payload);
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}
}

export default new TodoModel();
