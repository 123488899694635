import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import RootElement from "../components/RootElement";
import CommentModel from "../../../Models/CommentModel";

const Feedbacks = () => {

	const [loading, setLoading] = useState(true);
	const [searching, setSearching] = useState(false);
	const [keyword, setKeyword] = useState("");

	const [comments, setComments] = useState({ data: [] });

	const search = async () => {
		setSearching(true);

		if (!keyword) {
			fetchComments();
			return;
		}

		const res = await CommentModel.searchComments(keyword);
		setComments({ ...comments, data: res.data });

		setSearching(false);
	};

	const fetchComments = async () => {
		const res = await CommentModel.adminComments();

		setComments({ ...comments, ...res.data });

		setLoading(false);
		setSearching(false);
	};

	useEffect(() => {
		fetchComments();
	}, []);

	return (
		<RootElement>
			{loading && (
				<Box sx={{ width: "100%" }}>
					<LinearProgress />
				</Box>
			)}

			<div className="mt-5" style={{ minHeight: "100vh" }}>
				<div className="row justify-content-center my-5">
					<div className="col-md-8">
						<div className="bg-white shadow rounded p-1">
							<div className="d-md-flex justify-content-between align-items-center p-2">
								<input
									type="text"
									name=""
									id="feedback-search"
									placeholder="Search by MLS, Address, Customer name or email"
									onChange={(e) => setKeyword(e.target.value)}
								/>
								<button
									className="btn btn-primary"
									disabled={searching}
									onClick={() => search()}
								>
									{!searching ? (
										"Search"
									) : (
										<div className="px-3">
											<div
												className="spinner-border spinner-border-sm"
												role="status"
											>
												<span className="sr-only">
													Loading...
												</span>
											</div>
										</div>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="row justify-content-center">
					<div className="col-md-12">
						<div className="">
							{comments.data.length > 0 ? (
								<div className="px-3 pt-3">
									<h6 className="text-uppercase mb-2">
										{keyword
											? `Search results for ${keyword}`
											: `Latest ${comments.data.length} comments`}
									</h6>
									<table className="table table-striped">
										<thead className="bg-primary text-white">
											<tr style={{ fontSize: 14 }}>
												<th scope="col">#</th>
												<th>MLS</th>
												<th>Address</th>
												<th>Name</th>
												<th w>Email</th>
												<th>Comment</th>
											</tr>
										</thead>
										<tbody>
											{comments.data.map(
												(comment, index) => (
													<tr
														key={index}
														style={{ fontSize: 14 }}
													>
														<th scope="row">
															{index + 1}
														</th>
														<td>{comment.mls}</td>
														<td>
															{comment.address}
														</td>
														<td>
															{
																comment.customer_name
															}
														</td>
														<td>
															{
																comment.customer_email
															}
														</td>
														<td>
															{comment.comment}
														</td>
													</tr>
												)
											)}
										</tbody>
									</table>
								</div>
							) : (
								<div>
									<p>No data</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</RootElement>
	);
};

export default Feedbacks;
