import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "./../common/ajax";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import NoImage from "./../assets/img/no-image.png";
import NumberFormat from "react-number-format";
import { cloneDeep } from "lodash";
import SearchModel from "../Models/SearchModel";
import PropertyModel from "../Models/PropertyModel";

import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";

import CardButton from "../componets/CardButton";
import FeaturedCard from "../componets/FeaturedCard";

const SearchResultsPage = (props) => {
  const location = useLocation();
  const urlParams = useParams();
  const [searchData, setSearchData] = useState([]);
  const [requestData, setRequestData] = useState({ next_page_url: null });
  const [tempSearchData, setTempSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(false);

  useEffect(() => {
    setLoading(true);

    fetchProps();

    /* return () => {
      fetchProps();
    };*/
  }, []);

  // search
  const [search, setSearch] = useState({
    keyword: "",
    minPrice: 0,
    maxPrice: 0,
    bed: "any",
    // bath: "any",
    propertyType: "RESEDENTIAL",
  });

  const fetchProps = async () => {
    try {
      let status = "Sale";

      if (urlParams.status === "sold") {
        status = "sold";
      }

      let res = await PropertyModel.ownProperties(status);
      setSearchData(res.results.data);
      setRequestData({ next_page_url: res.results.next_page_url });
      console.log("ressss", res);

      setLoading(false);
    } catch (error) {
      console.log("error =>", error);
    }
  };

  const [suggestions, setSuggestions] = useState([]);
  const [searching, setSearching] = useState(false);

  let history = useHistory();

  const fetchMore = async () => {
    try {
      console.log("Fetching more");

      if (requestData.next_page_url) {
        console.log("fetchMore ", requestData);

        let _temp = cloneDeep(searchData);

        const res = await axios.get(requestData.next_page_url, search);

        console.log("res", res);

        _temp.push(...res.data.results.data);

        console.log("temp", _temp);

        setSearchData([..._temp]);
        // setTempSearchData([...searchData, res.data.results.data]);

        // this is for lazy loading
        setRequestData(res.data.results);

        // setRequestData({next_page_url: res.data.results.next_page_url})

        setSearching(false);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };
  const TITLE = process.env.REACT_APP_SITE_NAME+' - Guna Listings'  

  return (
    <>
    <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
      <HeaderComponent />
      <div className="innerbanner">
        <div className="w-100 ib_top"></div>
      </div>
      <div className="container pb-4 my-5">
        {loading && (
          <div class="d-flex justify-content-center mb-5">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}

        <InfiniteScroll
          dataLength={searchData.length} //This is important field to render the next data
          next={fetchMore}
          height={1000}
          hasMore={requestData.next_page_url ? true : false}
          loader={
            <div class="text-center my-3">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <div className="row pt-4 m-0">
            {searchData.length > 0 ? (
              searchData.map((item) => {
                return (
                  <div key={item.property_id} className="col-md-3 mb-5">
                    <div className="card" style={{ width: "100%" }}>
                      <img
                        src={`${item.main_img ? item.main_img : NoImage}`}
                        class="card-img-top"
                        alt="..."                      
                      />
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-6">
                            <h6
                              className="card-title"
                              style={{ fontSize: "14px" }}
                            >
                              {item.property_type}{" "}
                            </h6>
                          </div>
                          <div className="col-lg-6">
                            <div
                              style={{
                                backgroundColor: "#28a745",
                                color: "#fff",
                                padding: "3px",
                                border: "1px solid #28a745",
                              }}
                            >
                              <NumberFormat
                                style={{
                                  backgroundColor: "#28a745",
                                  color: "#fff",
                                  width: "100%",
                                  fontSize: "14px",
                                  textAlign: "center",
                                }}
                                value={item.Orig_dol}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 pt-3">
                            <h6
                              className="card-title"
                              style={{ fontSize: "14px" }}
                            >
                              Address: {item.Addr}
                            </h6>
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{
                            marginTop: "15px",
                            borderTop: "0.5px solid #a09d9d6e",
                            borderBottom: "0.5px solid #a09d9d6e",
                          }}
                        >
                          <div className="col-lg-5">
                            <h6
                              className="card-title"
                              style={{ fontSize: "14px" }}
                            >
                              <b>Type</b>
                            </h6>
                          </div>
                          <div className="col-lg-7">
                            <h6
                              className="card-title"
                              style={{ fontSize: "14px" }}
                            >
                              single family
                            </h6>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{ borderBottom: "0.5px solid #a09d9d6e" }}
                        >
                          <div className="col-lg-5">
                            <h6
                              className="card-title"
                              style={{ fontSize: "14px" }}
                            >
                              size
                            </h6>
                          </div>
                          <div className="col-lg-7">
                            <h6
                              className="card-title"
                              style={{ fontSize: "14px" }}
                            >
                              2520 SqFt
                            </h6>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{ borderBottom: "0.5px solid #a09d9d6e" }}
                        >
                          <div className="col-lg-5">
                            <h6
                              className="card-title"
                              style={{ fontSize: "14px" }}
                            >
                              Rooms
                            </h6>
                          </div>
                          <div className="col-lg-7">
                            <h6
                              className="card-title"
                              style={{ fontSize: "14px" }}
                            >
                              {" "}
                              {item.bed_rooms && item.bed_rooms} beds +{" "}
                              {item.bath_rooms && item.bath_rooms} baths
                            </h6>
                          </div>
                        </div>

                        {/*<Link
                          to={`/estate/details/${item.property_id}/${item.property_type}`}
                        >
                          <div
                            className="row"
                            style={{
                              backgroundColor: "#28a745",
                              color: "#fff",
                              textAlign: "center",
                              padding: "10px",
                              border: "1px solid #28a745",
                            }}
                          >
                            <p
                              style={{
                                color: "#fff",
                                textAlign: "center",
                                fontSize: "16px",
                                paddingLeft: "25%",
                              }}
                            >
                              VIEW DETAILS
                            </p>
                          </div>
                        </Link>*/}
                      </div>
                      <div className="mt-2">
                        <CardButton
                          title="VIEW DETAILS"
                          link={`/estate/details/${item.property_id}/${item.property_type}`}
                          icon="bi bi-eye"
                        />
                      </div>
                    </div>
                  </div>
                ); //
              })
            ) : (
              <h3 className="text-center">Data not found</h3>
            )}
          </div>
        </InfiniteScroll>
      </div>
      <FooterComponent />
    </>
  );
};

const mapStateToProps = (state) => {
  return { searchState: state.searchState };
};

export default connect(mapStateToProps, null)(SearchResultsPage);
