import React from "react";
// import {  useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import IconButton from "@mui/material/IconButton";
import DangerousIcon from "@mui/icons-material/Dangerous";
import Tooltip from "@mui/material/Tooltip";

// import NoImage from "./../assets/img/no-image.png";
// import NoImage from "./../assets/img/featured_area_markham.png";

function FeaturedCard({ item, closeBtn, goTo }) {
  // let history = useHistory();

  return (
    <div class="card shadow" style={{ borderRadius: 0, width: 250 }}>
      {/*<img
          src={`${item.main_img ? item.main_img : NoImage}`}
          class="card-img-top"
          alt="..."
        />*/}
      <div className="text-right">
        <Tooltip title="Close">
          <IconButton
            color="error"
            aria-label="add to list"
            component="span"
            onClick={() => closeBtn()}
          >
            <DangerousIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div class="p-2">
        <div className="card-text">
          <div className="d-flex justify-content-between align-items-center">
            <div className="mr-2">
              <h6 className="card-title" style={{ fontSize: "14px" }}>
                {item.property_type}
              </h6>
            </div>
            <div className="">
              <div
                style={{
                  backgroundColor: "#28a745",
                  color: "#fff",
                  padding: "3px",
                  border: "1px solid #28a745",
                }}
              >
                <NumberFormat
                  style={{
                    backgroundColor: "#28a745",
                    color: "#fff",
                    width: "100%",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                  value={item.Orig_dol}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <p
            className="font-weight-bold text-truncate"
            style={{ fontSize: 14, color: "#646464" }}
          >
            {item.Addr}, {item.Municipality}
          </p>
          <p
            className="font-weight-bold text-truncate"
            style={{ fontSize: 12, color: "#9b9b9b" }}
          >
            {item.bed_rooms} bed |
            {item.bath_rooms} bath {item.Rltr}
          </p>
        </div>
      </div>

      <div className="text-center mb-2">
        <button
          className="btn btn-sm bg_secondary text-white"
          onClick={() => goTo(item)}
        >
          More Details
        </button>
      </div>
    </div>
  );
}

export default React.memo(FeaturedCard);
