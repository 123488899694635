import { Link } from "react-router-dom";
import "./css/CategoryCard.css";

const CategoryCard = ({ category }) => {
  return (
    <Link to={`/blogs/${category.category}`}>
      <div className="news-item">
        <img src={category.image} alt="news" />
        <div className="bg_secondary decor-info descr">
          <h5 className="m-b-md">
            <p className="text-white">{category.category}</p>
          </h5>
        </div>
      </div>
    </Link>
  );
};

export default CategoryCard;
