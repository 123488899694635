import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/reducer';
import { persistStore, persistReducer } from "redux-persist";
import { AsyncStorage } from "AsyncStorage";

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    version: 0,
}
// const store = createStore(rootReducer, applyMiddleware(thunk));

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

export default store;