import React, { useState } from "react";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function ListItemComponent(props) {
	const [dense, setDense] = useState(false);
	const [secondary, setSecondary] = useState(false);

	return (
		<ListItem
			secondaryAction={
				<IconButton
					edge="end"
					aria-label="delete"
					onClick={() =>
						props.deleteEntry({
							type: props.table,
							data: props.data,
						})
					}
				>
					<DeleteIcon />
				</IconButton>
			}
		>
			<ListItemText
				primary={props.label}
				secondary={secondary ? "Secondary text" : null}
			/>
		</ListItem>
	);
}

export default ListItemComponent;
