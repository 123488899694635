import React from "react";
import { Link, } from "react-router-dom";
import NumberFormat from "react-number-format";

// import NoImage from "./../assets/img/no-image.png";
import NoImage from "./../assets/img/featured_area_markham.png";

//import CardButton from "./CardButton";

function FeaturedCard({ item }) {
   //console.log("item", item);
  return (
    <Link to={`/estate/details/${item.property_id}/${item.property_type}`}>
      <div key={item.property_id}>
        <div className="item_wrapper">
          {/* src={`${item.images ? process.env.REACT_APP_IMG_SOURCE + '/' + item.images[0].url : NoImage}`} */}
          {/* src={`${item.main_img ? item.main_img : NoImage}`} */}
          <img
            src={`${item.images.length >0 ? process.env.REACT_APP_IMG_SOURCE + '/' + item.images[0].url : NoImage}`}
            class="card-img-top"
            alt="..."
          />
          <div className="bg-white vm_fs_21 fw_bold px-2 text-dark pt-1 d-flex justify-content-between">
            <span><NumberFormat value={item.Orig_dol} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
            <span>&#10142;</span>
          </div>
          <p className="title bg-white px-2 text-truncate vm_fs_15">
            {/* 863 Cook Cres, Shelburne, ON, L0N 1S1 */}
            {item.Addr}, {item.Municipality}</p>
          <p className="bg-white px-2 vm_fs_15 pb-2">
            Bed {item.bed_rooms} &nbsp; | &nbsp; Bath {item.bath_rooms} &nbsp;  | &nbsp; Parking {item.park_spcs} <br />
            MLS® {item.Ml_num}</p>
        </div>
      </div>
    </Link>
  );
}

export default FeaturedCard;
