import HTTP from "../common/ajax";

const url = "/admin/settings/profile";

class ProfileModel {
  async fetchSiteSettings() {
    try {
      const res = await HTTP.get(`${url}/site-settings`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async fetchSiteSettings2() {
    try {
      const res = await HTTP.get(`public/profile/site-settings`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async saveSiteSettings(payload) {
    try {
      await HTTP.post(`${url}/site-settings`, payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }
}

export default new ProfileModel();
