import React, { useState } from "react";
// import AppBar from "./AppBar";
import BootstrapAppbar from "./BootstrapAppbar";
// import { useHistory } from "react-router";
// import {withRouter} from "react-router";

const RootElement = (props) => {
  const { children } = props;
  // console.log(`props => `, props);

  return (
    <div className="app-background">
      <BootstrapAppbar active={props.active} />
      <main style={{ paddingLeft: 10, paddingRight: 10 }}>{children}</main>
    </div>
  );
};

export default RootElement;
