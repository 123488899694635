import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Home from "./pages/Home/Home";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Buyers from "./pages/Buyers";
import Commerical from "./pages/Search/ComSearch";
import Sellers from "./pages/Sellers";
import Showings from "./pages/Showings";
import ShowingsList from "./pages/ShowingsList";
import GunaListings from "./pages/GunaListings";
import EstateHome from "./pages/Estate/EstateHome";
import EstateDetails from "./pages/Estate/EstateDetails";
import Search from "./pages/Search/Search";
import Brokerage from "./pages/Estate/Brokerage";
// import Spinner from "./componets/Spinner";
// import AdminHome from "./pages/admin/home";
import adminRoutes from "./routes/adminRoutes";
import Login from "./pages/admin/pages/Login";
import BlogDetails from "./pages/BlogDetail";
import Blogs from "./pages/Blogs";
import Calculators from "./pages/Calculators";
import LandTransfer from './pages/LandTransfer';
import MortgageLoan from './pages/MortgageLoan';
import MortgageAffordability from './pages/MortgageAffordability';
import CmhcPremium from './pages/CmhcPremium';


function App(props) {
  /* useEffect(() => {
    console.log(`props.appState => `, props.appState);
  }, []); */

  const privateRoute = (route, index) => {
    const AUTH = localStorage.getItem("auth");

    if (!AUTH) {
      return (
        <Route path="/admin/*">
          <Redirect to={{ pathname: "/admin/login" }} />
        </Route>
      );
    }

    return (
      <Route
        key={index}
        path={`/admin/${route.path}`}
        component={route.component}
        exact
      />
    );
  };

  return (
    <>
      <Router>
        <Switch>
          <Route path="/admin/login" exact>
            <Login />
          </Route>

          {adminRoutes.map((route, index) => privateRoute(route, index))}

          {/* {adminRoutes.map((route, index) => (
            <Route
              path={`/admin/${route.path}`}
              component={route.component}
              exact
              key={index}
            />
          ))}*/}

          <Route path="/" exact>
            <Home />
          </Route>

          <Route path="/search" exact>
            <Search />
          </Route>

          <Route path="/estate/:locationName" exact>
            <EstateHome />
          </Route>

          <Route path="/estate/details/:propertyId/:propertyType" exact>
            <EstateDetails />
          </Route>

          <Route path="/about" exact>
            <About />
          </Route>

          <Route path="/contact-us" exact>
            <ContactUs />
          </Route>

          <Route path="/sellers" exact>
            <Sellers />
          </Route>
          <Route path="/allbrokerage" exact>
            <Brokerage />
          </Route>

          <Route path="/buyers" exact>
            <Buyers />
          </Route>
          <Route path="/commerical" exact>
            <Commerical />
          </Route>

          <Route path="/our-listings/:status" exact>
            <GunaListings />
          </Route>

          <Route path="/showings" exact>
            <Showings />
          </Route>

          <Route path="/showings/:id" exact>
            <ShowingsList />
          </Route>

          <Route path="/blogs/:category" exact>
            <Blogs />
          </Route>

          <Route path="/blogs" exact>
            <Blogs />
          </Route>
          <Route path="/calculators" exact>
            <Calculators />
          </Route>
          <Route path="/landtransfer" exact>
            <LandTransfer />
          </Route>
          <Route path="/mortgageloan" exact>
            <MortgageLoan />
          </Route>
          <Route path="/mortgageaffordability" exact>
            <MortgageAffordability />
          </Route>
          <Route path="/cmhcpremium" exact>
            <CmhcPremium />
          </Route>

          <Route path="/blogs/:category/:postId" exact>
            <BlogDetails />
          </Route>

          <Route path="*">
            <Redirect to={{ pathname: "/" }} />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

const mapStateToProps = (state) => {
  // console.log("App.js => ", state);
  // console.log(state);
  return { appState: state.appState };
};

export default connect(mapStateToProps, null)(App);
