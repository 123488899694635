import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";

import RootElement from "../components/RootElement";

import EmailsTable from "../components/forms/email/EmailsTable";
import GroupsTable from "../components/forms/email/GroupsTable";

import NewEntryForm from "../components/forms/email/NewEntryForm";
import NewGroupForm from "../components/forms/email/NewGroupForm";
import MailForm from "../components/forms/email/MailForm";

// import EmailTemplateCard from "../components/forms/email/EmailTemplateCard";

import EmailListModel from "../../../Models/EmailListModel";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "1px solid #ccc",
	boxShadow: 24,
	p: 4,
};

const style2 = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 800,
	bgcolor: "background.paper",
	border: "1px solid #ccc",
	boxShadow: 24,
	p: 4,
};

const confirm = (title, text) => {
	return new Promise((resolve) => {
		Swal.fire({
			title: title,
			text: text,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
		}).then((result) => {
			if (result.isConfirmed) {
				resolve();
			}
		});
	});
};

export default function EmailList(props) {
	const History = useHistory();
	const [open, setOpen] = useState({
		modal: false,
		formType: true, // new entry else new group
	});

	const handleOpen = (type) => setOpen({ modal: true, type });
	const handleClose = () => setOpen({ modal: false, formType: true });

	const [loading, setLoading] = useState(true);
	const [loaders, setLoaders] = useState({
		sync: false,
	});

	const [listType, setListType] = useState(false);

	const [emails, setEmails] = useState([]);
	const [groups, setGroups] = useState([]);

	const fetchEmails = async () => {
		try {
			const res = await EmailListModel.emails();
			setEmails(res.data);
			setLoading(false);
		} catch (error) {
			console.log("error => ", error);
			setLoading(false);
		}
	};

	const fetchGroups = async () => {
		try {
			const res = await EmailListModel.getGroups();
			setGroups(res.data);
			setLoading(false);
		} catch (error) {
			console.log("error => ", error);
			setLoading(false);
		}
	};

	const sync = async () => {
		try {
			await confirm(
				"Sync details",
				"Any new data from Enquries will be sync with the emails list"
			);
			setLoaders({ ...loaders, sync: true });
			await EmailListModel.sync();
			await fetchEmails();
			setLoaders({ ...loaders, sync: false });
		} catch (error) {
			console.log("error ", error);
			setLoaders({ ...loaders, sync: false });
		}
	};

	const newEntry = async (payload) => {
		handleClose();

		try {
			setLoading(true);
			await EmailListModel.create(payload);
			await fetchEmails();
			await fetchGroups();
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log("error ", error);
		}
	};

	const deleteEntry = async (id) => {
		try {
			await confirm("Confirm", "Do you want to delete this entry?");
			setLoading(true);
			await EmailListModel.deleteEntry(id);
			await fetchEmails();
			await fetchGroups();
		} catch (error) {
			setLoading(false);
			console.log("error ", error);
		}
	};

	const newGroup = async (payload) => {
		handleClose();

		try {
			setLoading(true);
			await EmailListModel.newGroup(payload);
			await fetchGroups();
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log("error ", error);
		}
	};

	const deleteGroup = async (id) => {
		try {
			await confirm("Confirm", "Do you want to delete this entry?");
			setLoading(true);
			await EmailListModel.deleteGroup(id);
			await fetchEmails();
			await fetchGroups();
		} catch (error) {
			setLoading(false);
			console.log("error ", error);
		}
	};

	useEffect(() => {
		fetchEmails();
		fetchGroups();

		return () => {
			fetchEmails();
			fetchGroups();
		};
	}, []);

	const [mailModal, setMailModal] = useState(false);

	const openMailModal = () => setMailModal(true);
	const closeMailModal = () => setMailModal(false);

	const emailTemplates = ["reply", "thanks", "test"];
	const [template, setTemplate] = useState("Choose template");
	const [bulkEmail, setBulkEmail] = useState({ status: false, data: [] });

	const sendEmail = async (payload) => {
		console.log("payload ", payload);
		closeMailModal();
		setLoading(true);

		if (bulkEmail.status) {
			console.log("bulkEmail");
			// send the bulk email

			// Set back to default
			// setBulkEmail({ status: false, data: [] });
			return;
		}

		await EmailListModel.sendEmail({ ...payload, template: "test" });

		Swal.fire("Success", "Emails sent successfully", "success");
		setLoading(false);
	};

	const handleBulkEmail = async (payload) => {
		console.log("payload ", payload);
		const group = groups.find((group) => group.id === payload);

		History.push(`/admin/email-list/bulk/${group.group_type}`);

		/* return;

		const filtered = emails.filter(
			(email) => email.group_type === group.group_type
		);

		setBulkEmail({ status: true, data: filtered });
		openMailModal(); */
	};

	const removeFromBulkEmailList = async (id) => {
		const filtered = bulkEmail.data.filter((email) => email.id !== id);
		setBulkEmail({ status: true, data: filtered });
	};

	const [groupModal, setGroupModal] = useState(false);
	const [groupModalValue, setGroupModalValue] = useState({});

	const openChangeModal = () => setGroupModal(true);
	const closeChangeModal = () => setGroupModal(false);

	const changeGroupModal = (id) => {
		console.log("obj", id);
		const found = emails.find((email) => email.id === id);
		setGroupModalValue(found);
		openChangeModal();
	};

	const changeGroup = async (id, group_type) => {
		try {
			closeChangeModal();
			await confirm("Confirm", "Do you want to change group?");
			setLoading(true);
			await EmailListModel.changeGroup({
				id: groupModalValue.id,
				group_type,
			});
			await fetchEmails();
			await fetchGroups();
		} catch (error) {
			setLoading(false);
			console.log("error ", error);
		}
	};

	return (
		<RootElement active="emails">
			{loading && (
				<Box sx={{ width: "100%" }}>
					<LinearProgress />
				</Box>
			)}

			<div
				className="py-5"
				style={{ backgroundColor: "#fbfbfb", height: 120 + "vh" }}
			>
				<div className="">
					<div className="email-list">
						<div className="d-md-flex justify-content-between">
							<div>
								<div
									className="btn-group btn-group-toggle"
									data-toggle="buttons"
								>
									<label
										className="btn btn-secondary active"
										onClick={() => setListType(false)}
									>
										<input
											type="radio"
											name="options"
											id="option1"
											checked
										/>{" "}
										Email List
									</label>
									<label
										className="btn btn-secondary"
										onClick={() => setListType(true)}
									>
										<input
											type="radio"
											name="options"
											id="option3"
										/>{" "}
										Group List
									</label>
								</div>
							</div>
							<div className="mb-3">
								<Stack spacing={2} direction="row">
									<button
										className="btn btn-sm btn-success"
										onClick={() => History.push(
											`/admin/email-list/send`
										)}
										disabled={loading}
									>
										<i class="bi bi-envelope mr-2"></i> Compose
									</button>
									<button
										className="btn btn-sm btn-success"
										onClick={() => handleOpen(true)}
										disabled={loading}
									>
										New Email
									</button>
									<button
										className="btn btn-sm btn-success"
										onClick={() =>
											History.push(
												`/admin/email-list/import`
											)
										}
										disabled={loading}
									>
										CSV Import
									</button>
									<button
										className="btn btn-sm btn-success"
										onClick={() => handleOpen(false)}
										disabled={loading}
									>
										New Group
									</button>
									<button
										className="btn btn-sm btn-success"
										onClick={() => sync()}
										disabled={loading}
									>
										{loaders.sync ? (
											<div className="px-3">
												<div
													className="spinner-border spinner-border-sm"
													role="status"
												>
													<span className="sr-only">
														Loading...
													</span>
												</div>
											</div>
										) : (
											"Sync"
										)}
									</button>
								</Stack>
							</div>
						</div>

						{!listType ? (
							<div className="email-table">
								<EmailsTable
									data={emails}
									groups={groups}
									changeGroup={(id) => changeGroupModal(id)}
									sendmailmodal={(id) => openMailModal(id)}
									deleteEntry={(id) => deleteEntry(id)}
								/>
							</div>
						) : (
							<div>
								<GroupsTable
									data={groups}
									deleteEntry={(id) => deleteGroup(id)}
									sendmailmodal={(data) =>
										handleBulkEmail(data)
									}
								/>
							</div>
						)}
					</div>
				</div>
			</div>

			<Modal
				open={open.modal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div>
						{open.type ? (
							<NewEntryForm
								groups={groups}
								oncreate={(data) => newEntry(data)}
							/>
						) : (
							<NewGroupForm oncreate={(data) => newGroup(data)} />
						)}
					</div>
				</Box>
			</Modal>

			<Modal
				open={groupModal}
				onClose={closeChangeModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style2}>
					<div>
						<h5>Change the group</h5>
						<table class="table">
							<thead>
								<tr>
									<th scope="col">Full Name</th>
									<th scope="col">Email</th>
									<th scope="col">Phone Number</th>
									<th scope="col">Group</th>
									<th scope="col">Actions</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{groupModalValue.full_name}</td>
									<td>{groupModalValue.email}</td>
									<td>{groupModalValue.phone_number}</td>
									<td>{groupModalValue.group_type}</td>
									<td>
										<div class="form-group">
											<div class="dropdown">
												<button
													class="btn btn-secondary dropdown-toggle"
													type="button"
													id="dropdownMenuButton"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
												>
													Change Group
												</button>
												<div
													class="dropdown-menu"
													aria-labelledby="dropdownMenuButton"
												>
													{groups &&
														groups.map(
															(group, index) => (
																<a
																	key={index}
																	className="dropdown-item"
																	href="#"
																	onClick={(
																		e
																	) =>
																		changeGroup(
																			group.id,
																			e
																				.target
																				.text
																		)
																	}
																>
																	{
																		group.group_type
																	}
																</a>
															)
														)}
												</div>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Box>
			</Modal>

			<Modal
				open={mailModal}
				onClose={closeMailModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style2}>
					<div>
						<h5 className="text-center">Send Mail</h5>

						<div className="d-flex align-item-center mb-3">
							<div class="dropdown mr-3">
								<button
									class="btn btn-secondary dropdown-toggle"
									type="button"
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									{template}
								</button>
								<div
									class="dropdown-menu"
									aria-labelledby="dropdownMenuButton"
								>
									{emailTemplates.map((temp, index) => (
										<a
											key={index}
											class="dropdown-item"
											href="#"
											onClick={(e) =>
												setTemplate(e.target.text)
											}
										>
											{temp}
										</a>
									))}
								</div>
							</div>

							{bulkEmail.status && (
								<div>
									<button
										className="btn btn-primary"
										data-toggle="collapse"
										data-target="#collapseExample"
									>
										View Email List
									</button>
								</div>
							)}
						</div>

						<div className="collapse mb-3" id="collapseExample">
							<div className="card card-body">
								<table class="table table-sm table-borderless table-hover">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Name</th>
											<th scope="col">Email</th>
											<th scope="col">Action</th>
										</tr>
									</thead>
									<tbody>
										{bulkEmail.data &&
											bulkEmail.data.map(
												(data, index) => (
													<tr key={index}>
														<th scope="row">
															{index + 1}
														</th>
														<td>
															{data.full_name}
														</td>
														<td>{data.email}</td>
														<td>
															<button
																className="btn btn-sm btn-danger"
																onClick={() =>
																	removeFromBulkEmailList(
																		data.id
																	)
																}
															>
																<i class="bi bi-x"></i>
															</button>
														</td>
													</tr>
												)
											)}
									</tbody>
								</table>
							</div>
						</div>

						<MailForm sendEmail={(data) => sendEmail(data)} />
					</div>
				</Box>
			</Modal>
		</RootElement>
	);
}
