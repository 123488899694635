import React from "react";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";

import EmailActionButton from "./EmailActionButton";

const rows = [
	{ id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
	{ id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
	{ id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
	{ id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
	{ id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
	{ id: 6, lastName: "Melisandre", firstName: null, age: 150 },
	{ id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
	{ id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
	{ id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

export default function EmailsTable({
	data,
	deleteEntry,
	sendmailmodal,
	groups,
	changeGroup,
}) {
	const columns = [
		{ field: "id", headerName: "ID", width: 70 },
		{ field: "full_name", headerName: "Full Name", width: 200 },
		{ field: "email", headerName: "Email", width: 200 },
		{ field: "phone_number", headerName: "Phone number", width: 200 },
		{ field: "group_type", headerName: "Group Type", width: 200 },
		{
			field: "actions",
			headerName: "Actions",
			width: 350,
			renderCell: (params) => (
				<Stack spacing={2} direction="row">
					<EmailActionButton
						data={params.row}
						icon="envelope"
						color="success"
						toolTip="Send email"
						action={(data) => sendmailmodal(data)}
					/>
					<EmailActionButton
						data={params.row}
						icon="trash"
						color="danger"
						toolTip="Delete Entry"
						action={(id) => deleteEntry(id)}
					/>
					<Button variant="contained" onClick={() => changeGroup(params.row.id)}>
						Change Group
					</Button>
				</Stack>
			),
		},
	];

	return (
		<div style={{ height: 800, width: "100%" }}>
			<DataGrid
				rows={data}
				columns={columns}
				pageSize={50}
				rowsPerPageOptions={[50]}
			/>
		</div>
	);
}
