import React from "react";

export default function TextInputArea({ label, handleTextInput }) {
	return (
		<div class="input-group">
			<textarea
				type="text"
				class="form-control"
				aria-label="Sizing example input"
				aria-describedby="inputGroup-sizing-sm"
				rows="10"
				onChange={(e) => handleTextInput(e.target.value)}
			/>
		</div>
	);
}
