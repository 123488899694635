import React, { useRef } from "react";
import SunEditor from "suneditor-react";
import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const MyComponent = (props) => {
    /**
     * @type {React.MutableRefObject<SunEditor>} get type definitions for editor
     */
    const editor = useRef();

    // The sunEditor parameter will be set to the core suneditor instance when this function is called
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };

    const handleChange = (payload) => {
        props.updatePost(payload);
    };

    return (
        <div>
            <SunEditor
                height="280px"
                getSunEditorInstance={getSunEditorInstance}
                defaultValue={props.defaultValue}
                setAllPlugins={false}
                setOptions={{
                    plugins: plugins,
                    buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize", "formatBlock"],
                        ["paragraphStyle", "blockquote"],
                        [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                        ],
                        ["fontColor", "hiliteColor", "textStyle"],
                        ["removeFormat"],
                        "/", // Line break
                        ["outdent", "indent"],
                        ["align", "horizontalRule", "list", "lineHeight"],
                        [
                            "table",
                            "link",
                            "image",
                            "video",
                            "audio" /** ,'math' */,
                        ], // You must add the 'katex' library at options to use the 'math' plugin.
                        /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                        ["fullScreen", "showBlocks", "codeView"],
                        ["preview", "print"],
                        ["save"],
                        /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                    ],
                }}
                onChange={handleChange}
            />
        </div>
    );
};
export default MyComponent;
