import HTTP from "../../../common/ajax";

class SettingModel {
  async fetchMunicipality() {
    try {
      const res = await HTTP.get("/admin/settings/municipality");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async fetchPropertyTypes() {
    try {
      const res = await HTTP.get("/public/settings/property");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async fetchListingTypes() {
    try {
      const res = await HTTP.get("/admin/settings/listing");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async createMunicipality(payload) {
    try {
      const res = await HTTP.post("/admin/settings/municipality", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async createPropertyTypes(payload) {
    try {
      const res = await HTTP.post("/admin/settings/property", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async createListingTypes(payload) {
    try {
      const res = await HTTP.post("/admin/settings/listing", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async deleteEntry(payload) {
    try {
      const res = await HTTP.post("/admin/settings/delete", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }
}

export default new SettingModel();
