import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import Swal from "sweetalert2";

import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import LoadingButton from "../componets/LoadingButton";

import ContactModel from "../Models/ContactModel";


import InnerPageHeader from "../assets/img/inner_page_header.png";
import c1 from "../assets/img/c-1.png";
import c2 from "../assets/img/c-2.png";
import c3 from "../assets/img/c-3.png";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  async function handleContactForm(payload) {
    try {
      await ContactModel.create({
        ...payload,
      });

      Swal.fire(`Done`, `Message successfully`, "success");
      setLoading(false);

      formik.setFieldValue("firstname", "");
      formik.setFieldValue("lastname", "");
      formik.setFieldValue("email_address", "");
      formik.setFieldValue("phone_number", "");
      formik.setFieldValue("enquiry", "");
    } catch (error) {
      console.log("Error", error);
      Swal.fire(`Error`, `Something went wrong.`, "error");
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
        firstname: get("", "firstname", ""),
        lastname: get("", "lastname", ""),
      email_address: get("", "email_address", ""),
      enquiry: get("", "enquiry", ""),
      phone_number: get("", "phone_number", ""),
    },
    validationSchema: Yup.object({
        firstname: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required("Required"),
        lastname: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required("Required"),
      phone_number: Yup.string().min(10, 'Too Short!').max(15, 'Too Long!'),
      email_address: Yup.string().email('Invalid email').required('Required'),
      enquiry: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handleContactForm(values);
    },
  });
  const TITLE = process.env.REACT_APP_SITE_NAME+' - Contact Us'  

  return (
    <>
    <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
      <HeaderComponent />
      <div className="innerbanner">
        <div className="w-100 ib_top">
          <img src={InnerPageHeader}  style={{ width: 100 + "%" }}/>
        </div>
      </div>
      <div className="container pb-4 my-1">
        <div className="mb-5">
         
        </div>

        <div className="d-md-flex justify-content-center p-5">
          <div
          className="col-md-4 pl-md-5 pt-5 pt-md-0"
            style={{
              background: "#202020 none repeat scroll 0 0",
              boxShadow: "1px 0 21px rgb(0 0 0 / 20%)",
              padding: 20,
            }}
          >
            <div className="d-flex align-items-center my-5">
              <img src={c1}/>
              <p className="text-light pl-3">
              {process.env.REACT_APP_ADDRESS1_1}<br/>
              {process.env.REACT_APP_ADDRESS1_2}
              </p>
            </div>
            <div className="d-flex align-items-center my-5">
            <img src={c2}/>
              <p className="text-light pl-3">Telephone : {process.env.REACT_APP_PHONE_NUMBER}</p>
            </div>
            <div className="d-flex align-items-center my-5">
            <img src={c3}/>
              <div className="text-light pl-3">
                <p className="text-light">Email : {process.env.REACT_APP_EMAIL}</p>
                <p className="text-light">Web : {process.env.REACT_APP_WEBSITE_NAME}</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 pl-md-5 pt-5 pt-md-0">
            <form onSubmit={formik.handleSubmit}>
              <div className="">
                <div className="mb-1">
                  <div class="form-group">
                    <label for="name">First Name</label>
                    <input
                        type="text"
                        name="firstname"
                        id="firstname"
                        className="form-control rounded-0 col me-md-2 mb-3 mb-md-0"
                        placeholder="First Name"
                        value={formik.values.firstname}
                        onChange={(e) =>
                        formik.setFieldValue("firstname", e.target.value)
                        }
                        onBlur={() => formik.setFieldTouched("firstname")}
                    />
                    {Boolean(formik.errors.firstname) &&
                    formik.touched.firstname ? (
                        <small
                        id="firstnameError"
                        className={
                            "form-text " + Boolean(formik.errors.firstname)
                            ? "text-danger"
                            : "text-muted"
                        }
                        >
                        {formik.errors.firstname}
                        </small>
                    ) : (
                        ""
                    )}
                  </div>
                </div>
                <div className="mb-1">
                  <div class="form-group">
                    <label for="name">Last Name</label>
                    <input
                        type="text"
                        name="lastname"
                        id="lastname"
                        className="form-control rounded-0 col me-md-2 mb-3 mb-md-0"
                        placeholder="Last Name"
                        value={formik.values.lastname}
                        onChange={(e) =>
                        formik.setFieldValue("lastname", e.target.value)
                        }
                        onBlur={() => formik.setFieldTouched("lastname")}
                    />
                    {Boolean(formik.errors.lastname) &&
                    formik.touched.lastname ? (
                        <small
                        id="lastnameError"
                        className={
                            "form-text " + Boolean(formik.errors.lastname)
                            ? "text-danger"
                            : "text-muted"
                        }
                        >
                        {formik.errors.lastname}
                        </small>
                    ) : (
                        ""
                    )}
                  </div>
                </div>

                <div className="mb-1">
                  <div class="form-group">
                    <label for="email">Email address</label>
                    <input
                        type="text"
                        name="email_address"
                        id="email_address"
                        className="form-control rounded-0 col me-md-2 mb-3 mb-md-0"
                        placeholder="Email"
                        value={formik.values.email_address}
                        onChange={(e) =>
                        formik.setFieldValue("email_address", e.target.value)
                        }
                        onBlur={() => formik.setFieldTouched("email_address")}
                    />
                    {Boolean(formik.errors.email_address) &&
                    formik.touched.email_address ? (
                        <small
                        id="email_addressError"
                        className={
                            "form-text " + Boolean(formik.errors.email_address)
                            ? "text-danger"
                            : "text-muted"
                        }
                        >
                        {formik.errors.email_address}
                        </small>
                    ) : (
                        ""
                    )}
                  </div>
                </div>

                <div className="mb-1">
                  <div class="form-group">
                    <label for="name">Phone</label>
                    <input
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        className="form-control rounded-0 col me-md-2 mb-3 mb-md-0"
                        placeholder="Phone Number"
                        value={formik.values.phone_number}
                        onChange={(e) =>
                        formik.setFieldValue("phone_number", e.target.value)
                        }
                        onBlur={() => formik.setFieldTouched("phone_number")}
                    />
                    {Boolean(formik.errors.phone_number) &&
                    formik.touched.phone_number ? (
                        <small
                        id="phone_numberError"
                        className={
                            "form-text " + Boolean(formik.errors.phone_number)
                            ? "text-danger"
                            : "text-muted"
                        }
                        >
                        {formik.errors.phone_number}
                        </small>
                    ) : (
                        ""
                    )}
                  </div>
                </div>

                <div className="mb-1">
                  <div class="form-group">
                    <label for="msg">Message</label>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="5"
                      className="form-control rounded-0"
                      placeholder="Message"
                      value={formik.values.enquiry}
                      onChange={(e) =>
                      formik.setFieldValue("enquiry", e.target.value)
                      }
                      onBlur={() => formik.setFieldTouched("enquiry")}
                  ></textarea>
                  {Boolean(formik.errors.enquiry) && formik.touched.enquiry ? (
                      <small
                      id="enquiryError"
                      className={
                          "form-text " + Boolean(formik.errors.enquiry)
                          ? "text-danger"
                          : "text-muted"
                      }
                      >
                      {formik.errors.enquiry}
                      </small>
                  ) : (
                      ""
                  )}   
                  </div>
                </div>

                <LoadingButton
                  title="Send Now"
                  style="danger"
                  loading={loading}
                  handleClick={() => formik.submitForm()}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default ContactUs;
