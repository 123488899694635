import * as React from "react";
// import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectInput(props) {
	// const [age, setAge] = React.useState("");

	/* const handleChange = (event) => {
		setAge(event.target.value);
	};
 */
	return (
		<FormControl fullWidth>
			<InputLabel id="property-type-simple-select-label">
				{props.label}
			</InputLabel>
			<Select
				labelId="property-type-simple-select-label"
				id="property-type-simple-select"
				value={props.value}
				defaultValue={props.value}
				label="Property Type"
				onChange={(event) => props.getValue(event.target.value)}
			>
				{props.options &&
					props.options.map((option, index) => {
						return (
							<MenuItem value={option} key={index}>
								{option}
							</MenuItem>
						);
					})}
			</Select>
		</FormControl>
	);
}
