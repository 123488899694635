import React, { useState, useEffect } from "react";
import axios from "./../../common/ajax";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import { Helmet } from "react-helmet";

import SearchModel from "../../Models/SearchModel";
import SettingModel from "../admin/models/SettingModel";
import { savePropertyTypes,saveSearchData } from "../../store/actions/Search/SearchActions";
import { setShowings } from "../../store/actions/App/AppActions";

import HeaderComponent from "../../componets/Applications/Header/Header";
import FooterComponent from "../../componets/Applications/Footer/Footer";

import Card from "../../componets/Card";

const SearchResultsPage = (props) => {
  const location = useLocation();
  // const { locationName } = useParams();
  const [searchData, setSearchData] = useState([]);
  const [requestData, setRequestData] = useState({ next_page_url: null });
  // const [tempSearchData, setTempSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(false);
 
  useEffect(() => {
    //console.log("obj", props.searchState);
    setLoading(true);

    if (props.searchState.results.length > 0) {
        //console.log(`hmm => `,);
        if (props.searchState.results.length > 1) {

            //console.log(`more than 1 => `,);
            setSearchData(props.searchState.results);
            setRequestData({
            next_page_url: props.searchState.results.next_page_url,
            });
            setLoading(false);

        }
        return;
    }

    if(props.searchState.searchData.propertyType != 'RESEDENTIAL'){    
        props.searchState.searchData={
            propertyType: "RESEDENTIAL",
            buildingType: "any",
            transactionType: "any",
            keyword: "",
            minPrice: 0,
            maxPrice: 0,
            bed: "any",
            bath: "any",    
            parking: "any",
        };
    }

    //console.log(`coming? => `,);
    fetchProps();
  }, []);

  // search
    const [search, setSearch] = useState(props.searchState.searchData);   
    const fetchProps = async () => {
        if(props.searchState.searchData.propertyType == 'RESEDENTIAL'){
            try {
                let _search = props.searchState.searchData;
                if (search.keyword === "") {
                    _search = {
                    ..._search,
                    keyword: "Toronto",
                    };
                }

                let res = await SearchModel.search(_search);
                //console.log(res.results);
                if (res.results[0]) {
                  if (res.results.length !== undefined && res.results.length > 0) {
                      setSearchData(res.result);
                      setSearching(false);
                      setLoading(false);

                      return;
                  } else {
                      setSearchData([]);
                  }
                }else{
                  setSearchData([]);
                }
                //if (res.results[0]) {
                  setSearchData(res.results.data);
                  setRequestData({ next_page_url: res.results.next_page_url });
                //}
                setLoading(false);
                setSearching(false);
            } catch (error) {
                console.log("error =>", error);
            }
        }
    };

  const [suggestions, setSuggestions] = useState([]);
  const [searching, setSearching] = useState(false);

  // find suggesstion only if user type any keyword
  useEffect(() => {
    if(props.searchState.searchData.propertyType == 'RESEDENTIAL'){
        if(search.keyword && search.keyword != ''){
            SearchModel.findSimilarity(search.keyword).then((res) => {
            // matching rating is greater than 0
            if (res.bestMatch.rating > 0) {
                // copy array without duplication
                let data = cloneDeep(suggestions);
                const allInarray = data.filter(
                (values) => values === res.bestMatch.target
                );
                // Make sure the value doesn't exist already
                if (allInarray.length === 0) {
                data.push(res.bestMatch.target);
                setSuggestions(data);
                }
            }
            });
        }
    }
  }, [search.keyword]);

  // let history = useHistory();
  async function handleFormSubmit(event) {
    event.preventDefault();

    // if (search.keyword === "") {
    //   Swal.fire("Error", "Search keyword cannot be empty", "error");
    //   return;
    // }

    setSearching(true);
    let res = await SearchModel.search(search);
    //console.log(res);
    search.propertyType="RESEDENTIAL";
    props.saveSearchData(search);

    // MLS search only
    if (res !== undefined && res.length > 0) {
      setSearchData(res);
      setSearching(false);

      return;
    } else {
      setSearchData([]);
    }

    // other searches
    if (res.results[0]) {
      if (res.results.length > 0) {
        setSearchData(res.results);
      } else {
        setSearchData([]);
      }
    }else{
      setSearchData([]);
    }

    setSearching(false);
  }
 
  const [suggestionClicked, setSuggestionClicked] = useState(false);

  const handleTyping = (value) => {      
    setSearch({ ...search, keyword: value });    
    if (suggestionClicked) {
      setSuggestionClicked(false);
    }
  };

  const handleSuggestionClick = (keyword) => {       
    setSearch({ ...search, keyword: keyword });    
    setSuggestions([]);
    setSuggestionClicked(true);
    setCount(true);
  };

  const fetchMore = async () => {
    try {
      if (requestData.next_page_url) {
        let _temp = cloneDeep(searchData);

        const res = await axios.post(requestData.next_page_url, search);

        //console.log(`res => `, res);

        _temp.push(...res.data.results.data);

        setSearchData([..._temp]);
        // setTempSearchData([...searchData, res.data.results.data]);

        // this is for lazy loading
        setRequestData(res.data.results);

        // setRequestData({next_page_url: res.data.results.next_page_url})

        setSearching(false);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleOnBlur = () => {
    setTimeout(function () {
      setSuggestionClicked(true);
      setCount(count + 1);
    }, 200);
  };

  function addToShowings(payload) {
    let storage = JSON.parse(localStorage.getItem("showings"));
    const found = storage.find((pr) => pr.property_id === payload.property_id);

    if (found !== undefined || found) {
      Swal.fire(
        "Already Exist",
        "Property already in showings list.",
        "warning"
      );
      return;
    }
    // return;
    props.setShowings(payload);

    Swal.fire("Success", "Property added to showings list.", "success");
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  var searchText='';
  if(search.propertyType=="RESEDENTIAL"){
  if(search.keyword && search.keyword != ''){
    searchText+=search.keyword;
  } if(search.buildingType && search.buildingType != '' && search.buildingType != 'any' && search.buildingType != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.buildingType;
  } if(search.transactionType && search.transactionType != '' && search.transactionType != 'any' && search.transactionType != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.transactionType;
  }if(search.minPrice && search.minPrice != '' && search.minPrice != 'any' && search.minPrice != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+='Greater than : $'+search.minPrice;
  }if(search.maxPrice && search.maxPrice != '' && search.maxPrice != 'any' && search.maxPrice != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+='Less than : $'+search.maxPrice;
  }if(search.bed && search.bed != '' && search.bed != 'any' && search.bed != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.bed+' Bed(s)';
  }if(search.bath && search.bath != '' && search.bath != 'any' && search.bath != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.bath+' Bath(s)';
  }if(search.parking && search.parking != '' && search.parking != 'any' && search.parking != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.parking+' Parking(s)';
  }
  if(searchText != ''){searchText='for '+searchText;}
  }
  const TITLE = process.env.REACT_APP_SITE_NAME+' - Residential Properties'

  return (
    <>
     <Helmet>
          <title>{ TITLE }</title>
      </Helmet>
      <HeaderComponent />
      <div className="innerbanner">
        <div className="w-100 ib_top"></div>
      </div>
      <div className="container pb-4 my-5">
        <section className="bg_primary pb-md-0 px-3 pt-4 serchengine custom-padding">
          <div className="container pt-4 py-md-0">
            <form onSubmit={handleFormSubmit}>
              <div className="d-flex flex-md-row flex-column justify-content-between">
                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Property</p>
                  <select
                      name="buildingType"
                      id="building-type"
                      value={search.buildingType}
                      className="form-control bg_none border_radius_none text-white bg_primary"
                      onChange={(e) =>
                          setSearch({ ...search, buildingType: e.target.value })
                      }
                      >
                      <option value="any">Any</option>
                        <option value="condo">Condo</option>
                        <option value="Att/Row/Twnhouse">Att/Row/Twnhouse</option>
                        <option value="Cottage">Cottage</option>
                        <option value="Detached">Detached</option>
                        <option value="Duplex">Duplex</option>
                        <option value="Farm">Farm</option>
                        <option value="Fourplex">Fourplex</option>
                        <option value="Link">Link</option>
                        <option value="Lower Level">Lower Level</option>
                        <option value="Mobile/Trailer">Mobile/Trailer</option>
                        <option value="Multiplex">Multiplex</option>
                        <option value="Other">Other</option>
                        <option value="Rural Resid">Rural Resid</option>
                        <option value="Semi-Detached">Semi-Detached</option>
                        <option value="Store W/Apt/Offc">Store W/Apt/Offc</option>
                        <option value="Triplex">Triplex</option>
                        <option value="Upper Level">Upper Level</option>
                        <option value="Vacant Land">Vacant Land</option>			
                  </select>
                </div>

                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Transaction Type</p>
                  <select
                      name="transactionType"
                      id="transaction-type"
                      value={search.transactionType}
                      className="form-control bg_none border_radius_none text-white bg_primary"
                      onChange={(e) =>
                          setSearch({ ...search, transactionType: e.target.value })
                      }
                      >
                      <option value="any">Any</option>
                      <option value="Sale">Sale</option>
                      <option value="Lease">Rent/Lease</option>
                      <option value="Sub-Lease">Sub Lease</option>
                  </select>
                </div>

                <div className="pr-3 pl-3 pl-md-0">
                  <p className="text-white pb-2">
                  Search by MLS #, City, Neighborhood, Zip or Addres
                  </p>
                  <input
                      type="text"
                      name="keyword"
                      className="form-control bg_none border_radius_none text-white bg_primary"
                      placeholder="Toronto"
                      onChange={(e) => handleTyping(e.target.value)}
                      value={search.keyword}
                      onBlur={() => handleOnBlur()}
                  />
                  <ul className="list-group">
                      {!suggestionClicked &&
                      suggestions.map((suggestion, index) => (
                          <li
                          key={index}
                          className="list-group-item"
                          onClick={() => handleSuggestionClick(suggestion)}
                          >
                          {suggestion}
                          </li>
                      ))}
                  </ul>
                </div>

                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Minimum Price</p>
                  <input
                      type="number"
                      name="minPrice"
                      className="form-control bg_none border_radius_none text-white bg_primary"
                      placeholder=""
                      min="0"
                      value={search.minPrice}
                      step="50"
                      onChange={(e) =>
                          setSearch({ ...search, minPrice: e.target.value })
                      }
                      />
                </div>

                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Maximum Price</p>
                  <input
                      type="number"
                      name="maxPrice"
                      className="form-control bg_none border_radius_none text-white bg_primary"
                      placeholder=""
                      value={search.maxPrice}
                      min="0"
                      step="50"
                      onChange={(e) =>
                          setSearch({ ...search, maxPrice: e.target.value })
                      }
                      />
                </div>
              </div>
              <div className="d-flex flex-md-row flex-column justify-content-between mt-4">
                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Bed Rooms</p>
                  <select
                      name="bed"
                      id="bed"
                      value={search.bed}
                      className="form-control bg_none border_radius_none text-white bg_primary"
                      onChange={(e) =>
                        setSearch({ ...search, bed: e.target.value })
                      }
                    >
                      <option value="any">Any</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                </div>
                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Bath Rooms</p>
                  <select
                      name="bath"
                      id="bath"
                      value={search.bath}
                      className="form-control bg_none border_radius_none text-white bg_primary"
                      onChange={(e) =>
                        setSearch({ ...search, bath: e.target.value })
                      }
                    >
                      <option value="any">Any</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                </div>
                <div className="col pt-3 pt-md-0">
                  <p className="text-white pb-2">Parking</p>
                  <select
                      name="parking"
                      id="parking"
                      value={search.parking}
                      className="form-control bg_none border_radius_none text-white bg_primary"
                      onChange={(e) =>
                          setSearch({ ...search, parking: e.target.value })
                      }
                      >
                      <option value="any">Any</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      </select>
                </div>
               
                <div className="col pb-4">
                  <p className="text-white pb-2">&nbsp;</p>
                  <input
                    type="submit"
                    name="keyword"
                    value="Search"
                    className="form-control text-white border_none bg_secondary border_radius_none"
                  />
                </div>
              </div>
            </form>

            {searching && (
              <div className="d-flex justify-content-center align-items-center text-light pb-3">
                <h5 className="text-light mr-2">Searching please wait</h5>
                <div className="spinner-grow spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </section>

        <h4 className="my-5 text-center">
        {loading ? "Searching" : "Search"} {!loading && "Results"} {" "}
          {searchText} {loading && "..."}
        </h4>

        {loading && (
          <div className="d-flex justify-content-center mb-5">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        <InfiniteScroll
          scrollableTarget="app"
          dataLength={searchData.length}
          next={fetchMore}
          hasMore={requestData.next_page_url ? true : false}
          loader={
            <div className="text-center my-3">
              {
                requestData.next_page_url &&
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              }
            </div>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <div className="row pt-4 m-0">
            {searchData.length > 0 ? (
              searchData.map((item, index) => {
                return (
                  <div key={index} className="col-md-3 mb-5">
                    <Card
                      item={item}
                      addToShowings={(value) => addToShowings(value)}
                    />
                  </div>
                ); //
              })
            ) : (
              <h3 className="text-center">Data not found</h3>
            )}
          </div>
        </InfiniteScroll>
      </div>
      <FooterComponent />
    </>
  );
};

const mapStateToProps = (state) => {
  return { searchState: state.searchState };
};

const mapDispatchToProps = (dispatch) => ({
  savePropertyTypes: (data) => {
    dispatch(savePropertyTypes(data));
  },
  setShowings: (data) => {
    dispatch(setShowings(data));
  },
  saveSearchData: (data) => {
    dispatch(saveSearchData(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsPage);
