import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
// import Swal from "sweetalert2";

import RootElement from "../components/RootElement";
import PropertyModel from "../models/PropertyModel";

// import { RMIUploader } from "react-multiple-image-uploader";

/* const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 90 + "%",
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 4,
  height: "100vh",
  overflowX: "hidden",
};
 */
export default function OwnProperties(props) {
  // Modal
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // Custom
  const [loading, setLoading] = useState(false);
  // const [properties, setProperties] = useState([]);
  // const [propertyType, setPropertyType] = useState("own");

  const fetchProperties = async () => {
    try {
      await PropertyModel.fetchProperties('own');
      // setProperties(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error => ", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchProperties();
  }, []);

  /*  const deleteProperty = async (id, type, index) => {
     await confirmMsg("You want to delete this entry?");
 
     setLoading(true);
     try {
       const data = {
         property_id: id,
         property_type: type,
       };
 
       await PropertyModel.deleteProperty(data);
       fetchProperties();
       showAlert("Deleted!", `Property #${id} successfully deleted`, "success");
     } catch (error) {
       console.log("Error =>", error);
     }
   }; */

  /*   const confirmMsg = async (text) => {
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: "Are you sure?",
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            resolve();
          }
        });
      });
    }; */

  /* const showAlert = (title, msg, status) => {
    Swal.fire(title, msg, status);
  }; */

  // const [openUpload, setOpenUpload] = React.useState(false);
  // const handleOpenUpload = () => setOpenUpload(true);
  // const handleCloseUpload = () => setOpenUpload(false);

  /*  const [visible, setVisible] = useState(false);
   const [dataSources, setDataSources] = useState([]);
 
   const handleSetVisible = () => {
     setVisible(true);
   };
   const hideModal = () => {
     setVisible(false);
   };
   const onUpload = (data) => {
     console.log("Upload files", data);
   };
   const onSelect = (data) => {
     console.log("Select files", data);
   };
   const onRemove = (id) => {
     console.log("Remove image id", id);
   }; */

  const [images, setImages] = useState([]);
  // const [rawImageFiles, setRawImageFiles] = useState(null);
  const [count, setCount] = useState(0);

  const handleFileChange = async (e) => {
    // console.log(e.target.files);
    const files = e.target.files;

    // setRawImageFiles(files);

    const _images = images;

    for (var i = files.length - 1; i >= 0; i--) {
      const imageBase64 = await convert(files[i]);

      const data = {
        name: files[i].name,
        src: URL.createObjectURL(files[i]),
        type: files[i].type,
        data: imageBase64,
      };

      _images.push(data);
    }

    setImages(_images);

    setTimeout(function () {
      setCount(count + 1);
    }, 500);

    console.log("files", files);
    console.log("images", images);

    // addNewProperty({ images: _images }, "added");
  };

  const convert = (img) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onloadend = function () {
        console.log("RESULT", reader.result);
        resolve(reader.result);
      };
      reader.readAsDataURL(img);
    });
  };

  return (
    <RootElement>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="photoUpload">
            Uploadss
          </span>
        </div>
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            id="photo-upload"
            aria-describedby="photoUpload"
            accept="image/*"
            multiple
            onChange={(e) => handleFileChange(e)}
          />
          <label class="custom-file-label" for="photo-upload">
            Choose file
          </label>
        </div>
      </div>

      <div>
        {images.map((image, index) => (
          <img
            src={image.src}
            alt="..."
            class="img-thumbnail"
            key={index}
            width="250"
          />
        ))}
      </div>
    </RootElement>
  );
}
