import React from "react";
import { Helmet } from "react-helmet";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import bannerImage from "../assets/img/gunahomes_buyers.png";
import bannerImage2 from "../assets/img/guna_homes_buyers_2.png";
import hlg1 from "../assets/img/hlg1.png";
import sellersparr from "../assets/img/sellersparr.jpg";

const paraStyles = {
  color: "#949497",
};

const subHeadingStyle = {
  color: "#303030",
  fontWeight: 600,
};

const listStyle = {
  marginTop: 5,
  marginBottom: 5,
  color: "black",
};
const TITLE = process.env.REACT_APP_SITE_NAME+' - Buy'  

const About = () => {
  return (
    <>
    <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
      <HeaderComponent />
      <div className="innerbanner">
        <div className="w-100 ib_top"></div>
      </div>

      <img src={bannerImage} className="w-100" />

      <div className="container">
        <div className="mb-5">
          
        </div>

        <div className="container detail-section">
          <div className="d-md-flex align-items-center">
            <h3 className="font-weight-bold"><span className="text_secondary">THINKING OF</span> BUY A HOME</h3>
          </div>

          <div className="pt-3 pb-5">
            <p className="" style={paraStyles}>
              <strong style={{ color: "#e60d2e" }}>Buying a Home</strong> can be
              an overwhelming process and emotionally draining. Finding the
              right home is not always an easy task.
            </p>

            <p className="pt-1" style={paraStyles}>
              I advise buyers to schedule a maximum of 7 homes at a time because
              any more than that will make a buyer's head spin.Most buyers
              conduct a lot of research online before ever stepping foot in a
              home. Buyers spend an average of 6 to 8 weeks, according to the
              National Association of REALTORS, trying to figure out where they
              want to live.But once the neighborhood is selected, most buyers
              end up buying a home after 2 or 3 home tours.We can generally spot
              overpriced listings and advise you accordingly.
            </p>
          </div>
        </div>

        <div className="row pb-4">
          <div className="col-md-4 pl-4 pt-3">
            <img src={bannerImage2} style={{ width: 100 + "%" }}/>
          </div>
          <div className="col-md-8">
            <div>
              <h5 className="font-weight-bold">Why Buyers Choose me?</h5>
            </div>

            <div>
              <ul className="pl-4 pt-2">
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Prequalify you for a purchase in your price range.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Thoroughly understand your needs for your new home.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Do an initial search of existing homes for sale that meet
                    your needs.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Create a “Computerized Automatic Buyer Search Program”
                    customized specifically for your needs. E-mails are sent
                    immediately.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Research all For Sale by Owners, expired, and cancelled
                    listings for additional potential matches.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Prospect two hours every day looking for new listings that
                    are not yet on the market.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Contact the Top 25 Listing Agents locally to discuss
                    potential “pocket listings”.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Broadcast your needs and wants at our office meetings to
                    identify any new inventory first. We have a total of 50
                    Salespeople.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Preview properties regularly to help you save time.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Negotiation Skills: my Buyer transactions typically are 2.5%
                    below the average prices negotiated in our area. This means
                    $_______ in your favour
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Eliminate any properties that do not meet your needs.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Contact the Listing Agents to gather details on qualified
                    properties.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Personally DOOR KNOCK the neighborhoods you are focusing on
                    to identify any possible Sellers not on the market.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Conduct an ongoing pricing analysis to ensure the most
                    economical purchase possible.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Advise you as to the most advantageous offer strategy prior
                    to preparing your offer. (E.g.: unique terms and
                    conditions.)
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Create the purchase contract and arrange for it to be
                    presented.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Represent you on all offer presentations to ensure
                    negotiation of the best possible price and terms.
                  </p>
                </li>

                <li style={listStyle}>
                  <p style={paraStyles}>
                    Provide you with a list of all potential Closing Costs.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Assist you in arranging financing, appraisals and
                    inspections as needed.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Provide a list of qualified tradespeople to assist you in
                    the buying process when necessary.
                  </p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    Supervise the completion of all paperwork.
                  </p>
                </li>

                <li style={listStyle}>
                  <p style={paraStyles}>Arrange a final walk-through.</p>
                </li>
                <li style={listStyle}>
                  <p style={paraStyles}>
                    If you have not purchased within two weeks, conduct a full
                    Consultation
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${sellersparr})` ,
            height: 500,
          }}
          className="mb-5"
          >
          <div className="w-50 p-5 bottom-section">
            <h3 className="text-white px-5 pt-5 pb-3 font-weight-bold">Search. See. Love</h3>
            <p className="text-white my-2 px-5">
              Don’t wait to buy real estate, BUY REAL ESTATE AND WAIT
            </p>
            <p className="text-white my-2 px-5">
              Real Estate cannot be lost or stolen, nor can it be carried away.
              Purchased with common sense, paid for in full, and managed with
              reasonable care ….. it is about the safest investment in the
              world.
            </p>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default About;
