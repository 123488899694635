import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import Swal from "sweetalert2";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Zoom from "@mui/material/Zoom";

import EmailListModel from "../../../../../Models/EmailListModel";

import RootElement from "../../RootElement";

import MailForm from "./MailForm";
import EmailActionButton from "./EmailActionButton";
import TestName from "./TestName";
import Tools from "./Tools";

import Title from "./tools/Title";
import Paragraph from "./tools/Paragraph";
import Image from "./tools/Image";
import Images from "./tools/Images";
import ParagraphImage from "./tools/ParagraphImage";
import ImageParagraph from "./tools/ImageParagraph";
import TextInput from "./tool-forms/TextInput";
import TextArea from "./tool-forms/TextArea";
import Uploader from "./tool-forms/Uploader";

const style = {
	position: "absolute",
	top: "10%",
	left: "20%",
	transform: "translate(-50%, -50%)",
	width: 800,
	height: 500,
	bgcolor: "background.paper",
	border: "1px solid #dedede",
	boxShadow: 24,
	p: 4,
	overflow: "scroll",
};

const previewModalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 800,
	height: 500,
	bgcolor: "background.paper",
	border: "1px solid #dedede",
	boxShadow: 24,
	p: 4,
	overflow: "scroll",
};

export default function SendEmails() {
	const History = useHistory();

	const [loading, setLoading] = useState(true);

	const [viewList, setViewList] = useState(false);

	const [emails, setEmails] = useState([]);
	const [groups, setGroups] = useState([]);

	const emailTemplates = ["reply", "thanks", "test", "Custom"];

	const [template, setTemplate] = useState("Choose template");

	const fetchGroups = async () => {
		try {
			const res = await EmailListModel.getGroups();
			setGroups(res.data);
			setLoading(false);
		} catch (error) {
			console.log("error => ", error);
			setLoading(false);
		}
	};

	const getGroupEmails = async (type) => {
		try {
			const res = await EmailListModel.getGroupEmails(type);
			setEmails(res.data);
			setLoading(false);
		} catch (error) {
			console.log("error => ", error);
			setLoading(false);
		}
	};

	const removeFromBulkEmailList = async (id) => {
		const filtered = emails.filter((email) => email.id != id);
		setEmails(filtered);
	};

	const sendEmail = async (payload) => {
		console.log("payload ", payload);

		setLoading(true);

		return;

		await EmailListModel.sendEmail(payload);

		Swal.fire("Success", "Emails sent successfully", "success");
		setLoading(false);
	};

	const formik = useFormik({
		initialValues: {
			title: get("", "title", ""),
			subject: get("", "subject", ""),
			msg: get("", "msg", ""),
			group_type: get("", "group_type", ""),
		},
		validationSchema: Yup.object({
			title: Yup.string().required("Required"),
			subject: Yup.string().required("Required"),
			msg: Yup.string().required("Required"),
		}),
		onSubmit: (values) => {
			console.log("Values => ", values);
			sendEmail(values);
		},
	});

	const [count, setCount] = useState(0);
	const [componentsArray, setComponentsArray] = useState([
		{
			type: "Title",
			block: <Title title="Testing Title" />,
		},
		{
			type: "Paragraph",
			block: (
				<Paragraph paragraph="t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here," />
			),
		},
		{
			type: "Image",
			block: (
				<Image src="https://icons.getbootstrap.com/assets/img/icons-hero.png" />
			),
		},
		{
			type: "Images",
			block: (
				<Images
					one="https://icons.getbootstrap.com/assets/img/icons-hero.png"
					two="https://icons.getbootstrap.com/assets/img/icons-hero.png"
				/>
			),
		},
		{
			type: "Paragraph with Image",
			block: (
				<ParagraphImage
					src="https://icons.getbootstrap.com/assets/img/icons-hero.png"
					paragraph="t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,"
				/>
			),
		},
		{
			type: "Image with paragraph",
			block: (
				<ImageParagraph
					src="https://icons.getbootstrap.com/assets/img/icons-hero.png"
					paragraph="t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,"
				/>
			),
		},
	]);

	const convertToHtml = async (formikData) => {
		setLoading(true);

		let htmls = [];

		for (var i = 0; i < componentsArray.length; i++) {
			console.log("componentsArray loop => ", componentsArray[i]);

			const rawHtml = ReactDOMServer.renderToStaticMarkup(
				componentsArray[i].block
			);

			htmls.push(rawHtml);
		}

		// console.log("htmls", htmls);
		console.log("componentsArray", componentsArray);

		throw "stop";

		try {
			const res = await EmailListModel.sendTestEmail({
				...formikData,
				items: htmls,
				emails,
			});
			console.log("res ", res);
			setLoading(false);
		} catch (error) {
			console.log("error => ", error);
			setLoading(false);
		}
	};

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const [modalContent, setModalContent] = useState({
		type: "",
		componentType: "",
	});

	const addComponents = (type) => {
		console.log("add", type);

		switch (type) {
			case "title":
				// code block
				setModalContent({ type: "Title", componentType: type });
				handleOpen();
				break;
			case "paragraph":
				setModalContent({ type: "Paragraph", componentType: type });
				handleOpen();
				break;
			case "image":
				// code block
				setModalContent({ type: "Image", componentType: type });
				handleOpen();
				break;
			case "images":
				// code block
				setModalContent({ type: "Images", componentType: type });
				handleOpen();
				break;
			case "paragraph-image":
				// code block
				setModalContent({
					type: "Paragraph with Image",
					componentType: type,
				});
				handleOpen();
				break;
			case "image-paragraph":
				// code block
				setModalContent({
					type: "Paragraph with Image",
					componentType: type,
				});
				handleOpen();
				break;
			default:
			// code block
		}

		return;

		var rand = Math.round(Math.random() * 400);
		setComponentsArray([...componentsArray, <TestName name={rand} />]);

		setTimeout(function () {
			console.log(componentsArray);
			setCount(count + 1);
		}, 1500);
	};

	const testSend = () => {
		console.log("testSend", formik.values);
		// setLoading(true);

		if (template === "Custom") {
			convertToHtml(formik.values);
		} else {
			const data = {
				...formik.values,
				template,
				emails,
			};

			sendEmail(data);
		}
	};

	useEffect(() => {
		fetchGroups();
		// convertToHtml();

		return () => {
			fetchGroups();
		};
	}, []);

	const [inputValue, setInputValue] = useState("");
	const [imgUrls, setImgUrls] = useState([]);

	const handleAddComponent = (type) => {
		console.log("state", inputValue);
		console.log(type);

		switch (type) {
			case "title":
				// code block
				setComponentsArray([
					...componentsArray,
					{
						type: "Title",
						block: <Title title={inputValue} />,
					},
				]);

				console.log("??");

				break;
			case "paragraph":
				// code block
				setComponentsArray([
					...componentsArray,
					{
						type: "Paragraph",
						block: <Paragraph paragraph={inputValue} />,
					},
				]);

				break;
			case "image":
				// code block
				setComponentsArray([
					...componentsArray,
					{
						type: "Image",
						block: <Image src={imgUrls[0].src} />,
						srcDataOne: imgUrls[0].data,
					},
				]);

				break;
			case "images":
				// code block
				setComponentsArray([
					...componentsArray,
					{
						type: "Images",
						block: (
							<Images one={imgUrls[0].src} one={imgUrls[1].src} />
						),
						srcDataOne: imgUrls[0].data,
						srcDataTwo: imgUrls[1].data,
					},
				]);

				break;
			case "paragraph-image":
				// code block
				setComponentsArray([
					...componentsArray,
					{
						type: "Paragraph with image",
						block: (
							<ParagraphImage
								src={imgUrls[0].src}
								paragraph={inputValue}
							/>
						),
						srcDataOne: imgUrls[0].data,
					},
				]);

				break;
			case "image-paragraph":
				// code block
				setComponentsArray([
					...componentsArray,
					{
						type: "Image with paragraph",
						block: (
							<ImageParagraph
								src={imgUrls[0].src}
								paragraph={inputValue}
							/>
						),
						srcDataOne: imgUrls[0].data,
					},
				]);

				break;
			default:
			// code block
		}
		handleClose();
		console.log(componentsArray);
	};

	const handleFileChange = async (files) => {
		let imgDatas = [];

		for (var i = files.length - 1; i >= 0; i--) {
			const imageBase64 = await convert(files[i]);

			const res = await EmailListModel.uploadEmailPhoto({ img: imageBase64 });

			const data = {
				src: res.data,
				data: imageBase64,
			};

			imgDatas.push(data);
		}

		console.log(imgDatas);

		setImgUrls(imgDatas);
	};

	const [previewModal, setPreviewModal] = useState(false);
	const openPreviewModal = () => setPreviewModal(true);
	const closePreviewModal = () => setPreviewModal(false);

	const removeBlock = (indexLocation) => {
		console.log("removeBlock", indexLocation);

		const filtered = componentsArray.filter(
			(data, index) => index != indexLocation
		);

		setComponentsArray(filtered);
	};

	const convert = (img) => {
		return new Promise((resolve) => {
			var reader = new FileReader();
			reader.onloadend = function () {
				// console.log("RESULT", reader.result);
				resolve(reader.result);
			};
			reader.readAsDataURL(img);
		});
	};

	return (
		<RootElement>
			<div className="send-email my-5">
				<div className="shadow p-3">
					<div>
						<button
							className="btn btn-lg "
							onClick={() => History.goBack()}
						>
							<i className="bi bi-arrow-left"></i>
						</button>
					</div>

					<div className="d-flex flex-row-reverse mb-3">
						<div class="dropdown">
							<button
								className="btn btn-success dropdown-toggle"
								type="button"
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								disabled={loading}
							>
								{template}
							</button>
							<div
								class="dropdown-menu"
								aria-labelledby="dropdownMenuButton"
							>
								{emailTemplates.map((temp, index) => (
									<a
										key={index}
										className="dropdown-item"
										href="#"
										onClick={(e) =>
											setTemplate(e.target.text)
										}
									>
										{temp}
									</a>
								))}
							</div>
						</div>

						<div class="dropdown mx-3">
							<button
								class="btn btn-success dropdown-toggle"
								type="button"
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								disabled={loading}
							>
								{formik.values.group_type === ""
									? "Select a group"
									: formik.values.group_type}
							</button>
							<div
								class="dropdown-menu"
								aria-labelledby="dropdownMenuButton"
							>
								{groups &&
									groups.map((group, index) => (
										<a
											key={index}
											className="dropdown-item"
											href="#"
											onClick={(e) => {
												getGroupEmails(
													group.group_type
												);
												formik.setFieldValue(
													"group_type",
													e.target.text
												);
											}}
										>
											{group.group_type}
										</a>
									))}
							</div>
						</div>
					</div>

					{viewList && (
						<div className="mb-3">
							<div>
								<div>
									<div
										style={{
											height: 250,
											marginTop: 25,
											overflow: "auto",
										}}
										className="shadow mb-5 p-3"
									>
										<table class="table table-sm table-borderless table-hover">
											<thead>
												<tr>
													<th scope="col">#</th>
													<th scope="col">Name</th>
													<th scope="col">Email</th>
													<th scope="col">Action</th>
												</tr>
											</thead>
											<tbody>
												{emails.map((data, index) => (
													<tr key={index}>
														<th scope="row">
															{index + 1}
														</th>
														<td>
															{data.full_name}
														</td>
														<td>{data.email}</td>
														<td>
															<button
																className="btn btn-sm btn-danger"
																onClick={() =>
																	removeFromBulkEmailList(
																		data.id
																	)
																}
															>
																<i class="bi bi-x"></i>
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="send-email-container">
						<div className="email-header">
							<p>
								Send email to :{"  "}
								{formik.values.group_type === ""
									? "Select a group"
									: formik.values.group_type}
							</p>
							{formik.values.group_type && (
								<button
									className="btn btn-sm btn-success ml-2"
									data-toggle="collapse"
									data-target="#collapseExample"
									onClick={() => setViewList(!viewList)}
								>
									View List
								</button>
							)}
						</div>

						<div class="form-group mt-3">
							<label
								for="subject"
								className="custom-input-label mb-2"
							>
								Email Title
							</label>
							<input
								type="text"
								className="form-control custom-input"
								id="subject"
								aria-describedby="subjectHelp"
								value={formik.values.title}
								onChange={(value) =>
									formik.setFieldValue(
										"title",
										value.target.value
									)
								}
								onBlur={() => formik.setFieldTouched("title")}
							/>
							{Boolean(formik.errors.title) &&
								formik.touched.title ? (
								<small
									id="titleError"
									className={
										"form-text " +
											Boolean(formik.errors.title)
											? "text-danger"
											: "text-muted"
									}
								>
									{formik.errors.title}
								</small>
							) : (
								""
							)}
						</div>

						{template != "Custom" && (
							<div className="send-email-body">
								<form onSubmit={formik.handleSubmit}>
									<div class="form-group">
										<label
											for="subject"
											className="custom-input-label mb-2"
										>
											Email Subject
										</label>
										<input
											type="text"
											className="form-control custom-input"
											id="subject"
											aria-describedby="subjectHelp"
											value={formik.values.subject}
											onChange={(value) =>
												formik.setFieldValue(
													"subject",
													value.target.value
												)
											}
											onBlur={() =>
												formik.setFieldTouched(
													"subject"
												)
											}
										/>
										{Boolean(formik.errors.subject) &&
											formik.touched.subject ? (
											<small
												id="subjectError"
												className={
													"form-text " +
														Boolean(
															formik.errors.subject
														)
														? "text-danger"
														: "text-muted"
												}
											>
												{formik.errors.subject}
											</small>
										) : (
											""
										)}
									</div>
									<div class="form-group">
										<label
											for="emailMessage"
											className="custom-input-label mb-2"
										>
											Message
										</label>
										<textarea
											type="text"
											className="form-control"
											id="emailMessage"
											rows="5"
											value={formik.values.msg}
											onChange={(value) =>
												formik.setFieldValue(
													"msg",
													value.target.value
												)
											}
											onBlur={() =>
												formik.setFieldTouched("msg")
											}
										/>
										{Boolean(formik.errors.msg) &&
											formik.touched.msg ? (
											<small
												id="msgError"
												class={
													"form-text " +
														Boolean(formik.errors.msg)
														? "text-danger"
														: "text-muted"
												}
											>
												{formik.errors.msg}
											</small>
										) : (
											""
										)}
									</div>
								</form>
							</div>
						)}

						{template === "Custom" && (
							<>
								<div className="shadow p-3 mt-3 mb-5">
									<div className="d-flex align-items-center">
										<h5 className="mr-3">List of Blocks</h5>

										<button
											className="btn btn-success"
											onClick={() => openPreviewModal()}
										>
											Preview
										</button>
									</div>

									<div>
										{componentsArray.length > 0 ? (
											componentsArray.map(
												(data, index) => (
													<div className="d-flex align-content-center justify-content-between">
														<div className="my-1">
															<p className="font-weight-bold">
																{" "}
																{index +
																	1}.{" "}
																{data.type}
															</p>
														</div>

														<div>
															<button
																className="btn btn-sm btn-danger"
																onClick={() =>
																	removeBlock(
																		index
																	)
																}
															>
																Remove Block
															</button>
														</div>
													</div>
												)
											)
										) : (
											<p className="my-2">
												There's no block in your
												template. Please add a block
											</p>
										)}
									</div>
								</div>

								<div className="my-3">
									<Tools
										addComponents={(id) =>
											addComponents(id)
										}
									/>
								</div>
							</>
						)}

						<div className="d-flex justify-content-between align-items-center mt-5">
							<button
								className="btn btn-success"
								onClick={() => testSend()}
							>
								{!loading ? (
									<div>
										<i className="bi bi-cursor pr-2"></i>
										Send Now
									</div>
								) : (
									<div className="px-3">
										<div
											class="spinner-border spinner-border-sm"
											role="status"
										>
											<span class="sr-only">
												Loading...
											</span>
										</div>
									</div>
								)}
							</button>
						</div>
					</div>
				</div>
			</div>

			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Zoom in={open}>
					<Box sx={style}>
						<h5>Add {modalContent.type}</h5>

						<div className="my-3">
							{modalContent.componentType === "title" && (
								<TextInput
									label="Title"
									handleTextInput={(text) =>
										setInputValue(text)
									}
								/>
							)}

							{modalContent.componentType === "paragraph" && (
								<TextArea
									label="Paragraph"
									handleTextInput={(text) =>
										setInputValue(text)
									}
								/>
							)}

							{modalContent.componentType === "image" ||
								modalContent.componentType === "images" ? (
								<Uploader
									multiple={
										modalContent.componentType === "image"
											? false
											: true
									}
									handleFileChange={(files) =>
										handleFileChange(files)
									}
								/>
							) : (
								""
							)}

							{modalContent.componentType === "paragraph-image" ||
								modalContent.componentType === "image-paragraph" ? (
								<div>
									<div className="mb-2">
										<Uploader
											multiple={false}
											handleFileChange={(files) =>
												handleFileChange(files)
											}
										/>
									</div>

									<TextArea
										label="Paragraph"
										handleTextInput={(text) =>
											setInputValue(text)
										}
									/>
								</div>
							) : (
								""
							)}
						</div>

						<div
							className="add-button-wrapper text-right"
							style={{
								position: "absolute",
								bottom: 0,
								right: 0,
							}}
						>
							<button
								className="btn btn-lg btn-success"
								onClick={() =>
									handleAddComponent(
										modalContent.componentType
									)
								}
							>
								Add
							</button>
						</div>
					</Box>
				</Zoom>
			</Modal>

			<Modal
				open={previewModal}
				onClose={closePreviewModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={previewModalStyle}>
					<div>
						<div
							class="header"
							style={{
								height: 200,
								backgroundColor: "darkblue",
								textAlign: "center",
							}}
						>
							<img
								src="https://icons.getbootstrap.com/assets/img/icons-hero.png"
								width="100"
								style={{ paddingTop: 20 }}
							/>
							<h3 style={{ color: "#fff", marginTop: 20 }}>
								Title here
							</h3>
						</div>

						<div className="my-5">
							{componentsArray.map((data, index) => (
								<div>{data.block}</div>
							))}
						</div>

						<div
							class="footer"
							style={{
								height: 250,
								backgroundColor: "#101010",
								textAlign: "center",
							}}
						>
							<div
								class="footer-content"
								style={{ paddingTop: 50 }}
							>
								<div
									class="follow-us"
									style={{ color: "#fff" }}
								>
									<p className="text-center text-white">
										Follow us on
									</p>
									<div className="mt-3">
										<a
											href="#"
											target="_blank"
											className="mx-2"
										>
											<img
												src="https://img.icons8.com/color/48/000000/twitter--v1.png"
												width="25"
											/>
										</a>

										<a
											href="#"
											target="_blank"
											className="mx-2"
										>
											<img
												src="https://img.icons8.com/color/48/000000/facebook-new.png"
												width="25"
											/>
										</a>

										<a
											href="#"
											target="_blank"
											className="mx-2"
										>
											<img
												src="https://img.icons8.com/color/48/000000/linkedin.png"
												width="25"
											/>
										</a>

										<a
											href="#"
											target="_blank"
											className="mx-2"
										>
											<img
												src="https://img.icons8.com/fluency/48/000000/youtube-play.png"
												width="25"
											/>
										</a>
									</div>
								</div>

								<div
									class="copyright"
									style={{ color: "#fff", marginTop: 30 }}
								>
									Copyright 1999-2022 by Teamguna.com. All
									Rights Reserved.
								</div>
							</div>
						</div>
					</div>
				</Box>
			</Modal>
		</RootElement>
	);
}
