import React, { useState, CSSProperties } from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import Swal from "sweetalert2";

import {
	useCSVReader,
	lightenDarkenColor,
	formatFileSize,
} from "react-papaparse";

import RootElement from "../../RootElement";

import EmailListModel from "../../../../../Models/EmailListModel";
import file from "../../../../../assets/sample-import.csv";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
	DEFAULT_REMOVE_HOVER_COLOR,
	40
);
const GREY_DIM = "#686868";

const styles = {
	zone: {
		alignItems: "center",
		border: `2px dashed ${GREY}`,
		borderRadius: 20,
		display: "flex",
		flexDirection: "column",
		height: "100%",
		justifyContent: "center",
		padding: 20,
	},
	file: {
		background: "linear-gradient(to bottom, #EEE, #DDD)",
		borderRadius: 20,
		display: "flex",
		height: 120,
		width: 120,
		position: "relative",
		zIndex: 10,
		flexDirection: "column",
		justifyContent: "center",
	},
	info: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		paddingLeft: 10,
		paddingRight: 10,
	},
	size: {
		backgroundColor: GREY_LIGHT,
		borderRadius: 3,
		marginBottom: "0.5em",
		justifyContent: "center",
		display: "flex",
	},
	name: {
		backgroundColor: GREY_LIGHT,
		borderRadius: 3,
		fontSize: 12,
		marginBottom: "0.5em",
	},
	progressBar: {
		bottom: 14,
		position: "absolute",
		width: "100%",
		paddingLeft: 10,
		paddingRight: 10,
	},
	zoneHover: {
		borderColor: GREY_DIM,
	},
	default: {
		borderColor: GREY,
	},
	remove: {
		height: 23,
		position: "absolute",
		right: 6,
		top: 6,
		width: 23,
	},
};

const confirm = (title, text) => {
	return new Promise((resolve) => {
		Swal.fire({
			title: title,
			text: text,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
		}).then((result) => {
			if (result.isConfirmed) {
				resolve();
			}
		});
	});
};

export default function CSVReader() {
	const [loading, setLoading] = useState(false);

	const { CSVReader } = useCSVReader();
	const [zoneHover, setZoneHover] = useState(false);
	const [removeHoverColor, setRemoveHoverColor] = useState(
		DEFAULT_REMOVE_HOVER_COLOR
	);

	const [csvData, setCsvData] = useState([]);
	const [count, setCount] = useState(0);
	const [emails, setEmails] = useState([]);

	const handleResult = async (results) => {
		// console.log("results", results);
		setLoading(true);
		const rawData = results.data;

		let data = [];

		for (var i = 1; rawData.length - 1 >= i; i++) {
			const el = rawData[i];
			if (el.length > 2) {
				data.push(el);
			}
		}

		setCsvData(data);

		const res = await EmailListModel.importCsv(data);
		console.log("ssss", res);
		setEmails(res.data);

		setLoading(false);
	};

	const updateEntry = async (email, index) => {
		await confirm(
			"Confirm",
			"Would you like to update the existing entry with this details?"
		);
		await EmailListModel.updateEntry(email);

		let emailData = emails;
		emailData[index].status = "Success";
		emailData[index].message = "Entry updated.";

		setEmails(emailData);

		// console.log("emailData ", emailData);
		setTimeout(function () {
			setCount(count + 1);
		}, 1500);
	};

	return (
		<RootElement>
			{loading && (
				<Box sx={{ width: "100%" }}>
					<LinearProgress />
				</Box>
			)}

			<div className="container">
				<div className="my-5">
					<CSVReader
						onUploadAccepted={(results) => {
							handleResult(results);
							setZoneHover(false);
						}}
						onDragOver={(event) => {
							event.preventDefault();
							setZoneHover(true);
						}}
						onDragLeave={(event) => {
							event.preventDefault();
							setZoneHover(false);
						}}
					>
						{({
							getRootProps,
							acceptedFile,
							ProgressBar,
							getRemoveFileProps,
							Remove,
						}: any) => (
							<>
								<div
									{...getRootProps()}
									style={Object.assign(
										{},
										styles.zone,
										zoneHover && styles.zoneHover
									)}
								>
									{acceptedFile ? (
										<>
											<div style={styles.file}>
												<div style={styles.info}>
													<span style={styles.size}>
														{formatFileSize(
															acceptedFile.size
														)}
													</span>
													<span style={styles.name}>
														{acceptedFile.name}
													</span>
												</div>
												<div style={styles.progressBar}>
													<ProgressBar />
												</div>
												<div
													{...getRemoveFileProps()}
													style={styles.remove}
													onMouseOver={(event) => {
														event.preventDefault();
														setRemoveHoverColor(
															REMOVE_HOVER_COLOR_LIGHT
														);
													}}
													onMouseOut={(event) => {
														event.preventDefault();
														setRemoveHoverColor(
															DEFAULT_REMOVE_HOVER_COLOR
														);
													}}
												>
													<Remove
														color={removeHoverColor}
													/>
												</div>
											</div>
										</>
									) : (
										"Drop CSV file here or click to upload"
									)}
								</div>
							</>
						)}
					</CSVReader>
				</div>

				<div className="text-right mb-3">
					<a href={file}>
						<i class="bi bi-file-earmark-arrow-down"></i> sample CSV File
					</a>
				</div>

				<div>
					<table class="table table-bordered">
						<thead>
							<tr>
								<th scope="col">SN</th>
								<th scope="col">Full Name</th>
								<th scope="col">Email</th>
								<th scope="col">Phone</th>
								<th scope="col">Status</th>
								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>
							{emails &&
								emails.map((email, index) => (
									<tr key={index}>
										<th scope="row">{index + 1}</th>
										<td>{email.full_name}</td>
										<td>{email.email}</td>
										<td>{email.phone_number}</td>
										<td>
											{email.status === "error" && (
												<p className="text-danger">
													{email.message}
												</p>
											)}
											{email.status === "Success" && (
												<p className="text-success">
													{email.message}
												</p>
											)}
										</td>
										<td>
											<button
												className="btn btn-sm btn-info"
												disabled={
													email.status ===
														"Success" && true
												}
												onClick={() =>
													updateEntry(email, index)
												}
											>
												Update
											</button>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</RootElement>
	);
}
