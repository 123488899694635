import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";

import RootElement from "../components/RootElement";
import AppointmentForm from "../components/forms/AppointmentForm";
import GoogleMap from "../components/GoogleMap";

import DatePicker from "../../../componets/DatePicker";

import EnquiryModel from "../../../Models/EnquiryModel";
import CommentModel from "../../../Models/CommentModel";

import {
  getPropertyList,
  getPropertyLists,
  savePropertyList,
  removePropertyList,
} from "../../../store/actions/App/AppActions";
import moment from "moment";
import TextInput from "../components/inputs/TextInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #ccc",
  boxShadow: 24,
  p: 4,
};

const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #ccc",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "90%",
};

const Appointment = (props) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [data, setData] = useState(10);
  const [enquiries, setEnquiries] = useState([]);
  const [newFilteredPropertyData, setNewFilteredPropertyData] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = async () => {
    await EnquiryModel.fetchAppointments();
    setOpen(false);
  };

  const markAsCompleted = async (id, email_address) => {
    setLoading(true);
    try {
      await EnquiryModel.replyAppointment({
        appointment_id: id,
        type: "complete",
        email_address,
      });

      fetchAppointments();
      setLoading(false);

      Swal.fire("Done", "Marked as completed", "success");
    } catch (error) {
      // console.log("error", error);
      setLoading(false);
      Swal.fire("error", "Something went wrong.", "error");
    }
  };

  const [appointmentType, setAppointmentType] = useState("any");

  const fetchAppointments = async () => {
    try {
      const res = await EnquiryModel.fetchAppointments(appointmentType);
      setEnquiries(res.results);

      if (res.results.length > 0) {
        let _res = res.results;
        let _listData = [];

        for (var i = _res.length - 1; i >= 0; i--) {
          const _data = {
            appointment: _res[i].appointment,
            email_address: _res[i].email_address,
            data: _res[i].properties,
          };

          _listData.push(_data);
        }

        props.savePropertyList(_listData);
      }

      setLoading(false);
    } catch (error) {
      // console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchAppointments();
  }, [appointmentType]);

  const [openPropertList, setOpenPropertList] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [propertyListEmail, setPropertyListEmail] = useState("");
  const [propertyListname, setPropertyListname] = useState("");

  const handleOpenPropertList = (payload) => {
    setPropertyListEmail(payload.email_address);
    setPropertyListname(payload.full_name);
    setPropertyList(payload.properties);
    setOpenPropertList(true);
  };

  const handleCloseOpenPropertList = async () => setOpenPropertList(false);

  // Appointment List section
  const [appointmentListModal, setAppointmentListModal] = useState(false);
  const [filteredList, setFilteredList] = useState({
    customer_name: propertyListname,
    email_address: "",
    data: [],
    appointment: "",
  });

  const handleOpenAppointmentListModal = async (data) => {
    // console.log(`data => `, data);
    Swal.fire({
      icon: "warning",
      title: "Please Wait",
      text: "Fetching comments please wait.",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let appointmentsList = props.state.propertyList;

    let filtered = {};

    for (let x = 0; x < appointmentsList.length; x++) {
      const AP = appointmentsList[x];

      if (AP.appointment === data.appointment) {
        // checking if the admin already saved the available propeties in database or not
        // If it is not saved default properties will be in the list see line 214
        if (data.property_list !== null && data.property_list.length > 0) {
          // console.log(`data => `, data);
          // temp data to be modified below
          let payload = {
            appointment: data.appointment,
            email_address: data.email_address,
            data: data.property_list, // already saved in database
          };

          // if admin added new property to list, we must add it here
          if (newFilteredPropertyData.length > 0) {
            // add all properties in an array so we can remove duplicates
            const complexPropetiesData = [
              ...payload.data,
              ...newFilteredPropertyData,
            ];

            // remove duplates
            // Ref: https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
            const uniqueProperties = complexPropetiesData.filter(
              (v, i, a) =>
                a.findIndex((v2) => v2.property_id === v.property_id) === i
            );

            payload = {
              ...payload,
              data: uniqueProperties,
            };
          }

          filtered = payload;

          break;
        }

        // This will be default properties which means All requested propeties for appointment will be in the list
        filtered = AP;
        break;
      }
    }

    /*  appointmentsList.forEach((list) => {
      if (list.appointment === data.appointment) {
        console.log(`list => `, list);
        console.log(`data => `, data);
        if (data.property_list !== null && data.property_list.length > 0) {
          console.log(`data found => `, data);
          return data;
        }

        console.log(`here? => `);

        return list;
      }
    }); */

    // console.log(`filtered => `, filtered);

    // return;

    for (let f = 0; f < filtered.data.length; f++) {
      const property = filtered.data[f];

      // console.log(`property => `, property);

      const res = await CommentModel.commentsForProperty(property.property_id);

      // console.log(`res => `, res);
      property.rating =
        res.data.rating !== null ? res.data.rating.rating : null;
      property.comments = res.data.comments;

      // default available time
      // property.availableTime = moment().format("Y-m-d, H:s");
      // property.availableTime = "";

      // avoid undefined index error in backend
      // property.comment = "";
    }

    if (filtered) {
      setFilteredList({
        ...filteredList,
        appointment: data.appointment,
        customer_name: propertyListname,
        email_address: data.email_address,
        data: filtered.data,
      });
    } else {
      setFilteredList({ email_address: "", data: [] });
    }

    setTimeout(function () {
      Swal.close();
      setAppointmentListModal(true);
    }, 1000);
  };

  const handleCloseAppointmentListModal = () => setAppointmentListModal(false);

  const addToList = async (property, sn) => {
    // let _list = props.state.propertyList;
    let _list = props.state.propertyList;
    // console.log(`_list => `, _list);
    let found;

    const _data = {
      sn,
      property_id: property.property_id,
      property,
      availableTime: "",
      rating: "",
      comment: "",
    };

    if (_list.length > 0) {
      if (filteredList.data.length > 0) {
        found = filteredList;
      } else {
        found = _list.find((list) => list.email_address === propertyListEmail);
      }

      // console.log(`found => `, found);
      // console.log(`filteredList => `, filteredList);

      if (found) {
        for (var i = 0; i <= _list.length - 1; i++) {
          if (_list[i].email_address === found.email_address) {
            //find property
            const propFound = found.data.find(
              (dt) => dt.property_id === _data.property_id
            );

            // property not found
            if (!propFound) {
              // newFilteredPropertyData = [...filteredList.data, _data.property];
              setNewFilteredPropertyData([
                ...filteredList.data,
                _data.property,
              ]);
              // _list[i].data.push(_data);
            } else {
              Swal.fire({
                position: "top-end",
                html:
                  "<p class='text-left text-danger'>" +
                  "This item is already in the available list or You haven't set any appointments yet!" +
                  "</p>",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
              });
            }
          }
        }
      } else {
        const data = {
          customer_name: propertyListname,
          email_address: propertyListEmail,
          data: [_data],
        };

        // _list.push(data);
      }
    } else {
      const data = {
        customer_name: propertyListname,
        email_address: propertyListEmail,
        data: [_data],
      };

      // _list.push(data);
    }
    // setFilteredList({ ...filteredList, data: newFilteredPropertyData });
    // setCount(count + 1);
    // let _state = props.state.propertyList;

    // setAppointmentList((prevState) => [...prevState, data]);

    // props.savePropertyList(_list);

    // console.log(`filteredList => `, filteredList);
  };

  const removePropertFromList = (property_id) => {
    let _state = props.state.propertyList;

    const filtered = filteredList.data.filter(
      (property) => property.property_id !== property_id
    );

    const payload = {
      ...filteredList,
      data: filtered,
    };

    setFilteredList(payload);
  };

  const handleChange = (property_id, newValue) => {
    let _state = props.state.propertyList;

    for (var i = _state.length - 1; i >= 0; i--) {
      for (var x = _state[i].data.length - 1; x >= 0; x--) {
        if (_state[i].data[x].property_id === property_id) {
          _state[i].data[x].availableTime = newValue;
        }
      }
    }

    props.savePropertyList(_state);
  };

  const handleRating = (type, property_id, value) => {
    let _rating = type === "rating" ? value : "";
    // console.log(`filteredList => `, filteredList);
    let _state = filteredList;

    for (var x = _state.data.length - 1; x >= 0; x--) {
      if (_state.data[x].property_id === property_id) {
        if (type === "rating") {
          _state.data[x].rating = _rating;
        }
      }
    }

    setFilteredList({ ...filteredList, ..._state });
  };

  const handleComment = (type, property_id, value) => {
    let _comment = type === "comment" ? value : "";
    // console.log(`_comment => `, _comment);
    // console.log(`filteredList => `, filteredList);
    let _state = filteredList;

    for (var x = _state.data.length - 1; x >= 0; x--) {
      // console.log(`_state.data[x].property_id => `, _state.data[x].property_id);
      // console.log(`property_id => `, property_id);
      if (_state.data[x].property_id === property_id) {
        if (type === "comment") {
          // console.log(`_state.data[x] => `, _state.data[x]);
          _state.data[x].comment = _comment;
        }
      }
    }

    // const some = { ...filteredList, ..._state };
    // console.log(`some => `, some);
    setFilteredList({ ...filteredList, ..._state });
    // props.savePropertyList(_state);
  };

  async function saveList() {
    try {
      filteredList.data.forEach((data) => {
        if (
          data.availableTime === "" ||
          data.availableTime === null ||
          data.availableTime === undefined
        ) {
          Swal.fire({
            position: "top-end",
            html:
              "<p class='text-left text-danger'>" +
              "Please enter Date and Time for the property" +
              "</p>",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
          });

          return;
        }
      });

      // console.log(`filteredList => `, filteredList);

      // return;

      setLoading(true);

      await CommentModel.saveComment(filteredList);

      handleCloseAppointmentListModal();
      setLoading(false);

      Swal.fire("Done", "List saved successfully", "success");
    } catch (error) {
      handleCloseAppointmentListModal();
      setLoading(false);
      Swal.fire("Error", "Something went wrong!", "error");
      // console.log("error => ", error);
    }
  }

  const changeListPosition = (position, index) => {
    const _list = filteredList;
    const arrayTotal = _list.data.length + 1;

    if (arrayTotal >= position) {
      arraymove(filteredList.data, index, position - 1);
    }
  };

  function arraymove(arr, fromIndex, toIndex) {
    let _arr = arr;
    var element = _arr[fromIndex];

    _arr.splice(fromIndex, 1);
    _arr.splice(toIndex, 0, element);

    props.savePropertyList(_arr);
  }

  return (
    <RootElement>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <div className="">
        <div className="my-5">
          <button
            className="btn btn-sm btn-primary mr-2"
            onClick={() => setAppointmentType("ANY")}
          >
            Available Showings
          </button>
          <button
            className="btn btn-sm btn-success"
            onClick={() => setAppointmentType("COMPLETED")}
          >
            Completed Showings
          </button>
        </div>

        <div className="my-5">
          {enquiries.length > 0 ? (
            <table className="table table-hover">
              <thead>
                <tr className="cutom-tr-font-size">
                  <th scope="col">#</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Email Address</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Message</th>
                  <th scope="col">Properties</th>
                  <th scope="col">Appointment Date</th>
                  <th scope="col">Url</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {enquiries &&
                  enquiries.map((row, index) => (
                    <tr className="cutom-tr-font-size" key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{row.full_name}</td>
                      <td>{row.email_address}</td>
                      <td>{row.phone_number}</td>
                      <td>{row.message}</td>
                      <td>
                        {row.properties.length}

                        <button
                          className="btn btn-sm btn-outline-warning ml-3"
                          onClick={() => handleOpenPropertList(row)}
                        >
                          <i className="bi bi-eye"></i>
                        </button>
                      </td>
                      <td>{row.appointment}</td>
                      <td>{`${process.env.REACT_APP_BASE_URL}/showings/${row.url_code}`}</td>
                      <td>
                        <div>
                          <button
                            className="btn btn-sm btn-success mb-1 mr-1"
                            onClick={() => {
                              setData(row);
                              handleOpen();
                            }}
                            disabled={
                              appointmentType === "COMPLETED" ||
                              row.status === "ACCEPTED"
                                ? true
                                : false
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Accept the showing"
                          >
                            <i className="bi bi-check2"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-danger mb-1 mr-1"
                            onClick={() =>
                              markAsCompleted(row.id, row.email_address)
                            }
                            disabled={
                              row.status === "ACCEPTED" &&
                              row.status !== "COMPLETED"
                                ? false
                                : true
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Mark as completed"
                          >
                            <i className="bi bi-check2-all"></i>
                          </button>

                          <button
                            className="btn btn-sm btn-warning mb-1 mr-1"
                            onClick={() => handleOpenAppointmentListModal(row)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Manage Showing List"
                          >
                            <i className="bi bi-list-stars"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <div className="shadow">
              <div className="p-3 text-center">
                <h3 className="p-5">Currently there's no showings</h3>
              </div>
            </div>
          )}
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div
                className="my-3"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: 7,
                  padding: 15,
                }}
              >
                <div className="d-md-flex">
                  <div className="d-flex my-1 mr-3">
                    <strong className="font-weight-bold">
                      Customer Name:{" "}
                    </strong>
                    <strong className="pl-2">{data.full_name}</strong>
                  </div>
                  <div className="d-flex my-1">
                    <strong className="font-weight-bold">Email: </strong>
                    <strong className="pl-2">{data.email_address}</strong>
                  </div>
                </div>
                <div className="d-flex my-1">
                  <strong className="font-weight-bold">Phone: </strong>
                  <strong className="pl-2">{data.phone_number}</strong>
                </div>
                <div className="d-flex my-1">
                  <strong className="font-weight-bold">Message: </strong>
                  <strong className="pl-2">{data.message}</strong>
                </div>
              </div>

              <AppointmentForm
                initialValues={data}
                closeModal={handleClose}
                fetchAppointments={fetchAppointments}
              />
            </Box>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-properties"
          aria-describedby="transition-modal-properties-description"
          open={openPropertList}
          onClose={handleCloseOpenPropertList}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openPropertList}>
            <Box sx={style}>
              <div>
                <h5 className="text-center mb-2 text-uppercase">
                  Requested Properties For Appointment
                </h5>
                <table className="table table-hover">
                  <thead className="">
                    <tr>
                      <th scope="col">SN</th>
                      <th scope="col">MLS</th>
                      <th scope="col">Address</th>
                      <th scope="col">Price</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {propertyList &&
                      propertyList.map((property, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{property.mls}</td>
                          <td>
                            {property.address}, {property.municipality}
                          </td>
                          <td>$ {property.price}</td>
                          <td>
                            <Stack direction="row" spacing={2}>
                              <div className="border rounded">
                                <Tooltip title="View Details">
                                  <IconButton
                                    color="primary"
                                    aria-label="details"
                                    component="span"
                                    onClick={() =>
                                      history.push(
                                        `/admin/estate/details/${property.property_id}/${property.propertyType}`
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>

                              <div className="border rounded">
                                <Tooltip title="Add To Available List">
                                  <IconButton
                                    color="primary"
                                    aria-label="Add To Available List"
                                    component="span"
                                    onClick={() =>
                                      addToList(property, index + 1)
                                    }
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </Stack>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-properties"
          aria-describedby="transition-modal-properties-description"
          open={appointmentListModal}
          onClose={handleCloseAppointmentListModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={appointmentListModal}>
            <Box sx={styleTwo}>
              <div>
                <h5 className="text-center mb-2 text-uppercase">
                  Available Properties For Appointment
                </h5>
                <table className="table table-hover">
                  <thead className="">
                    <tr>
                      {/*<th scope="col">SN</th>*/}
                      <th scope="col">MLS</th>
                      <th scope="col">Address</th>
                      <th scope="col">Price</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredList.data &&
                      filteredList.data.map((data, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <span className="badge badge-danger">
                                {data.mls}
                              </span>
                            </td>

                            <td>
                              {data.address}, {data.municipality}
                            </td>
                            <td>
                              <span className="badge badge-success">
                                $ {data.price}
                              </span>
                            </td>
                            <td>
                              <Tooltip title="Remove from Available List">
                                <IconButton
                                  color="error"
                                  aria-label="Remove from Available List"
                                  component="span"
                                  onClick={() =>
                                    removePropertFromList(data.property_id)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <TextInput
                                id="list-position"
                                label="List Position"
                                variant="outlined"
                                getValue={(value) =>
                                  changeListPosition(value, index)
                                }
                                value={index + 1}
                                onBlur={""}
                                error={false}
                                errorMsg=""
                                type="number"
                              />
                            </td>
                            <td>
                              <Tooltip title="Select a time">
                                <DatePicker
                                  value={data.availableTime}
                                  getDateAndTime={(dateTime) =>
                                    handleChange(
                                      data.property_id,
                                      dateTime,
                                      data
                                    )
                                  }
                                />
                              </Tooltip>
                            </td>
                            <td>
                              <div className="d-flex">
                                <Rating
                                  name="hover-feedback"
                                  value={data.rating}
                                  precision={0.5}
                                  onChange={(event, newValue) => {
                                    handleRating(
                                      "rating",
                                      data.property_id,
                                      newValue
                                    );
                                  }}
                                  emptyIcon={
                                    <StarIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <TextInput
                                id="Comment-position"
                                label="Comment"
                                variant="outlined"
                                getValue={(value) => {
                                  // console.log(`value => `, value);
                                  handleComment(
                                    "comment",
                                    data.property_id,
                                    value
                                  );
                                }}
                                value={data.comment}
                                onBlur={""}
                                error={false}
                                errorMsg=""
                                type="text"
                              />

                              {/*     <div className="form-group">
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="2"
                                  value={data.comment}
                                  onChange={(e) =>
                                    handleComment(
                                      "comment",
                                      data.property_id,
                                      e.target.value
                                    )
                                  }
                                ></textarea>
                              </div> */}
                            </td>
                          </tr>

                          <tr className="">
                            <td colSpan={4}>
                              <strong>Comments</strong>

                              {data.comments &&
                                data.comments.map((comment, index) => {
                                  if (
                                    comment.comment !== null ||
                                    comment.comment !== ""
                                  ) {
                                    return (
                                      <div key={index} className="w-100">
                                        <div
                                          className="alert alert-primary w-100"
                                          role="alert"
                                        >
                                          <strong>{comment.comment}</strong>,
                                          for {comment.customer_email}{" "}
                                          <strong>On</strong>{" "}
                                          {moment(comment.created_at).format(
                                            "Y-M-D"
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={4}>
                              <hr
                                style={{
                                  borderTop: "1px dashed #c1c1c1",
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div>

              {filteredList.data.length > 0 && (
                <div className="text-right mb-3">
                  <button
                    className="btn btn-success"
                    onClick={() => saveList()}
                  >
                    {!loading ? (
                      " Save to Database"
                    ) : (
                      <div className="px-5">
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </button>
                </div>
              )}

              {filteredList.data.length > 0 && (
                <GoogleMap properties={filteredList.data} />
              )}
            </Box>
          </Fade>
        </Modal>
      </div>
    </RootElement>
  );
};

const mapStateToProps = (state) => {
  return { state: state.appState };
};

const mapDispatchToProps = (dispatch) => ({
  getPropertyList: (data) => {
    dispatch(getPropertyList(data));
  },
  getPropertyLists: (data) => {
    dispatch(getPropertyLists(data));
  },
  savePropertyList: (data) => {
    dispatch(savePropertyList(data));
  },
  removePropertyList: (data) => {
    dispatch(removePropertyList(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
