import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import "../../../assets/css/header.css";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../../assets/img/guna_homes_logo.png";
// import * as Icon from 'react-bootstrap-icons';

const Header = (props) => {
  // console.log("props header", props.state.appState.showings);
  // console.log("page", props.page);
  const history = useHistory();

  return (
    <header>
      <div className="position-absolute w-100">
        {props.page === "home" ? (
          <div className="first_layer p-4 mt-4 mt-md-0 d-none d-md-block">
            <h2 className="p-2"></h2>
          </div>
        ) : (
          <div className="first_layer2 bg-dark p-4 mt-4 mt-md-0 d-none d-md-block">
            <h2 className="p-2"></h2>
          </div>
        )}
        {props.page === "home" ? (
          <div className="second_layer p-3 d-none d-lg-block">
            <h2 className="p-2"></h2>
          </div>
        ) : (
          <div className="second_layer bg_secondary p-3 d-none d-lg-block">
            <h2 className="p-2"></h2>
          </div>
        )}
      </div>

      <div className="d-lg-flex justify-content-end">
        <div
          className="logo_wrapper"
          data-aos="fade-right"
          data-aos-duration="800"
          data-aos-delay="300"
        >
          <div className="logo bg-white d-flex justify-content-lg-center justify-content-md-between justify-content-between  align-items-center ps-lg-0 ps-md-4">
            <Link to="/">
              <Image src={Logo} alt={process.env.REACT_APP_ADDRESS1}/>
            </Link>
            <label for="menu" className="mr-3 h3 mob_menu">
              &#9776;
            </label>
          </div>
        </div>
        <div className="menu_wrapper">
          <div className="d-md-flex justify-content-between pt-lg-4 pb-lg-1">
            <div className="d-flex justify-content-start align-items-center top_menu">
              <nav role="navigation" class="menu">
                <input type="checkbox" id="menu" />
                <ul>
                  <label for="menu" className="mr-3 h3 mob_menu">
                    &#10006;
                  </label>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/buyers">Buy</Link>
                  </li>
                  <li>
                    <Link to="/sellers">Sell</Link>
                  </li>
                  <li>
                    <Link to="/allbrokerage">Our Listing</Link>
                  </li>
                  <li>
                    <Link to="/search">Residential Listing</Link>
                  </li>
                  <li>
                    <Link to="/commerical">Commerical Listing</Link>
                  </li>
                  <li>
                    <Link to="/estate/toronto">Map Search</Link>
                  </li>
                  <li>
                    <Link to="/calculators">Calculators</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact us</Link>
                  </li>

                  {/* Multiple Dropdown */}
                  {/* <li className="menu-hasdropdown">
                    <a href="#">About 
                      <label title="toggle menu" for="about">
                        <i className="fa fa-caret-down"></i>
                      </label>
                    </a>
                    <input type="checkbox" id="about"/>
                    <ul className="menu-dropdown">
                      <li><a href="">History</a></li>
                      <li><a href="">Mission</a></li>
                      <li className="menu-hasdropdown menu-hasflyout">
                        <a href="">Services
                          <label title="toggle menu" for="services">
                            <i className="fa fa-caret-down menu-downicon"></i>
                            <i className="fa fa-caret-right menu-righticon"></i>
                          </label>        
                        </a>
                        <input type="checkbox" id="services"/>
                        <ul className="menu-dropdown">
                          <li><a href="">Service 1</a></li>
                          <li><a href="">Service 2</a></li>
                          <li><a href="">Service 3</a></li>
                        </ul>
                      </li>
                    </ul>
                  </li> */}
                  {/* Multiple Dropdown End */}
                </ul>
              </nav>

              {/* <Link to="/buyers">
                  <p className="text-white px-3 text-uppercase hvr-sweep-to-right">Buy</p>
                </Link>
                <Link to="/sellers">
                  <p className="text-white px-3 text-uppercase hvr-sweep-to-right">Sell</p>
                </Link>
                <Link to="/estate/toronto">
                  <p className="text-white px-3 text-uppercase hvr-sweep-to-right">Property Listing</p>
                </Link>
                {/* <Link to="#">
                  <p className="text-white px-3 text-uppercase hvr-sweep-to-right">MLP Update</p>
                </Link> 
                <Link to="/contact-us">
                  <p className="text-white px-3 text-uppercase hvr-sweep-to-right">Contact us</p>
                </Link> */}
            </div>

            <div className="d-flex justify-content-center sign_menu pe-4 pe-lg-0 mr-md-3">
              <a
                type="button"
                className="ps-md-2 w-100 px-2 showing"
                onClick={() => history.push("/showings")}
              >
                Showings
                <span class="badge badge-danger ml-1">
                  {props.state.appState.showings.length}
                </span>
              </a>
            </div>
          </div>

          <div className="pt-lg-4 mt-md-2 pt-2 pt-md-0">
            <p className="text-white">
              <marquee
                behavior="scroll"
                direction="left"
                scrollamount="3"
                onmouseover="this.stop()"
                onmouseout="this.start()"
              >
                <span className="bg_primary text-white mr-2 py-1 px-2">
                  Buy Your Dream Home
                </span>
                Are you looking to Buy or sell a home? Please Contact {process.env.REACT_APP_PHONE_NUMBER}
              </marquee>
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(Header);
