import React from "react";

export default function ImageParagraph({ src, paragraph }) {
	return (
		<div
			className="image-paragraph"
			style={{ marginTop: 50, marginBottom: 50 }}
		>
			<table align="center">
				<tr>
					<td>
						<img src={src} width="250" />
					</td>
					<td style={{ verticalAlign: "middle" }}>
						<p style={{ color: "#464646", textAlign: "center" }}>
							{paragraph}
						</p>
					</td>
				</tr>
			</table>
		</div>
	);
}
