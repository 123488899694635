import React, { useEffect, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

import Swal from "sweetalert2";
import BlogModel from "../../../Models/BlogModel";

import RootElement from "../components/RootElement";
// import RichTextEditor from "../../../componets/blog/RichTextEditor";
import SunEditor from "../../../componets/blog/SunEditor";
import CategoryForm from "../components/forms/CategoryForm";
import { connect } from "react-redux";
import { saveBlogCategories } from "../../../store/actions/App/AppActions";
import BlogCategoryMenu from "../components/BlogCategoryMenu";
import BlogPostMenu from "../components/BlogPostMenu";
import BlogCategoryTable from "../components/tables/BlogCategoryTable";

function showMessage(type, message) {
  Swal.fire({
    position: "top-end",
    html: `<p class='text-left text-${type}'>` + `${message}` + `</p>`,
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
  });
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 600,
  bgcolor: "background.paper",
  border: "1px solid #dedede",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const generalModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 1000,
  // height: 600,
  bgcolor: "background.paper",
  border: "1px solid #dedede",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const BlogsPage = (props) => {
  console.log(`props.state => `, props.state);
  const [loading, setLoading] = useState(false);
  const [newBlog, setNewBlog] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [title, setTitle] = useState({
    error: false,
    title: "",
  });
  const [excerpt, setExcerpt] = useState({
    error: false,
    excerpt: "",
  });

  const [blog, setBlog] = useState({
    error: false,
    title: "",
  });

  const [category, setCategory] = useState({
    error: false,
    category: "Other",
    image: "",
  });

  const [content, setContent] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setFeaturedImage({ src: "", data: "" });
    setExcerpt({ excerpt: "", error: false });
    setContent("");
    setCategory({ category: "", error: false, image: "" });
    setTitle({ error: false, title: "" });
    setOpen(false);
  };

  const [creating, setCreating] = useState(false);

  async function createPost() {
    if (!title.title || !content || !excerpt.excerpt) {
      if (!title.title) setTitle({ ...title, error: true });
      if (!content) setBlog({ ...blog, error: true });
      if (!excerpt.excerpt) setExcerpt({ ...excerpt, error: true });
      // if (!category.category) setCategory({ ...category, error: true });
      return;
    }

    setCreating(true);

    const data = {
      title: title.title,
      category: category.category,
      excerpt: excerpt.excerpt,
      content,
      data: featuredImage.data,
      active: true,
    };

    try {
      await BlogModel.create(data);

      setTimeout(function () {
        fetchBlogs();
        Swal.fire("Success", "Blog post created", "success");
        setCreating(false);
        handleClose();
        setContent("");
        setFeaturedImage({ src: "", data: "" });
        setCategory({ category: "", error: false, image: "", image: "" });
        setTitle({ error: false, title: "" });
      }, 2500);
    } catch (error) {
      console.log("error ", error);
      setCreating(false);
    }
  }

  function updatePost(payload) {
    // console.log("updatePost ", payload);
    setContent(payload);
  }

  async function fetchBlogs() {
    setLoading(true);
    try {
      const res = await BlogModel.blogs();
      // console.log("res", res);
      setBlogs(res.data);
      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      setLoading(false);
    }
  }

  async function trashed() {
    setLoading(true);
    try {
      const res = await BlogModel.trashed();
      // console.log("res", res);
      setBlogs(res.data);
      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      setLoading(false);
    }
  }

  const getAllCategories = async () => {
    const res = await BlogModel.getAllCategories();
    props.saveBlogCategories(res.data);
  };

  useEffect(() => {
    getAllCategories();
    fetchBlogs();

    return () => {
      fetchBlogs();
    };
  }, []);

  const [blogId, setBlogId] = useState(null);

  const handleEdit = async (index) => {
    // console.log("handleEdit ", index);
    const data = blogs[index];

    // console.log("data", data);

    setTitle({ error: false, title: data.title });
    setCategory({ error: false, category: data.category, image: "" });
    setExcerpt({ error: false, excerpt: data.excerpt });
    setFeaturedImage({ src: data.featured_image_url, data: "" });
    setNewBlog(false);
    setBlogId(data.id);
    setContent(data.content);

    handleOpen();
  };

  const handleEditUpdate = async () => {
    if (!title.title || !content || !excerpt.excerpt) {
      if (!title.title) setTitle({ ...title, error: true });
      if (!content) setBlog({ ...blog, error: true });
      if (!excerpt.excerpt) setExcerpt({ ...excerpt, error: true });
      return;
    }

    setCreating(true);

    const data = {
      title: title.title,
      category: category.category,
      excerpt: excerpt.excerpt,
      data: featuredImage.data,
      content,
    };

    try {
      await BlogModel.update(blogId, data);

      setNewBlog(true);
      setBlogId(null);
      setContent("");
      setCreating(false);
      Swal.fire("Success", "Blog post updated", "success");
      setTimeout(function () {
        fetchBlogs();
        handleClose();
        setFeaturedImage({ src: "", data: "" });
        setTitle({ error: false, title: "" });
      }, 1500);
    } catch (error) {
      console.log("error ", error);
      setCreating(false);
    }
  };

  const handleDelete = async (index) => {
    const data = {
      id: blogs[index].id,
    };

    try {
      // check if it is active or not
      // then delete

      if (blogs[index].active) {
        await confirmMsg(
          "This is an active post. If you delete there will be no post in home screen. Would you like to proceed?"
        );
      }

      await confirmMsg("Confirm! you want to trash this post?");

      setLoading(true);

      await BlogModel.delete(data);

      setNewBlog(true);
      setBlogId(null);
      setContent("");
      setCreating(false);
      setTimeout(function () {
        fetchBlogs();
        Swal.fire("Success", "Blog deleted", "success");
        handleClose();
      }, 2500);
    } catch (error) {
      console.log("error ", error);
      setCreating(false);
      setLoading(false);
    }
  };

  const confirmMsg = async (text) => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "Are you sure?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          resolve();
        }
      });
    });
  };

  async function handleActive(id) {
    try {
      setLoading(true);

      await confirmMsg("Do you want to change the active status?");
      await BlogModel.changeStatus(id);
      await fetchBlogs();

      Swal.fire("Success", "Status updated.", "success");

      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      setLoading(false);
    }
  }

  const [featuredImage, setFeaturedImage] = useState({ src: "", data: "" });

  async function handleFileChange(e) {
    const files = e.target.files;

    const imageBase64 = await convert(files[0]);

    const data = {
      src: URL.createObjectURL(files[0]),
      data: imageBase64,
    };

    setFeaturedImage(data);
  }

  const convert = (img) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onloadend = function () {
        // console.log("RESULT", reader.result);
        resolve(reader.result);
      };
      reader.readAsDataURL(img);
    });
  };

  const [trashedBlogs, setTrashedBlogs] = useState(false);

  const handleFetchButtons = async (type) => {
    if (type) {
      await fetchBlogs();
      setTrashedBlogs(false);

      return;
    }

    setTrashedBlogs(true);

    await trashed();
  };

  async function handleRestore(id) {
    try {
      setLoading(true);

      await confirmMsg("Would you like to restore?");
      await BlogModel.restore(id);
      await handleFetchButtons(false);

      Swal.fire("Success", "Post successfully restored", "success");

      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      setLoading(false);
    }
  }

  async function permenantDelete(id) {
    try {
      const data = {
        id: id,
      };

      setLoading(true);

      await confirmMsg("Would you like to permenantly delete the post?");
      await BlogModel.permenantDelete(data);
      await handleFetchButtons(false);

      Swal.fire("Success", "Post successfully deleted", "success");

      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      setLoading(false);
    }
  }

  const [generalModal, setGeneralModal] = useState(false);
  const [showForm, setShowForm] = useState("");

  const openGeneralModal = (form) => {
    setShowForm(form);
    setGeneralModal(true);
  };
  const closeGeneralModal = () => setGeneralModal(false);

  const handleCategoryForm = async (formData, isEdit) => {
    try {
      // console.log(`formData => `, formData);
      setLoading(true);

      if (!isEdit) {
        await BlogModel.createCategory(formData);
        showMessage("success", "New category has been created.");
      } else {
        const payload = {
          oldData: category.category,
          newData: formData.category,
          image: formData.image,
        };
        // update
        await BlogModel.updateCategory(payload);
        showMessage("success", "Category has been updated.");
      }

      closeGeneralModal();
      setLoading(false);
      getAllCategories();
    } catch (error) {
      setLoading(false);
      // console.log(`error => `, error);
      showMessage("danger", error.data);
      closeGeneralModal();
    }
  };

  const deleteCategory = async (category) => {
    // closeGeneralModal();
    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          deleteAllPosts: "Delete all posts belongs to this category",
          changePosts: "Change post category to 'Other'",
        });
      }, 100);
    });

    const res = await Swal.fire({
      title: `Are you sure? You want to delete`,
      text: "Please select the cancel option",
      input: "radio",
      inputOptions: inputOptions,
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
      },
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: `Cancel`,
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#EB1D36",
      width: "45rem",
    });

    if (res.isConfirmed) {
      closeGeneralModal();

      const payload = {
        category: category.category,
        changePosts: res.value === "changePosts" ? true : false,
        deleteAllPosts: res.value === "deleteAllPosts" ? true : false,
      };

      try {
        await BlogModel.deleteCategory(payload);
        setLoading(false);
        getAllCategories();
        closeGeneralModal();
        showMessage("success", "Category has been deleted!");
      } catch (error) {
        console.log(`error => `, error);
        showMessage("danger", "Something went wrong.");
      }
    }
  };

  return (
    <RootElement active="blogs">
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <div className="my-5">
        <div className="d-flex justify-content-between align-items-center my-3">
          <h5>Current Blogs</h5>

          <div>
            <Stack spacing={1} direction="row">
              <BlogCategoryMenu
                handleClick={(type) => openGeneralModal(type)}
              />

              <BlogPostMenu
                fetchPosts={(type) => handleFetchButtons(type)}
                createPost={handleOpen}
              />
            </Stack>
          </div>
        </div>

        {!trashedBlogs ? (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">SN</th>
                <th scope="col">Image</th>
                <th scope="col">Title</th>
                <th scope="col">Created</th>
                <th scope="col">Updated</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {blogs &&
                blogs.map((blog, index) => (
                  <tr>
                    <th scope="row" key={index}>
                      {index + 1}
                    </th>
                    <td>
                      <img src={blog.featured_image_url} width={50} alt="" />
                    </td>
                    <td>{blog.title}</td>

                    <td>{blog.created_at}</td>
                    <td>{blog.updated_at}</td>
                    <td>
                      <Stack direction="row" spacing={2}>
                        <div className="border rounded">
                          <Tooltip title="Change active status">
                            <IconButton
                              color="primary"
                              aria-label="details"
                              component="span"
                              onClick={() => handleActive(blog.id)}
                            >
                              {blog.active ? (
                                <ToggleOnIcon color="success" />
                              ) : (
                                <ToggleOffIcon color="disabled" />
                              )}
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className="border rounded">
                          <Tooltip title="Edit post">
                            <IconButton
                              color="primary"
                              aria-label="details"
                              component="span"
                              onClick={() => handleEdit(index)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className="border rounded">
                          <Tooltip title="Trash post">
                            <IconButton
                              color="error"
                              aria-label="details"
                              component="span"
                              onClick={() => handleDelete(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Stack>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">SN</th>
                <th scope="col">Image</th>
                <th scope="col">Title</th>
                <th scope="col">Created</th>
                <th scope="col">Updated</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {blogs &&
                blogs.map((blog, index) => (
                  <tr>
                    <th scope="row" key={index}>
                      {index + 1}
                    </th>
                    <td>
                      <img src={blog.featured_image_url} width={50} alt="" />
                    </td>
                    <td>{blog.title}</td>

                    <td>{blog.created_at}</td>
                    <td>{blog.updated_at}</td>
                    <td>
                      <Stack direction="row" spacing={2}>
                        {/*<div className="border rounded">
                          <Tooltip title="Change active status">
                            <IconButton
                              color="primary"
                              aria-label="details"
                              component="span"
                              onClick={() => handleActive(blog.id)}
                              disabled={blog.active}
                            >
                              {blog.active ? (
                                <ToggleOnIcon />
                              ) : (
                                <ToggleOffIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className="border rounded">
                          <Tooltip title="Edit post">
                            <IconButton
                              color="primary"
                              aria-label="details"
                              component="span"
                              onClick={() => handleEdit(index)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </div>*/}

                        <div className="border rounded">
                          <Tooltip title="Restore the post">
                            <IconButton
                              color="success"
                              aria-label="details"
                              component="span"
                              onClick={() => handleRestore(blog.id)}
                            >
                              <RotateLeftIcon />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className="border rounded">
                          <Tooltip title="Delete permenantly">
                            <IconButton
                              color="error"
                              aria-label="details"
                              component="span"
                              onClick={() => permenantDelete(blog.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Stack>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>

      <div className="container my-5">
        {/*<div>
          <RichTextEditor createPost={(data) => createPost(data)} />
          <SunEditor updatePost={(data) => updatePost(data)} />
        </div>*/}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="blog-post">
              <div className="d-flex flex-row-reverse mb-3">
                {newBlog ? (
                  <button
                    type={"submit"}
                    className="btn bg_secondary d-flex align-items-center"
                    onClick={() => createPost()}
                    disabled={creating}
                  >
                    <p className="text-white mr-2">
                      {creating ? "Please wait. . ." : "CREATE"}
                    </p>
                    {creating && (
                      <div className="">
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </button>
                ) : (
                  <button
                    type={"submit"}
                    className="btn bg_secondary d-flex align-items-center"
                    onClick={() => handleEditUpdate()}
                    disabled={creating}
                  >
                    <p className="text-white mr-2">
                      {creating ? "Please wait. . ." : "UPDATE"}
                    </p>
                    {creating && (
                      <div className="">
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </button>
                )}
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label for="blogTitle">Post Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="blogTitle"
                      aria-describedby="emailHelp"
                      value={title.title}
                      onChange={(e) =>
                        setTitle({ error: false, title: e.target.value })
                      }
                    />
                    {title.error && (
                      <small id="emailHelp" className="form-text text-danger">
                        Please enter a blog title.
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <label for="category">Post Category</label>
                  <select
                    class="custom-select"
                    onChange={(e) =>
                      setCategory({
                        ...category,
                        error: false,
                        category: e.target.value,
                      })
                    }
                  >
                    <option value="">Please select a category</option>

                    {props.state.blogCategories &&
                      props.state.blogCategories.map((blogCategory, index) => (
                        <option
                          key={index}
                          value={blogCategory.category}
                          selected={
                            category.category === blogCategory.category
                              ? true
                              : false
                          }
                        >
                          {blogCategory.category}
                        </option>
                      ))}
                    <option
                      value="Other"
                      selected={category.category === "Other" ? true : false}
                    >
                      Other
                    </option>
                  </select>
                  {category.error && (
                    <small id="category" className="form-text text-danger">
                      Please select a category
                    </small>
                  )}
                </div>
              </div>

              <label>Featured Image</label>
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="inputGroupFile01"
                        aria-describedby="inputGroupFileAddon01"
                        onChange={(e) => handleFileChange(e)}
                      />
                      <label
                        className="custom-file-label"
                        for="inputGroupFile01"
                      >
                        Select an Image
                      </label>
                    </div>
                  </div>

                  <img
                    src={featuredImage.src}
                    alt=""
                    style={{ width: "100%" }}
                    className="test"
                  />
                </div>
              </div>

              <div className="form-group">
                <label for="blogExcerpt">Excerpt Text</label>
                <textarea
                  rows="3"
                  className="form-control col-md-8 mt-2"
                  id="blogExcerpt"
                  aria-describedby="blogExcerpt"
                  value={excerpt.excerpt}
                  onChange={(e) =>
                    setExcerpt({ error: false, excerpt: e.target.value })
                  }
                />
                {excerpt.error && (
                  <small id="blogExcerpt" className="form-text text-danger">
                    Please enter excerpt text.
                  </small>
                )}
              </div>

              {/* <p>Content</p> */}
              <div className="mt-3">
                <SunEditor
                  newBlog={newBlog}
                  updatePost={(data) => updatePost(data)}
                  defaultValue={content}
                />
              </div>

              {blog.error && (
                <small id="emailHelp" className="form-text text-danger">
                  Blog content is empty.
                </small>
              )}
            </div>
          </Box>
        </Modal>

        <Modal
          open={generalModal}
          onClose={closeGeneralModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...generalModalStyle,
              height: showForm === "manage-category" ? "80%" : "auto",
            }}
          >
            {showForm === "create-category" && (
              <CategoryForm
                isEdit={false}
                initialValues={""}
                handleSubmit={handleCategoryForm}
                loading={loading}
              />
            )}

            {showForm === "edit-category" && (
              <CategoryForm
                isEdit={true}
                initialValues={{
                  category: category.category,
                  image: category.image,
                }}
                handleSubmit={handleCategoryForm}
                loading={loading}
              />
            )}

            {showForm === "manage-category" && (
              <BlogCategoryTable
                categories={props.state.blogCategories}
                setCategory={(data) => {
                  setCategory({
                    ...category,
                    category: data.category,
                    image: data.image,
                    error: false,
                  });
                  closeGeneralModal();
                  openGeneralModal("edit-category");
                }}
                deleteCategory={(category) => deleteCategory(category)}
              />
            )}
          </Box>
        </Modal>
      </div>
    </RootElement>
  );
};

const mapStateToProps = (state) => {
  return { state: state.appState };
};

const mapDispatchToProps = (dispatch) => ({
  saveBlogCategories: (data) => {
    dispatch(saveBlogCategories(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogsPage);
