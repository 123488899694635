import React from "react";

import "./css/SettingsCard.css";

function SettingsCard(props) {
  return (
    <div class="card shadow">
      <div class="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h5 class="card-title">{props.title}</h5>
          <div>
            <button
              className="btn btn-sm btn-success"
              onClick={() => props.btnClick()}
            >
              Add
            </button>
          </div>
        </div>

        <div>{props.children}</div>
      </div>
    </div>
  );
}

export default SettingsCard;
