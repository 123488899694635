import React from "react";

export default function Image({ one, two }) {
	return (
		<div className="images" style={{ marginTop: 50, marginBottom: 50 }}>
			<table align="center">
				<tr>
					<td>
						<div style={{ marginLeft: 20, marginRight: 20 }}>
							<img
								src={one}
								width="150"
							/>
						</div>
					</td>
					<td>
						<div style={{ marginLeft: 20, marginRight: 20 }}>
							<img
								src={two}
								width="150"
							/>
						</div>
					</td>
				</tr>
			</table>
		</div>
	);
}
