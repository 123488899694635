import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";

export default function EnquiryForm({ groups, oncreate }) {
  const formik = useFormik({
    initialValues: {
      full_name: get("", "full_name", ""),
      email: get("", "email", ""),
      phone_number: get("", "phone_number", ""),
      group_type: get("None", "group_type", "None"),
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      oncreate(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div class="form-group">
        <label for="full-name">Full Name</label>
        <input
          type="text"
          class="form-control"
          id="full-name"
          aria-describedby="subjectHelp"
          value={formik.values.full_name}
          onChange={(value) =>
            formik.setFieldValue("full_name", value.target.value)
          }
          onBlur={() => formik.setFieldTouched("full_name")}
        />
        {Boolean(formik.errors.full_name) && formik.touched.full_name ? (
          <small
            id="subjectError"
            class={
              "form-text " + Boolean(formik.errors.full_name)
                ? "text-danger"
                : "text-muted"
            }
          >
            {formik.errors.full_name}
          </small>
        ) : (
          ""
        )}
      </div>
      <div class="form-group">
        <label for="email-address">Email Address</label>
        <input
          type="text"
          class="form-control"
          id="email-address"
          value={formik.values.email}
          onChange={(value) =>
            formik.setFieldValue("email", value.target.value)
          }
          onBlur={() => formik.setFieldTouched("email")}
        />
        {Boolean(formik.errors.email) && formik.touched.email ? (
          <small
            id="email-address-error"
            class={
              "form-text " + Boolean(formik.errors.email)
                ? "text-danger"
                : "text-muted"
            }
          >
            {formik.errors.email}
          </small>
        ) : (
          ""
        )}
      </div>

      <div class="form-group">
        <label for="phone-number">Phone Number</label>
        <input
          type="text"
          class="form-control"
          id="phone-number"
          value={formik.values.phone_number}
          onChange={(value) =>
            formik.setFieldValue("phone_number", value.target.value)
          }
          onBlur={() => formik.setFieldTouched("phone_number")}
        />
        {Boolean(formik.errors.phone_number) && formik.touched.phone_number ? (
          <small
            id="phone-number-error"
            class={
              "form-text " + Boolean(formik.errors.phone_number)
                ? "text-danger"
                : "text-muted"
            }
          >
            {formik.errors.phone_number}
          </small>
        ) : (
          ""
        )}
      </div>

      <div class="form-group">
        <label for="phone-number" className="mb-1">
          Select a group type
        </label>

        <div class="dropdown">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {formik.values.group_type === ""
              ? "Select a type"
              : formik.values.group_type}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {groups &&
              groups.map((group, index) => (
                <a
                  key={index}
                  className="dropdown-item"
                  href="#"
                  onClick={(e) =>
                    formik.setFieldValue("group_type", e.target.text)
                  }
                >
                  {group.group_type}
                </a>
              ))}
          </div>
        </div>
      </div>

      <div className="text-right">
        <button type="submit" class="btn btn-primary">
          Create
        </button>
      </div>
    </form>
  );
}
