import {
  SET_DATA,
  SAVE_PROPERTY_DETAILS,
} from "./../../actions/Properties/PropertiesActionsTypes";

const initialState = {
  properties: [],
  propertyDetails: {},
};
const PropertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        properties: action.payload,
      };
    case SAVE_PROPERTY_DETAILS:
      return {
        ...state,
        propertyDetails: action.payload,
      };
    default: {
      return state;
    }
  }
};
export default PropertiesReducer;
