import React from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

function RelatedProperty({ property }) {
  return (
    <Link
      to={`/estate/details/${property.property_id}/${property.property_type}`}
    >
      <div className="item">
        <img
          src={`${process.env.REACT_APP_IMG_SOURCE}/${property.url}`}
          className="w-100 img"
        />
        <div class="bg-white px-2 text-dark pt-1 d-flex justify-content-between">
          <h6 className="fw_bold p-0 m-0"><NumberFormat value={property.Orig_dol} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h6>
          <h6 className="p-0 m-0">➞</h6>
        </div>
        <p class="title bg-white px-2 text-truncate vm_fs_15 vm_lh_18 pt-2 pb-1">{property.Addr}</p>
        <p class="bg-white px-2 vm_fs_15 pb-2 vm_lh_18">
          Bed {property.bed_rooms} &nbsp; | &nbsp; Bath {property.bath_rooms}{" "} | &nbsp; Parking {property.Park_spcs}{" "}
        </p>
        <p class="bg-white px-2 vm_fs_15 pb-2 vm_lh_18">MLS® {property.Ml_num}</p>
      </div>
    </Link>
  );
}

export default RelatedProperty;
