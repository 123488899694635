import React, { useState } from "react";
 //import { useHistory } from "react-router";
import AuthModel from "../models/AuthModel";

import LoadingButton from "../../../componets/LoadingButton";

const Login = () => {
 //const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ error: false, msg: "" });
  const [data, setData] = useState({ email: "", password: "" });

  const login = async () => {
    if (data.email === "" || data.password === "") {
      setError({ error: true, msg: "email and password is required" });
      return;
    }

    setLoading(true);

    try {
      const RES = await AuthModel.login(data);
      //console.log(RES);
      localStorage.setItem("auth", JSON.stringify(RES.data));

      setLoading(false);

      //history.push("/admin/home");
      window.location.href = "/admin/home";
    } catch (error) {
      //console.log("Error => ", error);

      if (!error.success) {
        // console.log("false");
        //console.log(`error => `, error);
        setError({ error: true, msg: "Invalid credentials." });
      }

      setLoading(false);
    }

    /*setTimeout(function () {
      localStorage.setItem("auth", true);
      setLoading(false);
      window.location.reload();
    }, 2500);*/
  };

  return (
    <>
      <div
        className="d-flex justify-content-center login-wrapper"
        style={{ marginTop: "10%" }}
      >
        <form className="form-signin">
          <div className="text-center">
            <img
              className="mb-4"
              src="/img/guna_homes_logo.png"
              alt=""
              width="250"
            />
          </div>
          {/*<h1 className="h3 mb-3 font-weight-normal text-center">
						Sign in
					</h1>*/}

          <div>
            <label for="inputEmail">Email</label>
            <input
              type="email"
              id="inputEmail"
              className="form-control"
              placeholder="Email address"
              required
              autofocus
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <p
              className="text-danger font-weight-bold"
              style={{ fontSize: 10 }}
            >
              {error.error && error.msg}
            </p>
          </div>

          <div className="my-3">
            <label for="inputPassword">Password</label>
            <input
              type="password"
              id="inputPassword"
              className="form-control"
              placeholder="Password"
              required
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
          </div>

          <LoadingButton
            title="Sign In"
            style="danger"
            loading={loading}
            type="submit"
            handleClick={() => login()}
          />
        </form>
      </div>
    </>
  );
};

export default Login;
