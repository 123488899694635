import axios from "axios";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API}/api`,
});

// Add a request interceptor
API.interceptors.request.use(
  (config) => {
    const storage = JSON.parse(localStorage.getItem("auth"));
    const token = storage === null ? "" : storage.access_token;
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log('Axios Erro => ', error);

    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    return API.post(`auth/refresh`, null)
      .then((response) => {
        let storage = JSON.parse(localStorage.getItem("auth"));

        storage = {
          ...storage,
          access_token: response.data.data.access_token,
        };

        localStorage.setItem("auth", JSON.stringify(storage));

        error.response.config.headers["Authorization"] =
          "Bearer " + response.data.data.access_token;

        return axios(error.response.config);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
);

export default API;
