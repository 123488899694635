import HTTP from "../common/ajax";

class EmailListModel {
  async email(id) {
    try {
      const res = await HTTP.get("admin/email-list/" + id);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async emails() {
    try {
      const res = await HTTP.get("admin/email-list");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async create(payload) {
    try {
      await HTTP.post("admin/email-list", payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async update(id, payload) {
    try {
      await HTTP.post("admin/email-list/" + id, payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async deleteEntry(id) {
    try {
      await HTTP.get("admin/email-list/delete/" + id);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async sync() {
    try {
      const res = await HTTP.get("admin/email-list/sync");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async getGroup(id) {
    try {
      const res = await HTTP.get("admin/email-list/group/" + id);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async getGroups() {
    try {
      const res = await HTTP.get("admin/email-list/group");
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async newGroup(payload) {
    try {
      await HTTP.post("admin/email-list/group", payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async changeGroup(payload) {
    try {
      await HTTP.post("admin/email-list/group/change", payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async deleteGroup(id) {
    try {
      await HTTP.get("admin/email-list/group/delete/" + id);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async sendEmail(payload) {
    try {
      await HTTP.post("admin/email-list/email/send", payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async importCsv(payload) {
    try {
      const res = await HTTP.post("admin/email-list/email/import", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async getGroupEmails(type) {
    try {
      const res = await HTTP.get("admin/email-list/group/emails/" + type);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async sendBulkEmail(payload) {
    try {
      await HTTP.post("admin/email-list/email/bulk/send", payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async updateEntry(payload) {
    try {
      await HTTP.post(
        "admin/email-list/email/update/entry",
        payload
      );
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async uploadEmailPhoto(payload) {
    try {
      const res = await HTTP.post(
        "admin/email-list/email/send/upload",
        payload
      );
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async sendTestEmail(payload) {
    try {
      const res = await HTTP.post("admin/email-list/email/send/test", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async convert(img) {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onloadend = function () {
        // console.log("RESULT", reader.result);
        resolve(reader.result);
      };
      reader.readAsDataURL(img);
    });
  }
}

export default new EmailListModel();
