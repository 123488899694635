import HTTP from "../../../common/ajax";

class PropertyModel {
  async fetchProperties(type) {
    try {
      const res = await HTTP.get("/admin/properties/" + type);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async addToOwnProperties(payload) {
    try {
      const res = await HTTP.post("/admin/properties/own", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async addToOwnListings(payload) {
    try {
      const res = await HTTP.post("/admin/properties/add/own-listings", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async deleteProperty(payload) {
    try {
      const res = await HTTP.post(`/admin/properties/delete`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async uploadImages(payload) {
    try {
      const res = await HTTP.post(`admin/properties/image/upload`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async deleteImages(payload) {
    try {
      const res = await HTTP.post(`admin/properties/image/delete`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }
}

export default new PropertyModel();
