import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import Swal from "sweetalert2";
import moment from "moment";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import TextInput from "../inputs/TextInput";
import SelectInput from "../inputs/SelectInput";
import Divider from "@mui/material/Divider";
import Switch from "../switch/Switch";

import PropertyModel from "../../../../Models/PropertyModel";

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	// textAlign: "center",
	color: theme.palette.text.secondary,
	boxShadow: "unset",
}));

const inputStyle = {
	border: "1px solid #ccc",
	padding: 10,
	marginBottom: 10,
	borderRadius: 5,
	transition: "opacity 1s ease-in",
};

const INP = "";

export default function EditForm(props) {
	console.log("Props ", props.initialValues);
	const initialValues = props.initialValues;
	const PD = props.initialValues.propert_details[0];

	console.log("PD", PD);

	const [loading, setLoading] = useState(false);
	const [bedroomDetails, setBedroomDetails] = useState([]);
	const [bathroomDetails, setBathroomDetails] = useState([]);
	const [kitchenDetails, setKitchenDetails] = useState([]);
	const [roomDetails, setRoomDetails] = useState([]);
	const [errors, setErrors] = useState({ municipality: false, type: false });
	const [images, setImages] = useState([]);
	const [rawImageFiles, setRawImageFiles] = useState(null);

	function updateDynamicVales({ stateArray, roomType, index, type, value }) {
		// this data only need for first time
		let data = {
			index,
			width: type === "width" ? value : "",
			length: type === "length" ? value : "",
			size: type === "size" ? value : "",
		};

		// Get a copy of state array
		const values = [...stateArray];

		// if found = true, user is already entered something
		// Just update the necessary fields
		const found = values.find((value) => value.index === index);

		if (!found) {
			values.push(data);
			switch (roomType) {
				case "bedroom":
					setBedroomDetails(values);
					break;

				case "bathroom":
					setBathroomDetails(values);
					break;

				case "kitchen":
					setKitchenDetails(values);
					break;

				case "room":
					setRoomDetails(values);
					break;

				default:
					break;
			}
			return;
		}

		if (type === "width") {
			values[index].width = value;
		} else if (type === "length") {
			values[index].length = value;
		} else if (type === "size") {
			values[index].size = value;
		}

		switch (roomType) {
			case "bedroom":
				setBedroomDetails(values);
				break;

			case "bathroom":
				setBathroomDetails(values);
				break;

			case "kitchen":
				setKitchenDetails(values);
				break;

			case "room":
				setRoomDetails(values);
				break;

			default:
				break;
		}
	}

	const handleDynamicInput = (payload) => {
		let stateArray = [];

		switch (payload.roomType) {
			case "bedroom":
				stateArray = bedroomDetails;
				updateDynamicVales({ stateArray, ...payload });
				break;

			case "bathroom":
				stateArray = bathroomDetails;
				updateDynamicVales({ stateArray, ...payload });
				break;

			case "kitchen":
				stateArray = kitchenDetails;
				updateDynamicVales({ stateArray, ...payload });
				break;

			case "room":
				stateArray = roomDetails;
				updateDynamicVales({ stateArray, ...payload });
				break;

			default:
				break;
		}
	};

	const updateProperty = async (payload, actionType) => {
		// console.log("here?");
		try {
			const res = await PropertyModel.createNewProperty({
				...payload,
				actionType,
			});

			props.fetchProperties();

			setLoading(false);
			props.closeModal();

			Swal.fire(
				`Successfully ${actionType}`,
				`Property ${actionType}`,
				"success"
			);
		} catch (error) {
			console.log("Error => ", error);
			setLoading(false);

			props.closeModal();

			Swal.fire(
				`Failed`,
				"Something went wrong. Please try again",
				"error"
			);
		}
	};

	const showMessage = (payload) => {
		console.log("???");
	};

	const handleFileChange = async (e) => {
		console.log(e.target.files);
		const files = e.target.files;

		setRawImageFiles(files);

		const _images = images;

		for (var i = files.length - 1; i >= 0; i--) {
			const imageBase64 = await convert(files[i]);

			const data = {
				name: files[i].name,
				src: URL.createObjectURL(files[i]),
				type: files[i].type,
				data: imageBase64,
			};

			_images.push(data);
		}

		setImages(_images);

		// console.log("files", files);
		// console.log("images", images);

		// addNewProperty({ images: _images }, "added");
	};

	const convert = (img) => {
		return new Promise((resolve) => {
			var reader = new FileReader();
			reader.onloadend = function () {
				console.log("RESULT", reader.result);
				resolve(reader.result);
			};
			reader.readAsDataURL(img);
		});
	};

	const formik = useFormik({
		initialValues: {
			Ml_num: get(initialValues.Ml_num, "Ml_num", initialValues.Ml_num),
			Municipality: get(
				initialValues.Municipality,
				"Municipality",
				initialValues.Municipality
			),
			area: get(initialValues.Area, "area", initialValues.Area),
			approx_age: get(PD.Yr_built, "approx_age", PD.Yr_built),
			S_r: get(initialValues.S_r, "S_r", initialValues.S_r),
			property_type: get(
				initialValues.property_type,
				"property_type",
				initialValues.property_type
			),
			property_name: get(PD.Addr, "property_name", PD.Addr),
			property_address: get(
				initialValues.Addr,
				"property_address",
				initialValues.Addr
			),
			property_description: get(
				initialValues.Ad_text,
				"property_description",
				initialValues.Ad_text
			),
			property_style: get(PD.Style, "property_style", PD.Style),
			bedrooms: get(PD.Br, "bedrooms", PD.Br),
			bathrooms: get(PD.Bath_tot, "bathrooms", PD.Bath_tot),
			kitchens: get(PD.Num_kit, "kitchens", PD.Num_kit),
			rooms: get(PD.Rms, "rooms", PD.Rms),
			square_feet: get(PD.Sqft, "square_feet", PD.Sqft),
			walk_score: get(PD.Walk_score, "walk_score", PD.Walk_score),
			upcoming_open_house: get(
				PD.upcoming_open_house,
				"upcoming_open_house",
				PD.upcoming_open_house
			),
			// Price details
			property_price: get(
				initialValues.Orig_dol,
				"property_price",
				initialValues.Orig_dol
			),
			tax: get(PD.Taxes, "tax", PD.Taxes),
			tax_year: get(PD.Tax_year, "tax_year", PD.Tax_year),

			// room details
			air_conditioning: get(PD.A_c, "air_conditioning", PD.A_c),
			fireplaces: get(PD.fireplaces, "fireplaces", PD.fireplaces),
			den_or_family_room: get(PD.Den_fr, "den_or_family_room", PD.Den_fr),
			// building details
			basement: get(PD.Bsmt1_out, "basement", PD.Bsmt1_out),
			basement_two: get(PD.Bsmt2_out, "basement_two", PD.Bsmt2_out),
			heat_source: get(PD.Heat_inc, "heat_source", PD.Heat_inc),
			heat_type: get(PD.Heating, "heat_type", PD.Heating),
			exterior: get(PD.Constr1_out, "exterior", PD.Constr1_out),
			seperate_entrance: get(PD.Pvt_ent, "seperate_entrance", PD.Pvt_ent),
			// parking details
			garages: get(PD.Gar_spaces, "garages", PD.Gar_spaces),
			garage_type: get(PD.Gar_type, "garage_type", PD.Gar_type),
			parking_space: get(PD.Park_spcs, "parking_space", PD.Park_spcs),
			// parking_total: get("", "parking_total", ""),
			// land details
			fronting_on: get(PD.fronting_on, "fronting_on", PD.fronting_on),
			frontage: get(PD.frontage, "frontage", PD.frontage),
			lot_dimensions: get(
				PD.lot_dimensions,
				"lot_dimensions",
				PD.lot_dimensions
			),
			pool: get(PD.Pool, "pool", PD.Pool),
			sewers: get(PD.Sewer, "sewers", PD.Sewer),
			Water: get(PD.Water, "Water", PD.Water),
			Waterfront: get(PD.Waterfront, "Waterfront", PD.Waterfront),
			Status: get(initialValues.Status, "Status", initialValues.Status),
		},
		validationSchema: Yup.object({
			Ml_num: Yup.string().required("Required"),
			area: Yup.string().required("Required"),
			// Municipality: Yup.string().required("Required"),
			property_name: Yup.string().required("Required"),
			property_address: Yup.string().required("Required"),
			property_description: Yup.string().required("Required"),
			// property_type: Yup.string().required("Required"),
			approx_age: Yup.string().required("Required"),
			bedrooms: Yup.number().required("Required"),
			bathrooms: Yup.number().required("Required"),
			kitchens: Yup.number().required("Required"),
			rooms: Yup.number().required("Required"),
			walk_score: Yup.number().required("Required"),
		}),
		onSubmit: (values) => {
			// console.log("Values => ", values);

			// console.log("Data => ", data);

			if (values.Municipality === "") {
				console.log("muni empty");
				setErrors({ ...errors, municipality: true });
				return;
			}

			if (values.property_type === "") {
				console.log("empty");
				setErrors({ ...errors, type: true });
				return;
			}

			const data = {
				...initialValues,
				Ml_num: values.Ml_num,
				Type_own1_out: values.property_type,
				Municipality: values.Municipality,
				Area: values.area,
				County: values.area,
				Orig_dol: values.property_price,
				Addr: values.property_address,
				Ad_text: values.property_description,
				bed_rooms: values.bedrooms,
				bath_rooms: values.bathrooms,
				S_r: values.S_r,
				Status: values.Status,
				propert_details: [
					{
						...PD,
						A_c: values.air_conditioning,
						Ad_text: values.property_description,
						Addr: values.property_address,
						Area: values.area,
						Bath_tot: values.bathrooms,
						Br: values.bedrooms,
						Bsmt1_out: values.basement,
						Bsmt2_out: values.basement_two,
						Constr1_out: values.exterior,
						County: values.area,
						Den_fr: values.den_or_family_room,
						Gar_spaces: values.garages,
						Gar_type: values.garage_type,
						Heat_inc: values.heat_source,
						Heating: values.heat_type,
						Ml_num: values.Ml_num,
						Municipality: values.Municipality,
						Num_kit: values.kitchens,
						Park_spcs: values.parking_space,
						Pool: values.pool,
						Pvt_ent: values.seperate_entrance,
						Rms: values.rooms,
						S_r: values.S_r,
						Sewer: values.sewers,
						Sqft: values.square_feet,
						Status: values.Status,
						Style: values.property_style,
						Taxes: values.tax,
						Tax_year: values.tax_year,
						Tot_park_spcs: values.parking_space,
						Type_own1_out: values.property_type,
						Walk_score: values.Walk_score,
						Water: values.Water,
						Water_front: values.Waterfront,
						Waterfront: values.Waterfront,
						Yr: values.approx_age,
						Yr_built: values.approx_age,
						Portion_property_lease_srch: ".",
						fronting_on: values.fronting_on,
						frontage: values.frontage,
						lot_dimensions: values.lot_dimensions,
						fireplaces: values.fireplaces,
						upcoming_open_house: values.upcoming_open_house,
						seperate_entrance: values.seperate_entrance,
					},
				],
			};

			console.log("Final Payload => ", data);
			// setLoading(true);
			updateProperty(data, "updated");
		},
	});

	const _bedrooms = [];
	const _bathrooms = [];
	const _kitchens = [];
	const _rooms = [];

	for (let i = 0; i < formik.values.bedrooms; i++) {
		_bedrooms.push(i);
	}

	for (let i = 0; i < formik.values.bathrooms; i++) {
		_bathrooms.push(i);
	}

	for (let i = 0; i < formik.values.kitchens; i++) {
		_kitchens.push(i);
	}

	for (let i = 0; i < formik.values.rooms; i++) {
		_rooms.push(i);
	}

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box sx={{ flexGrow: 1 }}>
				<div>
					<h5>Property Details</h5>
					<hr style={{ borderColor: "#ccc", marginBottom: 30 }} />
					<Grid container spacing={2}>
						<Grid item xs={12} md={9} lg={9}>
							<Item className="w-100">
								<TextInput
									id="property-name"
									label="Property Name"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"property_name",
											value
										)
									}
									value={formik.values.property_name}
									onBlur={() =>
										formik.setFieldTouched("property_name")
									}
									error={
										Boolean(formik.errors.property_name) &&
										formik.touched.property_name
									}
									errorMsg={formik.errors.property_name}
									className="w-100"
								/>
							</Item>
						</Grid>

						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<SelectInput
									label="Municipality"
									placeholder={
										"Please select a Municipality."
									}
									options={props.municipality}
									value={formik.values.Municipality}
									getValue={(value) =>
										formik.setFieldValue(
											"Municipality",
											value
										)
									}
									onBlur={() =>
										formik.setFieldTouched("Municipality")
									}
									error={
										Boolean(formik.errors.Municipality) &&
										formik.touched.Municipality
									}
									errorMessage={formik.errors.Municipality}
								/>
								<small
									className={
										errors.municipality
											? "text-danger"
											: "text-dark"
									}
								>
									Cannot create a property without
									Municipality.
								</small>
							</Item>
						</Grid>
						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<TextInput
									id="ml-num"
									label="ML Number"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("Ml_num", value)
									}
									value={formik.values.Ml_num}
									onBlur={() =>
										formik.setFieldTouched("Ml_num")
									}
									error={
										Boolean(formik.errors.Ml_num) &&
										formik.touched.Ml_num
									}
									errorMsg={formik.errors.Ml_num}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<TextInput
									id="style"
									label="Style"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"property_style",
											value
										)
									}
									value={formik.values.property_style}
									onBlur={() =>
										formik.setFieldTouched("property_style")
									}
									error={
										Boolean(formik.errors.property_style) &&
										formik.touched.property_style
									}
									errorMsg={formik.errors.property_style}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<TextInput
									id="area"
									label="Area"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("area", value)
									}
									value={formik.values.area}
									onBlur={() =>
										formik.setFieldTouched("area")
									}
									error={
										Boolean(formik.errors.area) &&
										formik.touched.area
									}
									errorMsg={formik.errors.area}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<TextInput
									style={{ marginBottom: 5, marginRight: 5 }}
									id="property-approx-age"
									label="Age"
									variant="outlined"
									type="text"
									getValue={(value) =>
										formik.setFieldValue(
											"approx_age",
											value
										)
									}
									value={formik.values.approx_age}
									onBlur={() =>
										formik.setFieldTouched("approx_age")
									}
									error={
										Boolean(formik.errors.approx_age) &&
										formik.touched.approx_age
									}
									errorMsg={formik.errors.approx_age}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={3} lg={3}>
							<Item className="anything">
								<SelectInput
									label="Type"
									placeholder={
										"Please select a property type."
									}
									options={props.propertyTypes}
									value={formik.values.property_type}
									getValue={(value) =>
										formik.setFieldValue(
											"property_type",
											value
										)
									}
									onBlur={() =>
										formik.setFieldTouched("property_type")
									}
									error={
										Boolean(formik.errors.property_type) &&
										formik.touched.property_type
									}
									errorMessage={formik.errors.property_type}
								/>
								<small
									className={
										errors.municipality
											? "text-danger"
											: "text-dark"
									}
								>
									Cannot create a property without Type.
								</small>
							</Item>
						</Grid>
						<Grid item xs={12} md={5}>
							<Item>
								<TextInput
									style={{ width: 100 + "%" }}
									id="property-address"
									label="Address"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"property_address",
											value
										)
									}
									value={formik.values.property_address}
									onBlur={() =>
										formik.setFieldTouched(
											"property_address"
										)
									}
									error={
										Boolean(
											formik.errors.property_address
										) && formik.touched.property_address
									}
									errorMsg={formik.errors.property_address}
								/>
							</Item>
						</Grid>

						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<TextInput
									id="property-size"
									label="Size (sq ft)"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"square_feet",
											value
										)
									}
									value={formik.values.square_feet}
									onBlur={() =>
										formik.setFieldTouched("square_feet")
									}
									error={
										Boolean(formik.errors.square_feet) &&
										formik.touched.square_feet
									}
									errorMsg={formik.errors.square_feet}
								/>
							</Item>
						</Grid>

						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<TextInput
									id="property-walk-score"
									label="Walk Score"
									variant="outlined"
									type="number"
									getValue={(value) =>
										formik.setFieldValue(
											"walk_score",
											value
										)
									}
									value={formik.values.walk_score}
									onBlur={() =>
										formik.setFieldTouched("walk_score")
									}
									error={
										Boolean(formik.errors.walk_score) &&
										formik.touched.walk_score
									}
									errorMsg={formik.errors.walk_score}
								/>
							</Item>
						</Grid>

						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<SelectInput
									label="Upcoming Open House"
									placeholder={"Please select an option."}
									options={["Yes", "No"]}
									value={formik.values.upcoming_open_house}
									getValue={(value) =>
										formik.setFieldValue(
											"upcoming_open_house",
											value
										)
									}
									onBlur={() =>
										formik.setFieldTouched(
											"upcoming_open_house"
										)
									}
									error={
										Boolean(
											formik.errors.upcoming_open_house
										) && formik.touched.upcoming_open_house
									}
									errorMessage={
										formik.errors.upcoming_open_house
									}
								/>
							</Item>
						</Grid>

						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<SelectInput
									label="Listing Type"
									placeholder={
										"Please select a listing type."
									}
									options={props.listingTypes}
									value={formik.values.S_r}
									getValue={(value) =>
										formik.setFieldValue("S_r", value)
									}
									onBlur={() => formik.setFieldTouched("S_r")}
									error={
										Boolean(formik.errors.S_r) &&
										formik.touched.S_r
									}
									errorMessage={formik.errors.S_r}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<SelectInput
									label="Property Status"
									placeholder={"Please select an option."}
									options={["Sale", "Sold"]}
									value={formik.values.Status}
									getValue={(value) =>
										formik.setFieldValue("Status", value)
									}
									onBlur={() =>
										formik.setFieldTouched("Status")
									}
									error={
										Boolean(formik.errors.Status) &&
										formik.touched.Status
									}
									errorMessage={formik.errors.Status}
								/>
							</Item>
						</Grid>

						<Grid item xs={12} md={12}>
							<Item>
								<TextInput
									style={{ width: 100 + "%" }}
									id="property-description"
									label="Property Description"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"property_description",
											value
										)
									}
									value={formik.values.property_description}
									onBlur={() =>
										formik.setFieldTouched(
											"property_description"
										)
									}
									error={
										Boolean(
											formik.errors.property_description
										) && formik.touched.property_description
									}
									errorMsg={
										formik.errors.property_description
									}
									multiline
									minRows={6}
								/>
							</Item>
						</Grid>
					</Grid>

					<h5 style={{ marginTop: 30 }}>Price Details</h5>
					<hr style={{ borderColor: "#ccc", marginBottom: 30 }} />
					<Grid container spacing={2}>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<TextInput
									id="property-price"
									label="Price"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"property_price",
											value
										)
									}
									value={formik.values.property_price}
									onBlur={() =>
										formik.setFieldTouched("property_price")
									}
									error={
										Boolean(formik.errors.property_price) &&
										formik.touched.property_price
									}
									errorMsg={formik.errors.property_price}
									type="number"
								/>
							</Item>
						</Grid>

						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<TextInput
									id="property-tax"
									label="Tax"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("tax", value)
									}
									value={formik.values.tax}
									onBlur={() => formik.setFieldTouched("tax")}
									error={
										Boolean(formik.errors.tax) &&
										formik.touched.tax
									}
									errorMsg={formik.errors.tax}
									type="number"
								/>
							</Item>
						</Grid>

						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<TextInput
									id="property-tax-year"
									label="Tax Year"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("tax_year", value)
									}
									value={formik.values.tax_year}
									onBlur={() =>
										formik.setFieldTouched("tax_year")
									}
									error={
										Boolean(formik.errors.tax_year) &&
										formik.touched.tax_year
									}
									errorMsg={formik.errors.tax_year}
									type="number"
								/>
							</Item>
						</Grid>
					</Grid>

					<h5 style={{ marginTop: 30 }}>Inside Details</h5>
					<hr style={{ borderColor: "#ccc", marginBottom: 30 }} />
					<Grid container spacing={2}>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<TextInput
									id="property-air-conditioning"
									label="Air Conditioning"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"air_conditioning",
											value
										)
									}
									value={formik.values.air_conditioning}
									onBlur={() =>
										formik.setFieldTouched(
											"air_conditioning"
										)
									}
									error={
										Boolean(
											formik.errors.air_conditioning
										) && formik.touched.air_conditioning
									}
									errorMsg={formik.errors.air_conditioning}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<TextInput
									id="property-fire-places"
									label="Fireplace"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"fireplaces",
											value
										)
									}
									value={formik.values.fireplaces}
									onBlur={() =>
										formik.setFieldTouched("fireplaces")
									}
									error={
										Boolean(formik.errors.fireplaces) &&
										formik.touched.fireplaces
									}
									errorMsg={formik.errors.fireplaces}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<SelectInput
									label="Den/Family Room"
									placeholder={"Please select an option."}
									options={["Yes", "No"]}
									value={formik.values.den_or_family_room}
									getValue={(value) =>
										formik.setFieldValue(
											"den_or_family_room",
											value
										)
									}
									onBlur={() =>
										formik.setFieldTouched(
											"den_or_family_room"
										)
									}
									error={
										Boolean(
											formik.errors.den_or_family_room
										) && formik.touched.den_or_family_room
									}
									errorMessage={
										formik.errors.den_or_family_room
									}
								/>
							</Item>
						</Grid>
					</Grid>

					<h5 style={{ marginTop: 30 }}>Room Details</h5>
					<hr style={{ borderColor: "#ccc", marginBottom: 30 }} />
					<Grid container spacing={2}>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<TextInput
									id="property-bedrooms"
									label="Bedrooms"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("bedrooms", value)
									}
									value={formik.values.bedrooms}
									onBlur={() =>
										formik.setFieldTouched("bedrooms")
									}
									error={
										Boolean(formik.errors.bedrooms) &&
										formik.touched.bedrooms
									}
									errorMsg={formik.errors.bedrooms}
									type="number"
								/>
							</Item>
							<Item>
								{_bedrooms.map((room, index) => {
									return (
										<div key={index} style={inputStyle}>
											<p className="custom-input-title my-2 p-2 badge badge-primary">
												Bedroom {index + 1}
											</p>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-width-${index}`}
													>
														Width
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="100 feet / meter"
													aria-label="Width"
													aria-describedby="basic-addon1"
													name={`bedroom-width-${index}`}
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType: "bedroom",
															type: "width",
															value: e.target
																.value,
														})
													}
												/>
											</div>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-length-${index}`}
													>
														Length
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="100 feet / meter / cm"
													aria-label="Length"
													aria-describedby="basic-addon2"
													name={`bedroom-length-${index}`}
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType: "bedroom",
															type: "length",
															value: e.target
																.value,
														})
													}
												/>
											</div>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-size-${index}`}
													>
														Size (sqr ft)
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="Square"
													aria-label="Square"
													aria-describedby="basic-sqr"
													name={`bedroom-sqr-${index}`}
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType: "bedroom",
															type: "size",
															value: e.target
																.value,
														})
													}
												/>
											</div>
										</div>
									);
								})}
							</Item>
						</Grid>

						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<TextInput
									id="property-bathrooms"
									label="Bathrooms"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("bathrooms", value)
									}
									value={formik.values.bathrooms}
									onBlur={() =>
										formik.setFieldTouched("bathrooms")
									}
									error={
										Boolean(formik.errors.bathrooms) &&
										formik.touched.bathrooms
									}
									errorMsg={formik.errors.bathrooms}
									type="number"
								/>
							</Item>
							<Item>
								{_bathrooms.map((room, index) => {
									return (
										<div key={index} style={inputStyle}>
											<p className="custom-input-title my-2 p-2 badge badge-success">
												Bathroom {index + 1}
											</p>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-width-${index}`}
													>
														Width
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="100 feet / meter"
													aria-label="Width"
													aria-describedby="basic-addon1"
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType:
																"bathroom",
															type: "width",
															value: e.target
																.value,
														})
													}
												/>
											</div>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-length-${index}`}
													>
														Length
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="100 feet / meter / cm"
													aria-label="Length"
													aria-describedby="basic-addon2"
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType:
																"bathroom",
															type: "length",
															value: e.target
																.value,
														})
													}
												/>
											</div>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-size-${index}`}
													>
														Size (sqr ft)
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="Square"
													aria-label="Square"
													aria-describedby="basic-sqr"
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType:
																"bathroom",
															type: "size",
															value: e.target
																.value,
														})
													}
												/>
											</div>
										</div>
									);
								})}
							</Item>
						</Grid>

						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<TextInput
									id="property-kitchens"
									label="Kitchens"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("kitchens", value)
									}
									value={formik.values.kitchens}
									onBlur={() =>
										formik.setFieldTouched("kitchens")
									}
									error={
										Boolean(formik.errors.kitchens) &&
										formik.touched.kitchens
									}
									errorMsg={formik.errors.kitchens}
									type="number"
								/>
							</Item>
							<Item>
								{_kitchens.map((room, index) => {
									return (
										<div key={index} style={inputStyle}>
											<p className="custom-input-title my-2 p-2 badge badge-warning">
												Kitchen {index + 1}
											</p>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-width-${index}`}
													>
														Width
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="100 feet / meter"
													aria-label="Width"
													aria-describedby="basic-addon1"
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType: "kitchen",
															type: "width",
															value: e.target
																.value,
														})
													}
												/>
											</div>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-length-${index}`}
													>
														Length
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="100 feet / meter / cm"
													aria-label="Length"
													aria-describedby="basic-addon2"
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType: "kitchen",
															type: "length",
															value: e.target
																.value,
														})
													}
												/>
											</div>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-size-${index}`}
													>
														Size (sqr ft)
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="Square"
													aria-label="Square"
													aria-describedby="basic-sqr"
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType: "kitchen",
															type: "size",
															value: e.target
																.value,
														})
													}
												/>
											</div>
										</div>
									);
								})}
							</Item>
						</Grid>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<TextInput
									id="property-rooms"
									label="Rooms"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("rooms", value)
									}
									value={formik.values.rooms}
									onBlur={() =>
										formik.setFieldTouched("rooms")
									}
									error={
										Boolean(formik.errors.rooms) &&
										formik.touched.rooms
									}
									errorMsg={formik.errors.rooms}
									type="number"
								/>
							</Item>
							<Item>
								{_rooms.map((room, index) => {
									return (
										<div key={index} style={inputStyle}>
											<p className="custom-input-title my-2 p-2 badge badge-danger">
												Room {index + 1}
											</p>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-width-${index}`}
													>
														Width
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="100 feet / meter"
													aria-label="Width"
													aria-describedby="basic-addon1"
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType: "room",
															type: "width",
															value: e.target
																.value,
														})
													}
												/>
											</div>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-length-${index}`}
													>
														Length
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="100 feet / meter / cm"
													aria-label="Length"
													aria-describedby="basic-addon2"
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType: "room",
															type: "length",
															value: e.target
																.value,
														})
													}
												/>
											</div>

											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span
														className="input-group-text"
														id={`basic-sqr-${index}`}
													>
														Size (sqr ft)
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="Square"
													aria-label="Square"
													aria-describedby="basic-sqr"
													onChange={(e) =>
														handleDynamicInput({
															index,
															roomType: "room",
															type: "size",
															value: e.target
																.value,
														})
													}
												/>
											</div>
										</div>
									);
								})}
							</Item>
						</Grid>
					</Grid>

					<h5 style={{ marginTop: 30 }}>Parking Details</h5>
					<hr style={{ borderColor: "#ccc", marginBottom: 30 }} />
					<Grid container spacing={2}>
						<Grid item xs={12} md={4} lg={4}>
							<Item>
								<TextInput
									id="garage"
									label="Garages"
									variant="outlined"
									type="number"
									getValue={(value) =>
										formik.setFieldValue("garages", value)
									}
									value={formik.values.garages}
									onBlur={() =>
										formik.setFieldTouched("garages")
									}
									error={
										Boolean(formik.errors.garages) &&
										formik.touched.garages
									}
									errorMsg={formik.errors.garages}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={4} lg={4}>
							<Item>
								<TextInput
									id="garage-type"
									label="Garage Type"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"garage_type",
											value
										)
									}
									value={formik.values.garage_type}
									onBlur={() =>
										formik.setFieldTouched("garage_type")
									}
									error={
										Boolean(formik.errors.garage_type) &&
										formik.touched.garage_type
									}
									errorMsg={formik.errors.garage_type}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={4} lg={4}>
							<Item>
								<TextInput
									id="parking_space"
									label="Parking Place"
									variant="outlined"
									type="number"
									getValue={(value) =>
										formik.setFieldValue(
											"parking_space",
											value
										)
									}
									value={formik.values.parking_space}
									onBlur={() =>
										formik.setFieldTouched("parking_space")
									}
									error={
										Boolean(formik.errors.parking_space) &&
										formik.touched.parking_space
									}
									errorMsg={formik.errors.parking_space}
								/>
							</Item>
						</Grid>
					</Grid>

					<h5 style={{ marginTop: 30 }}>Building Details</h5>
					<hr style={{ borderColor: "#ccc", marginBottom: 30 }} />
					<Grid container spacing={2}>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<TextInput
									className="w-100"
									id="heating-type"
									label="Heating Type"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("heat_type", value)
									}
									value={formik.values.heat_type}
									onBlur={() =>
										formik.setFieldTouched("heat_type")
									}
									error={
										Boolean(formik.errors.heat_type) &&
										formik.touched.heat_type
									}
									errorMsg={formik.errors.heat_type}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<SelectInput
									label="Heating Source"
									placeholder={"Please select an option."}
									options={["Gas", "Electric"]}
									value={formik.values.heat_source}
									getValue={(value) =>
										formik.setFieldValue(
											"heat_source",
											value
										)
									}
									onBlur={() =>
										formik.setFieldTouched("heat_source")
									}
									error={
										Boolean(formik.errors.heat_source) &&
										formik.touched.heat_source
									}
									errorMessage={formik.errors.heat_source}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<SelectInput
									label="Basement"
									placeholder={"Please select an option."}
									options={["Full", "Half"]}
									value={formik.values.basement}
									getValue={(value) =>
										formik.setFieldValue("basement", value)
									}
									onBlur={() =>
										formik.setFieldTouched("basement")
									}
									error={
										Boolean(formik.errors.basement) &&
										formik.touched.basement
									}
									errorMessage={formik.errors.basement}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<SelectInput
									label="Basement"
									placeholder={"Please select an option."}
									options={["Finished", "Unfinished"]}
									value={formik.values.basement_two}
									getValue={(value) =>
										formik.setFieldValue(
											"basement_two",
											value
										)
									}
									onBlur={() =>
										formik.setFieldTouched("basement_two")
									}
									error={
										Boolean(formik.errors.basement_two) &&
										formik.touched.basement_two
									}
									errorMessage={formik.errors.basement_two}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<SelectInput
									label="Exterior"
									placeholder={"Please select an option."}
									options={["Brick", "Vinyl"]}
									value={formik.values.exterior}
									getValue={(value) =>
										formik.setFieldValue("exterior", value)
									}
									onBlur={() =>
										formik.setFieldTouched("exterior")
									}
									error={
										Boolean(formik.errors.exterior) &&
										formik.touched.exterior
									}
									errorMessage={formik.errors.exterior}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={3} lg={3}>
							<Item>
								<SelectInput
									label="Seperate Entrance"
									placeholder={"Please select an option."}
									options={["Yes", "No"]}
									value={formik.values.seperate_entrance}
									getValue={(value) =>
										formik.setFieldValue(
											"seperate_entrance",
											value
										)
									}
									onBlur={() =>
										formik.setFieldTouched(
											"seperate_entrance"
										)
									}
									error={
										Boolean(
											formik.errors.seperate_entrance
										) && formik.touched.seperate_entrance
									}
									errorMessage={
										formik.errors.seperate_entrance
									}
								/>
							</Item>
						</Grid>
					</Grid>

					<h5 style={{ marginTop: 30 }}>Land Details</h5>
					<hr style={{ borderColor: "#ccc", marginBottom: 30 }} />
					<Grid container spacing={2}>
						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<TextInput
									id="fronting_on"
									label="Fronting On"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"fronting_on",
											value
										)
									}
									value={formik.values.fronting_on}
									onBlur={() =>
										formik.setFieldTouched("fronting_on")
									}
									error={
										Boolean(formik.errors.fronting_on) &&
										formik.touched.fronting_on
									}
									errorMsg={formik.errors.fronting_on}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<TextInput
									id="frontage"
									label="Frontage"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("frontage", value)
									}
									value={formik.values.frontage}
									onBlur={() =>
										formik.setFieldTouched("frontage")
									}
									error={
										Boolean(formik.errors.frontage) &&
										formik.touched.frontage
									}
									errorMsg={formik.errors.frontage}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<TextInput
									id="lot_dimensions"
									label="Lot Depth"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"lot_dimensions",
											value
										)
									}
									value={formik.values.lot_dimensions}
									onBlur={() =>
										formik.setFieldTouched("lot_dimensions")
									}
									error={
										Boolean(formik.errors.lot_dimensions) &&
										formik.touched.lot_dimensions
									}
									errorMsg={formik.errors.lot_dimensions}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<TextInput
									id="sewers"
									label="Sewers"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("sewers", value)
									}
									value={formik.values.sewers}
									onBlur={() =>
										formik.setFieldTouched("sewers")
									}
									error={
										Boolean(formik.errors.sewers) &&
										formik.touched.sewers
									}
									errorMsg={formik.errors.sewers}
								/>
							</Item>
						</Grid>
						<Grid item xs={12} md={2} lg={2}>
							<Item>
								<SelectInput
									label="Pool"
									placeholder={"Please select an option."}
									options={["Yes", "No"]}
									value={formik.values.pool}
									getValue={(value) =>
										formik.setFieldValue("pool", value)
									}
									onBlur={() =>
										formik.setFieldTouched("pool")
									}
									error={
										Boolean(formik.errors.pool) &&
										formik.touched.pool
									}
									errorMessage={formik.errors.pool}
								/>
							</Item>
						</Grid>
					</Grid>

					<h5 style={{ marginTop: 30 }}>Other Details</h5>
					<hr style={{ borderColor: "#ccc", marginBottom: 30 }} />
					<Grid container spacing={2}>
						<Grid item xs={12} md={12} lg={12}>
							<Item>
								{/* <TextInput
                  style={{ marginBottom: 5, marginRight: 5 }}
                  id="property-cen-vac"
                  label="Property Cen Vac"
                  variant="outlined"
                  getValue={(value) => formik.setFieldValue("cen_vac", value)}
                  value={formik.values.cen_vac}
                  onBlur={() => formik.setFieldTouched("cen_vac")}
                  error={
                    Boolean(formik.errors.cen_vac) && formik.touched.cen_vac
                  }
                  errorMsg={formik.errors.cen_vac}
                />
                <TextInput
                  style={{ marginBottom: 5, marginRight: 5 }}
                  id="property-lan-lev"
                  label="Property Lan Lev"
                  variant="outlined"
                  getValue={(value) => formik.setFieldValue("lan_lev", value)}
                  value={formik.values.lan_lev}
                  onBlur={() => formik.setFieldTouched("lan_lev")}
                  error={
                    Boolean(formik.errors.lan_lev) && formik.touched.lan_lev
                  }
                  errorMsg={formik.errors.lan_lev}
                />*/}
								<TextInput
									style={{ marginBottom: 5, marginRight: 5 }}
									id="property-water"
									label="Property Water"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue("Water", value)
									}
									value={formik.values.Water}
									onBlur={() =>
										formik.setFieldTouched("Water")
									}
									error={
										Boolean(formik.errors.Water) &&
										formik.touched.Water
									}
									errorMsg={formik.errors.Water}
								/>
								<TextInput
									style={{ marginBottom: 5, marginRight: 5 }}
									id="property-water-front"
									label="Water Front"
									variant="outlined"
									getValue={(value) =>
										formik.setFieldValue(
											"Waterfront",
											value
										)
									}
									value={formik.values.Waterfront}
									onBlur={() =>
										formik.setFieldTouched("Waterfront")
									}
									error={
										Boolean(formik.errors.Waterfront) &&
										formik.touched.Waterfront
									}
									errorMsg={formik.errors.Waterfront}
								/>
							</Item>
						</Grid>
					</Grid>

					{/*<h5 style={{ marginTop: 30 }}>Images</h5>
          <hr style={{ borderColor: "#ccc", marginBottom: 30 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Item>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="photoUpload">
                      Upload
                    </span>
                  </div>
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="photo-upload"
                      aria-describedby="photoUpload"
                      accept="image/*"
                      multiple
                      onChange={(e) => handleFileChange(e)}
                    />
                    <label class="custom-file-label" for="photo-upload">
                      Choose file
                    </label>
                  </div>                 
                </div>
              </Item>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Item className="d-flex justify-content-around">
                {images &&
                  images.map((img) => (
                    <div>
                      <img src={img.src} alt="" width="200" height="200" />
                    </div>
                  ))}
              </Item>
            </Grid>
          </Grid>*/}
				</div>

				<button
					type={"submit"}
					className="btn btn-primary mt-5 d-flex align-items-center"
					disabled={loading}
				>
					<p className="text-white mr-2">
						{loading ? "Please wait. . ." : "Update"}
					</p>
					{loading && (
						<div
							class="spinner-border spinner-border-sm text-light"
							role="status"
						>
							<span class="sr-only">Loading...</span>
						</div>
					)}
				</button>
			</Box>
		</form>
	);
}
