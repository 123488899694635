import {
  UPDATE_LOADING,
  SET_SHOWINGS,
  UPDATE_SHOWINGS,
  GET_PROPERTY_LIST,
  GET_PROPERTY_LISTS,
  SAVE_PROPERTY_LIST,
  REMOVE_PROPERTY_LIST,
  SAVE_BLOG_CATEGORIES,
  SAVE_BLOG_POSTS,
  SAVE_BLOG_POST,
  GET_BLOG_POST,
} from "./AppActionTypes";

export const getPropertyList = (id) => async (dispatch) => {
  dispatch({ type: GET_PROPERTY_LIST, payload: id });
};

export const getPropertyLists = () => async (dispatch) => {
  dispatch({ type: GET_PROPERTY_LISTS });
};

export const savePropertyList = (payload) => async (dispatch) => {
  dispatch({ type: SAVE_PROPERTY_LIST, payload });
};

export const removePropertyList = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_PROPERTY_LIST, payload: id });
};

export const savePropertyDetails = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_LOADING, payload });
};

export const setShowings = (payload) => async (dispatch) => {
  let storage = localStorage.getItem("showings");

  if (storage === null) {
    const data = [{ ...payload }];
    localStorage.setItem("showings", JSON.stringify(data));
  } else {
    let storageData = JSON.parse(storage);

    storageData.push(payload);

    localStorage.setItem("showings", JSON.stringify(storageData));
  }

  dispatch({ type: SET_SHOWINGS, payload });
};

export const updateShowings = (payload) => async (dispatch) => {
  localStorage.setItem("showings", JSON.stringify(payload));
  dispatch({ type: UPDATE_SHOWINGS, payload });
};

export const saveBlogCategories = (payload) => async (dispatch) => {
  dispatch({ type: SAVE_BLOG_CATEGORIES, payload });
};

export const saveBlogPosts = (payload) => async (dispatch) => {
  dispatch({ type: SAVE_BLOG_POSTS, payload });
};

export const saveBlogPost = (payload) => async (dispatch) => {
  dispatch({ type: SAVE_BLOG_POST, payload });
};

export const getBlogPost = (id) => async (dispatch) => {
  dispatch({ type: GET_BLOG_POST, payload: { id } });
};
