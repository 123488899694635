import React from "react";

const Tools = ({ addComponents }) => {
	return (
		<div className="d-flex align-items-center tool-wrapper">
			<div className="card" onClick={() => addComponents("title")}>
				<i class="bi bi-type tool-icon"></i>
			</div>

			<div className="card" onClick={() => addComponents("image")}>
				<i class="bi bi-card-image tool-icon"></i>
			</div>

			<div className="card" onClick={() => addComponents("paragraph")}>
				<i class="bi bi-paragraph tool-icon"></i>
			</div>

			<div
				className="card"
				onClick={() => addComponents("paragraph-image")}
			>
				<div className="d-flex justify-content-center align-items-center">
					<div className="custom-border">
						<i class="bi bi-justify" style={{ fontSize: 20 }}></i>
					</div>
					<div className="custom-border">
						<i
							class="bi bi-card-image"
							style={{ fontSize: 20 }}
						></i>
					</div>
				</div>
			</div>

			<div
				className="card"
				onClick={() => addComponents("image-paragraph")}
			>
				<div className="d-flex justify-content-center align-items-center">
					<div className="custom-border">
						<i
							class="bi bi-card-image"
							style={{ fontSize: 20 }}
						></i>
					</div>
					<div className="custom-border">
						<i class="bi bi-justify" style={{ fontSize: 20 }}></i>
					</div>
				</div>
			</div>

			<div className="card" onClick={() => addComponents("images")}>
				<div className="d-flex justify-content-center align-items-center">
					<div className="custom-border">
						<i
							class="bi bi-card-image"
							style={{ fontSize: 20 }}
						></i>
					</div>
					<div className="custom-border">
						<i
							class="bi bi-card-image"
							style={{ fontSize: 20 }}
						></i>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Tools;
