import HTTP from "../common/ajax";

class PropertyModel {
  async fetchPropertyDetails(propertyId) {
    try {
      const res = await HTTP.get(`properties/details/${propertyId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async createNewProperty(payload) {
    try {
      const res = await HTTP.post(`admin/properties/own/new`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async uploadImages(payload) {
    try {
      const res = await HTTP.post(`admin/properties/image/upload`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async ownProperties(status) {
    try {
      const res = await HTTP.get(`own-listings/${status}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }
}

export default new PropertyModel();
