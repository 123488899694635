import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import Swal from "sweetalert2";

import RootElement from "../../RootElement";
import MailForm from "./MailForm";

import EmailListModel from "../../../../../Models/EmailListModel";

const emailTemplates = ["reply", "thanks", "test"];

export default function BulkEmailSend() {
	const History = useHistory();
	const { group_type } = useParams();

	const [emails, setEmails] = useState([]);
	const [template, setTemplate] = useState("Choose template");

	const fetchEmail = async () => {
		const res = await EmailListModel.getGroupEmails(group_type);
		console.log("res", res);
		setEmails(res.data);
	};

	const removeFromBulkEmailList = async (id) => {
		const filtered = emails.filter((email) => email.id != id);
		setEmails(filtered);
	};

	const sendEmail = async (payload) => {
		try {
			console.log("payload ", payload);
			await EmailListModel.sendBulkEmail({
				...payload,
				template,
				emails,
				group_type,
			});

			Swal.fire("Success", "Emails sent successfully", "success");

			setTimeout(function () {
				window.location.reload();
			}, 1500);
		} catch (error) {
			console.log("error", error);
		}
	};

	useEffect(() => {
		fetchEmail();
	}, []);

	return (
		<RootElement>
			<div className="container">
				<div className="bulk-email-list">
					<div>
						<div>
							<button
								className="btn btn-lg"
								onClick={() => History.goBack()}
							>
								<i class="bi bi-arrow-left"></i>
							</button>
						</div>
					</div>

					<div
						style={{
							height: 250,
							marginTop: 25,
							overflow: "auto",
						}}
						className="shadow mb-5 p-3"
					>
						<table class="table table-sm table-borderless table-hover">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Name</th>
									<th scope="col">Email</th>
									<th scope="col">Action</th>
								</tr>
							</thead>
							<tbody>
								{emails &&
									emails.map((data, index) => (
										<tr key={index}>
											<th scope="row">{index + 1}</th>
											<td>{data.full_name}</td>
											<td>{data.email}</td>
											<td>
												<button
													className="btn btn-sm btn-danger"
													onClick={() =>
														removeFromBulkEmailList(
															data.id
														)
													}
												>
													<i class="bi bi-x"></i>
												</button>
											</td>
										</tr>
									))}

								{emails &&
									emails.map((data, index) => (
										<tr key={index}>
											<th scope="row">{index + 1}</th>
											<td>{data.full_name}</td>
											<td>{data.email}</td>
											<td>
												<button
													className="btn btn-sm btn-danger"
													onClick={() =>
														removeFromBulkEmailList(
															data.id
														)
													}
												>
													<i class="bi bi-x"></i>
												</button>
											</td>
										</tr>
									))}

								{emails &&
									emails.map((data, index) => (
										<tr key={index}>
											<th scope="row">{index + 1}</th>
											<td>{data.full_name}</td>
											<td>{data.email}</td>
											<td>
												<button
													className="btn btn-sm btn-danger"
													onClick={() =>
														removeFromBulkEmailList(
															data.id
														)
													}
												>
													<i class="bi bi-x"></i>
												</button>
											</td>
										</tr>
									))}

								{emails &&
									emails.map((data, index) => (
										<tr key={index}>
											<th scope="row">{index + 1}</th>
											<td>{data.full_name}</td>
											<td>{data.email}</td>
											<td>
												<button
													className="btn btn-sm btn-danger"
													onClick={() =>
														removeFromBulkEmailList(
															data.id
														)
													}
												>
													<i class="bi bi-x"></i>
												</button>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>

					<div className="my-3 shadow p-4">
						<div class="dropdown mr-3 mb-3">
							<button
								class="btn btn-secondary dropdown-toggle"
								type="button"
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								{template}
							</button>
							<div
								class="dropdown-menu"
								aria-labelledby="dropdownMenuButton"
							>
								{emailTemplates.map((temp, index) => (
									<a
										key={index}
										class="dropdown-item"
										href="#"
										onClick={(e) =>
											setTemplate(e.target.text)
										}
									>
										{temp}
									</a>
								))}
							</div>
						</div>

						<MailForm sendEmail={(data) => sendEmail(data)} />
					</div>
					<div></div>
				</div>
			</div>
		</RootElement>
	);
}
