import HTTP from "../common/ajax";


class CommentModel {
	async commentsForProperty(propertId) {
		try {
			const res = await HTTP.get(`admin/comments/property/${propertId}`);
			return Promise.resolve(res.data);
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}

	async comment(id) {
		try {
			const res = await HTTP.get(`public/comments/${id}`);
			return Promise.resolve(res.data);
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}

	async comments() {
		try {
			const res = await HTTP.get(`public/comments`);
			return Promise.resolve(res.data);
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}

	async saveComment(payload) {
		try {
			const res = await HTTP.post(`public/comments`, payload);
			return Promise.resolve(res.data);
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}

	async adminComments() {
		try {
			const res = await HTTP.get(`admin/comments`);
			return Promise.resolve(res.data);
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}

	async searchComments(keyword) {
		try {
			const res = await HTTP.get(`admin/comments/search/${keyword}`);
			return Promise.resolve(res.data);
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}


}

export default new CommentModel();
