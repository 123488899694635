import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import { connect } from "react-redux";
import {
  getBlogPost,
  saveBlogPosts,
  saveBlogCategories,
} from "../store/actions/App/AppActions";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import BlogModel from "../Models/BlogModel";

import "../assets/css/BlogDetails.css";

const BlogDetails = (props) => {
  const History = useHistory();
  const params = useParams();

  const [post, setPost] = useState(props.state.blogPost);
  const [postNotFound, setPostNotFound] = useState(false);

  useEffect(() => {
    const fetchPostData = async () => {
      const res = await BlogModel.fetchBlogPostData(
        params.category,
        params.postId
      );

      if (res.data) {
        if (res.data.post === null) {
          setPostNotFound(true);
        } else {
          setPost(res.data.post);
        }

        if (res.data.relatedTopics) {
          props.saveBlogPosts(res.data.relatedTopics);
        }
      }

      if (props.state.blogCategories.length <= 0) {
        const response = await BlogModel.getAllCategories2();
        props.saveBlogCategories(response.data);
      }
    };

    fetchPostData();

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [params.postId]);
  const TITLE = process.env.REACT_APP_SITE_NAME + ' - Blog Details'

  return (
    <>
    <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
      <HeaderComponent />
      <section className="py-5 mb-md-4 mt-md-5">
        <div class="blog-single gray-bg">
          <div class="container">
            <div class="row align-items-start">
              <div class="col-lg-8 m-15px-tb">
                <article class="article">
                  <div class="article-img">
                    <img src={post.featured_image_url} title="" alt="" />
                  </div>
                  <div class="article-title">
                    {/*  <h6>
                      <a href="#">{post.category}</a>
                    </h6> */}
                    <h2>{post.title}</h2>
                  </div>
                  <div
                    class="article-content"
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  ></div>
                  <div class="nav tag-cloud">
                    {props.state.blogCategories.length > 0 &&
                      props.state.blogCategories.map((category, index) => (
                        <Link key={index} to={`/blogs/${category.category}`}>
                          {category.category}
                        </Link>
                      ))}
                  </div>
                </article>
                <div class="contact-form article-comment">
                  <h4>Leave a Reply</h4>
                  <form id="contact-form" method="POST">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            name="Name"
                            id="name"
                            placeholder="Name *"
                            class="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            name="Email"
                            id="email"
                            placeholder="Email *"
                            class="form-control"
                            type="email"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Your message *"
                            rows="4"
                            class="form-control"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="send">
                          <button class="px-btn theme">
                            <span>Submit</span> <i class="arrow"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-4 m-15px-tb blog-aside">
                <div class="widget widget-latest-post">
                  <div class="widget-title">
                    <h3>Latest Post</h3>
                  </div>
                  <div class="widget-body">
                    {props.state.blogPosts &&
                      props.state.blogPosts.map((post, index) => (
                        <div class="latest-post-aside media" key={index}>
                          <div class="lpa-left media-body">
                            <div class="lpa-title">
                              <h5>
                                <Link
                                  className="tg-primary-text"
                                  to={`/blogs/${post.category}/${post.id}`}
                                >
                                  {post.title}
                                </Link>
                              </h5>
                            </div>
                            <div class="lpa-meta">
                              <a class="date" href="#">
                                {moment(post.updated).format("DD MMM YYYY")}
                              </a>
                            </div>
                          </div>
                          <div class="lpa-right">
                            <a href="#">
                              <img
                                src={post.featured_image_url}
                                title=""
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div class="widget widget-tags">
                  <div class="widget-title">
                    <h3>Latest Tags</h3>
                  </div>
                  <div class="widget-body">
                    <div class="nav tag-cloud">
                      {props.state.blogCategories.length > 0 &&
                        props.state.blogCategories.map((category, index) => (
                          <Link key={index} to={`/blogs/${category.category}`}>
                            {category.category}
                          </Link>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterComponent />
    </>
  );
};

const mapStateToProps = (state) => {
  return { state: state.appState };
};

const mapDispatchToProps = (dispatch) => ({
  saveBlogPosts: (payload) => {
    dispatch(saveBlogPosts(payload));
  },
  getBlogPost: (id) => {
    dispatch(getBlogPost(id));
  },
  saveBlogCategories: (payload) => {
    dispatch(saveBlogCategories(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetails);
